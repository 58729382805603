import React, { useState, useEffect } from 'react';
import { Card, CardHeader, CardBody, CardFooter, Avatar, ScrollShadow } from '@nextui-org/react';
import { Accordion, AccordionItem } from '@nextui-org/accordion';
import { motion } from 'framer-motion';

const container = {
	hidden: { opacity: 0, scale: 0.8 },
	visible: {
		opacity: 1,
		scale: 1,
		transition: {
			delayChildren: 0.3,
			staggerChildren: 0.2,
		},
	},
};

const item = {
	hidden: { y: 20, opacity: 0 },
	visible: { y: 0, opacity: 1 },
};

const FAQ = () => {
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
	const [searchQuery, setSearchQuery] = useState('');
	const [filteredFAQs, setFilteredFAQs] = useState([]);

	const faqs = [
		{
			key: '0',
			title: 'How do I create an account?',
			content:
				'Creating an account is easy! Just click on the "Register" button at the top right corner of the homepage. Fill in your details, submit the form, and you\'ll receive a confirmation email to verify your account. Welcome aboard!',
		},
		{
			key: '1',
			title: 'What services does Our Video offer?',
			content:
				'Our Video provides a range of services, including AI-powered content generation, ad script creation, and targeted ad campaigns. Visit our "Services" page to discover how we can help elevate your brand.',
		},
		{
			key: '2',
			title: 'How can I contact customer support?',
			content:
				'Need assistance? Reach out to our friendly customer support team via the "Contact Us" page. Fill out the form with your query, and we\'ll get back to you as soon as possible. We\'re here to help!',
		},
		{
			key: '3',
			title: 'Is there a free trial available?',
			content:
				'Yes, we offer a free trial for new users! Sign up for an account to access the free trial and explore our services. Experience the power of AI with Our Video at no cost!',
		},
		{
			key: '4',
			title: 'How do I upgrade my account?',
			content:
				'Upgrading your account is simple. Log in and navigate to the "Account" section. Choose the subscription plan that best suits your needs and proceed with the upgrade. Unlock more features and benefits today!',
		},
	];

	useEffect(() => {
		setFilteredFAQs(faqs);
	}, []);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	const handleSearch = (e) => {
		const query = e.target.value.toLowerCase();
		setSearchQuery(query);

		const filtered = faqs.filter(
			(faq) => faq.title.toLowerCase().includes(query) || faq.content.toLowerCase().includes(query)
		);
		setFilteredFAQs(filtered);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			<form className={`max-w-md mx-auto mt-10 ${isMobile ? 'mr-5 ml-5' : ''}`}>
				<div className='relative'>
					<div className='absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none'>
						<svg
							className='w-4 h-4 text-gray-500 dark:text-gray-400'
							aria-hidden='true'
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 20 20'>
							<path
								stroke='currentColor'
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z'
							/>
						</svg>
					</div>
					<input
						type='search'
						id='default-search'
						className='block w-full p-4 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-white-500 focus:border-white-500 dark:bg-transparent dark:border-white-600 dark:placeholder-white-400 dark:text-white dark:focus:ring-white-500 dark:focus:border-white'
						placeholder='Find what you need'
						value={searchQuery}
						onChange={handleSearch}
						required
					/>
					<button
						type='submit'
						className='text-white absolute end-2.5 bottom-2.5 bg-purple-700 hover:bg-white-800 focus:ring-4 focus:outline-none focus:ring-white font-medium rounded-lg text-sm px-4 py-2 dark:hover:bg-white-700 dark:focus:ring-white'>
						Search
					</button>
				</div>
			</form>
			<motion.div
				className='flex justify-center mt-6 mb-10 p-5'
				variants={container}
				initial='hidden'
				animate='visible'>
				<Accordion className='mt-4'>
					{filteredFAQs.map((faq) => (
						<AccordionItem
							key={faq.key}
							title={faq.title}
							className='bg-white dark:bg-transparent rounded-lg mb-2'>
							<motion.p className='text-sm'>{faq.content}</motion.p>
						</AccordionItem>
					))}
				</Accordion>
			</motion.div>
		</>
	);
};

export default FAQ;
