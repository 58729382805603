import { useState, useEffect } from 'react';
import axios from 'axios';

import { handleForbidden } from '../navigation';
import { createApi } from './AxiosConfig';

const useFetch = ({ method, url, params, data, responseType, content_type }) => {
	const [response, setResponse] = useState({
		error: null,
		loading: true,
		data: null,
		refetch: null,
	});

	useEffect(() => {
		const source = axios.CancelToken.source();

		const fetch = async () => {
			setResponse((prev) => ({ ...prev, loading: true }));
			try {
				const config = {
					method: method,
					url,
					ContentType: content_type,
					headers: {
						'Access-Control-Allow-Origin': '*',
					},
					cancelToken: source.token,
				};

				if (params) config.params = params;
				if (data) config.data = data;
				if (responseType) config.responseType = responseType;

				const res = await createApi(config);

				setResponse((prev) => ({
					...prev,
					data: res.data,
					loading: false,
				}));
			} catch (error) {
				if (error?.response?.data === 'Forbidden' || error?.response?.status === 403) handleForbidden();
				if (axios.isCancel(error)) {
					// //console.log('Request canceled', error.message);
				} else {
					setResponse((prev) => ({ ...prev, error, loading: false }));
				}
			} finally {
				setTimeout(() => {
					setResponse((prev) => ({ ...prev, error: null }));
				}, 1000);
			}
		};

		setResponse((prev) => ({
			...prev,
			refetch: fetch,
		}));

		fetch();

		return () => {
			source.cancel('Operation canceled by the user.');
		};
	}, [url]);

	return response;
};

export default useFetch;
