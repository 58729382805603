const CategoryOptions = [
	{ value: 'affiliateMarketing', label: '🤝 Affiliate Marketing' },
	{ value: 'programmaticAd', label: '🤖 Programmatic Ad' },
	{ value: 'remarketing', label: '♻️ Remarketing' },
	{ value: 'sponsoredContent', label: '📑 Sponsored Content' },
	{ value: 'interactiveAd', label: '🖲️ Interactive Ad' },
	{ value: 'geoTargetedAd', label: '📍 Geo-Targeted Ad' },
	{ value: 'doorHanger', label: '🚪 Door Hanger' },
	{ value: 'yardSign', label: '🏡 Yard Sign' },
	{ value: 'shelfTalker', label: '🛒 Shelf Talker' },
	{ value: 'endCapDisplay', label: '🏷️ End Cap Display' },
	{ value: 'tableTent', label: '🍽️ Table Tent' },
	{ value: 'counterMat', label: '🖥️ Counter Mat' },
	{ value: 'floorGraphic', label: '🛠️ Floor Graphic' },
	{ value: 'windowCling', label: '🪟 Window Cling' },
	{ value: 'ceilingDangler', label: '🔝 Ceiling Dangler' },
	{ value: 'balloonAd', label: '🎈 Balloon Ad' },
	{ value: 'trailerWrap', label: '🚛 Trailer Wrap' },
	{ value: 'mallAd', label: '🏬 Mall Ad' },
	{ value: 'airportAd', label: '✈️ Airport Ad' },
	{ value: 'busAd', label: '🚌 Bus Ad' },
	{ value: 'trainAd', label: '🚆 Train Ad' },
	{ value: 'taxiAd', label: '🚖 Taxi Ad' },
	{ value: 'subwayAd', label: '🚇 Subway Ad' },
	{ value: 'sportsVenueAd', label: '🏟️ Sports Venue Ad' },
	{ value: 'stadiumAd', label: '🏟️ Stadium Ad' },
	{ value: 'arenaAd', label: '🏟️ Arena Ad' },
	{ value: 'eventSponsorship', label: '🎉 Event Sponsorship' },
	{ value: 'concertSponsorship', label: '🎤 Concert Sponsorship' },
	{ value: 'festivalSponsorship', label: '🎪 Festival Sponsorship' },
	{ value: 'charityEventSponsorship', label: '❤️ Charity Event Sponsorship' },
	{ value: 'marathonSponsorship', label: '🏃 Marathon Sponsorship' },
	{ value: 'sportsTeamSponsorship', label: '🏅 Sports Team Sponsorship' },
	{ value: 'corporateEvent', label: '🏢 Corporate Event' },
	{ value: 'productLaunch', label: '🚀 Product Launch' },
	{ value: 'grandOpening', label: '🎀 Grand Opening' },
	{ value: 'openHouse', label: '🏠 Open House' },
	{ value: 'communityEvent', label: '🏘️ Community Event' },
	{ value: 'pressConference', label: '🗞️ Press Conference' },
	{ value: 'mediaTour', label: '🎙️ Media Tour' },
	{ value: 'inStorePromotion', label: '🏪 In-Store Promotion' },
	{ value: 'samplingCampaign', label: '🍫 Sampling Campaign' },
	{ value: 'streetTeam', label: '🚴 Street Team' },
	{ value: 'flashMob', label: '💃 Flash Mob' },
	{ value: 'projectionMapping', label: '🌐 Projection Mapping' },
	{ value: '3DAd', label: '📐 3D Ad' },
	{ value: 'virtualRealityAd', label: '🕶️ Virtual Reality Ad' },
	{ value: 'augmentedRealityAd', label: '🌐 Augmented Reality Ad' },
	{ value: 'digitalOutOfHomeAd', label: '🏙️ Digital Out of Home Ad' },
	{ value: 'retargeting', label: '🔄 Retargeting' },
	{ value: 'geoFencing', label: '📍 Geo-Fencing' },
	{ value: 'beaconMarketing', label: '📡 Beacon Marketing' },
	{ value: 'pushNotification', label: '🔔 Push Notification' },
	{ value: 'smsMarketing', label: '📲 SMS Marketing' },
	{ value: 'mmsMarketing', label: '📹 MMS Marketing' },
	{ value: 'inAppAdvertising', label: '📱 In-App Advertising' },
	{ value: 'mobileGamesAd', label: '🎮 Mobile Games Ad' },
	{ value: 'nativeMobileAd', label: '📱 Native Mobile Ad' },
	{ value: 'rewardedVideoAd', label: '🏅 Rewarded Video Ad' },
	{ value: 'playableAd', label: '🎮 Playable Ad' },
	{ value: 'connectedTvAd', label: '📺 Connected TV Ad' },
	{ value: 'overTheTopAd', label: '📺 Over-the-Top Ad' },
	{ value: 'smartTvAd', label: '📺 Smart TV Ad' },
	{ value: 'addressableTvAd', label: '📺 Addressable TV Ad' },
	{ value: 'audioStreamingAd', label: '🎧 Audio Streaming Ad' },
	{ value: 'musicAppAd', label: '🎵 Music App Ad' },
	{ value: 'podcastSponsorship', label: '🎙️ Podcast Sponsorship' },
	{ value: 'brandedPodcast', label: '🎙️ Branded Podcast' },
	{ value: 'interactiveVoiceAd', label: '🗣️ Interactive Voice Ad' },
	{ value: 'voiceSearchAd', label: '🔍 Voice Search Ad' },
	{ value: 'smartSpeakerAd', label: '🔊 Smart Speaker Ad' },
	{ value: 'iotAd', label: '🌐 IoT Ad' },
	{ value: 'wearableTechAd', label: '⌚ Wearable Tech Ad' },
	{ value: 'gameConsoleAd', label: '🎮 Game Console Ad' },
	{ value: 'esportsAd', label: '🎮 eSports Ad' },
	{ value: 'inGameAd', label: '🎮 In-Game Ad' },
	{ value: 'gamificationAd', label: '🏆 Gamification Ad' },
	{ value: 'lootBoxAd', label: '🎁 Loot Box Ad' },
	{ value: 'rewardProgramAd', label: '🏅 Reward Program Ad' },
	{ value: 'crowdfundingCampaign', label: '💰 Crowdfunding Campaign' },
	{ value: 'subscriptionBoxAd', label: '📦 Subscription Box Ad' },
	{ value: 'mealKitAd', label: '🍽️ Meal Kit Ad' },
	{ value: 'fitnessAppAd', label: '🏋️ Fitness App Ad' },
	{ value: 'meditationAppAd', label: '🧘 Meditation App Ad' },
	{ value: 'healthcareAppAd', label: '🏥 Healthcare App Ad' },
	{ value: 'fintechAppAd', label: '💳 Fintech App Ad' },
	{ value: 'edtechAppAd', label: '📚 EdTech App Ad' },
	{ value: 'rideSharingAd', label: '🚗 Ride Sharing Ad' },
	{ value: 'foodDeliveryAd', label: '🍔 Food Delivery Ad' },
	{ value: 'groceryDeliveryAd', label: '🛒 Grocery Delivery Ad' },
	{ value: 'logisticsAd', label: '📦 Logistics Ad' },
	{ value: 'recruitmentAd', label: '👔 Recruitment Ad' },
	{ value: 'jobBoardAd', label: '💼 Job Board Ad' },
	{ value: 'freelancePlatformAd', label: '🖥️ Freelance Platform Ad' },
	{ value: 'onlineCourseAd', label: '📚 Online Course Ad' },
	{ value: 'membershipSiteAd', label: '👥 Membership Site Ad' },
	{ value: 'webinarAd', label: '🎓 Webinar Ad' },
	{ value: 'virtualEventAd', label: '💻 Virtual Event Ad' },
	{ value: 'liveStreamAd', label: '📹 Live Stream Ad' },
	{ value: 'payPerViewAd', label: '💸 Pay-Per-View Ad' },
	{ value: 'subscriptionAd', label: '🔔 Subscription Ad' },
	{ value: 'newsAppAd', label: '🗞️ News App Ad' },
	{ value: 'magazineAppAd', label: '📖 Magazine App Ad' },
	{ value: 'ebookAd', label: '📚 eBook Ad' },
	{ value: 'audiobookAd', label: '🎧 Audiobook Ad' },
	{ value: 'comicBookAd', label: '📚 Comic Book Ad' },
	{ value: 'mangaAd', label: '📚 Manga Ad' },
	{ value: 'graphicNovelAd', label: '📚 Graphic Novel Ad' },
	{ value: 'onlineDatingAd', label: '❤️ Online Dating Ad' },
	{ value: 'virtualDatingAd', label: '💻 Virtual Dating Ad' },
	{ value: 'matchmakingAd', label: '💞 Matchmaking Ad' },
	{ value: 'genealogyAd', label: '🌳 Genealogy Ad' },
	{ value: 'ancestryAd', label: '🧬 Ancestry Ad' },
	{ value: 'dnaTestAd', label: '🧬 DNA Test Ad' },
	{ value: 'homeTestKitAd', label: '🏠 Home Test Kit Ad' },
	{ value: 'telehealthAd', label: '🩺 Telehealth Ad' },
	{ value: 'mentalHealthAppAd', label: '🧠 Mental Health App Ad' },
	{ value: 'onlineTherapyAd', label: '🛋️ Online Therapy Ad' },
	{ value: 'petCareAppAd', label: '🐾 Pet Care App Ad' },
	{ value: 'petAdoptionAd', label: '🐕 Pet Adoption Ad' },
	{ value: 'petSuppliesAd', label: '🐾 Pet Supplies Ad' },
	{ value: 'petInsuranceAd', label: '🐾 Pet Insurance Ad' },
	{ value: 'veterinaryAd', label: '🐾 Veterinary Ad' },
	{ value: 'carSharingAd', label: '🚗 Car Sharing Ad' },
	{ value: 'carRentalAd', label: '🚗 Car Rental Ad' },
	{ value: 'vacationRentalAd', label: '🏠 Vacation Rental Ad' },
	{ value: 'hotelBookingAd', label: '🏨 Hotel Booking Ad' },
	{ value: 'travelInsuranceAd', label: '🛡️ Travel Insurance Ad' },
	{ value: 'cruiseAd', label: '🛳️ Cruise Ad' },
	{ value: 'tourPackageAd', label: '🏖️ Tour Package Ad' },
	{ value: 'themeParkAd', label: '🎢 Theme Park Ad' },
	{ value: 'museumAd', label: '🏛️ Museum Ad' },
	{ value: 'zooAd', label: '🦁 Zoo Ad' },
	{ value: 'aquariumAd', label: '🐠 Aquarium Ad' },
	{ value: 'concertAd', label: '🎤 Concert Ad' },
	{ value: 'theaterAd', label: '🎭 Theater Ad' },
	{ value: 'movieAd', label: '🎬 Movie Ad' },
	{ value: 'filmFestivalAd', label: '🎥 Film Festival Ad' },
	{ value: 'bookFairAd', label: '📚 Book Fair Ad' },
	{ value: 'comicConAd', label: '🎉 Comic Con Ad' },
	{ value: 'gameConventionAd', label: '🎮 Game Convention Ad' },
	{ value: 'autoShowAd', label: '🚗 Auto Show Ad' },
	{ value: 'fashionShowAd', label: '👗 Fashion Show Ad' },
	{ value: 'foodFestivalAd', label: '🍴 Food Festival Ad' },
	{ value: 'beerFestivalAd', label: '🍻 Beer Festival Ad' },
	{ value: 'wineFestivalAd', label: '🍷 Wine Festival Ad' },
	{ value: 'musicFestivalAd', label: '🎵 Music Festival Ad' },
	{ value: 'culturalFestivalAd', label: '🎉 Cultural Festival Ad' },
	{ value: 'holidayEventAd', label: '🎄 Holiday Event Ad' },
	{ value: 'charityEventAd', label: '❤️ Charity Event Ad' },
	{ value: 'fundraisingAd', label: '💰 Fundraising Ad' },
	{ value: 'donationCampaign', label: '💸 Donation Campaign' },
	{ value: 'volunteerRecruitmentAd', label: '🙋 Volunteer Recruitment Ad' },
	{ value: 'advocacyAd', label: '📢 Advocacy Ad' },
	{ value: 'politicalCampaignAd', label: '🗳️ Political Campaign Ad' },
	{ value: 'publicServiceAd', label: '📢 Public Service Ad' },
	{ value: 'environmentalAd', label: '🌳 Environmental Ad' },
	{ value: 'sustainabilityAd', label: '🌱 Sustainability Ad' },
	{ value: 'renewableEnergyAd', label: '🌞 Renewable Energy Ad' },
	{ value: 'electricVehicleAd', label: '🔋 Electric Vehicle Ad' },
	{ value: 'energySavingAd', label: '💡 Energy Saving Ad' },
	{ value: 'smartHomeAd', label: '🏠 Smart Home Ad' },
	{ value: 'homeImprovementAd', label: '🛠️ Home Improvement Ad' },
	{ value: 'realEstateAd', label: '🏡 Real Estate Ad' },
	{ value: 'mortgageAd', label: '🏦 Mortgage Ad' },
	{ value: 'financialPlanningAd', label: '💹 Financial Planning Ad' },
	{ value: 'investmentAd', label: '📈 Investment Ad' },
	{ value: 'insuranceAd', label: '🛡️ Insurance Ad' },
	{ value: 'bankingAd', label: '🏦 Banking Ad' },
	{ value: 'creditCardAd', label: '💳 Credit Card Ad' },
	{ value: 'loanAd', label: '💸 Loan Ad' },
	{ value: 'retirementAd', label: '👴 Retirement Ad' },
	{ value: 'wealthManagementAd', label: '💰 Wealth Management Ad' },
	{ value: 'taxServiceAd', label: '💼 Tax Service Ad' },
	{ value: 'accountingServiceAd', label: '📊 Accounting Service Ad' },
	{ value: 'legalServiceAd', label: '⚖️ Legal Service Ad' },
	{ value: 'consultingServiceAd', label: '🧠 Consulting Service Ad' },
	{ value: 'hrServiceAd', label: '🧑‍💼 HR Service Ad' },
	{ value: 'itServiceAd', label: '💻 IT Service Ad' },
	{ value: 'cybersecurityAd', label: '🔒 Cybersecurity Ad' },
	{ value: 'softwareAd', label: '💾 Software Ad' },
	{ value: 'saasAd', label: '☁️ SaaS Ad' },
	{ value: 'cloudServiceAd', label: '☁️ Cloud Service Ad' },
	{ value: 'dataAnalyticsAd', label: '📊 Data Analytics Ad' },
	{ value: 'aiAd', label: '🤖 AI Ad' },
	{ value: 'blockchainAd', label: '🔗 Blockchain Ad' },
	{ value: 'cryptocurrencyAd', label: '💰 Cryptocurrency Ad' },
	{ value: 'defiAd', label: '🔗 DeFi Ad' },
	{ value: 'nftAd', label: '🖼️ NFT Ad' },
	{ value: '3dPrintingAd', label: '🖨️ 3D Printing Ad' },
	{ value: 'roboticsAd', label: '🤖 Robotics Ad' },
	{ value: 'dronesAd', label: '🚁 Drones Ad' },
	{ value: 'iotAd', label: '🌐 IoT Ad' },
	{ value: 'wearableTechAd', label: '⌚ Wearable Tech Ad' },
	{ value: 'gamingAd', label: '🎮 Gaming Ad' },
	{ value: 'esportsAd', label: '🎮 eSports Ad' },
	{ value: 'vrAd', label: '🕶️ VR Ad' },
	{ value: 'arAd', label: '🌐 AR Ad' },
	{ value: 'mixedRealityAd', label: '🌐 Mixed Reality Ad' },
	{ value: 'smartTechAd', label: '📱 Smart Tech Ad' },
	{ value: 'smartWatchAd', label: '⌚ Smart Watch Ad' },
	{ value: 'smartphoneAd', label: '📱 Smartphone Ad' },
	{ value: 'tabletAd', label: '📱 Tablet Ad' },
	{ value: 'laptopAd', label: '💻 Laptop Ad' },
	{ value: 'desktopAd', label: '🖥️ Desktop Ad' },
	{ value: 'printerAd', label: '🖨️ Printer Ad' },
	{ value: 'scannerAd', label: '🖨️ Scanner Ad' },
	{ value: 'cameraAd', label: '📷 Camera Ad' },
	{ value: 'lensAd', label: '📷 Lens Ad' },
	{ value: 'photoEditingAd', label: '🖼️ Photo Editing Ad' },
	{ value: 'videoEditingAd', label: '🎬 Video Editing Ad' },
	{ value: 'animationAd', label: '🎥 Animation Ad' },
	{ value: 'vfxAd', label: '🎥 VFX Ad' },
	{ value: 'musicProductionAd', label: '🎶 Music Production Ad' },
	{ value: 'audioEditingAd', label: '🎧 Audio Editing Ad' },
	{ value: 'djAd', label: '🎧 DJ Ad' },
	{ value: 'concertProductionAd', label: '🎤 Concert Production Ad' },
	{ value: 'eventProductionAd', label: '🎉 Event Production Ad' },
	{ value: 'lightingAd', label: '💡 Lighting Ad' },
	{ value: 'soundSystemAd', label: '🔊 Sound System Ad' },
	{ value: 'stageDesignAd', label: '🎭 Stage Design Ad' },
	{ value: 'eventPlanningAd', label: '🗓️ Event Planning Ad' },
	{ value: 'cateringAd', label: '🍽️ Catering Ad' },
	{ value: 'weddingPlanningAd', label: '💍 Wedding Planning Ad' },
	{ value: 'partyPlanningAd', label: '🎉 Party Planning Ad' },
	{ value: 'corporateEventPlanningAd', label: '🏢 Corporate Event Planning Ad' },
	{ value: 'conferencePlanningAd', label: '🏢 Conference Planning Ad' },
	{ value: 'tradeShowPlanningAd', label: '🏙️ Trade Show Planning Ad' },
	{ value: 'expoPlanningAd', label: '🏙️ Expo Planning Ad' },
	{ value: 'festivalPlanningAd', label: '🎉 Festival Planning Ad' },
	{ value: 'concertPlanningAd', label: '🎤 Concert Planning Ad' },
	{ value: 'meetingPlanningAd', label: '📅 Meeting Planning Ad' },
	{ value: 'seminarPlanningAd', label: '🎓 Seminar Planning Ad' },
	{ value: 'workshopPlanningAd', label: '🛠️ Workshop Planning Ad' },
	{ value: 'retreatPlanningAd', label: '🏞️ Retreat Planning Ad' },
	{ value: 'teamBuildingAd', label: '🏅 Team Building Ad' },
	{ value: 'trainingAd', label: '🎓 Training Ad' },
	{ value: 'hrTrainingAd', label: '🧑‍💼 HR Training Ad' },
	{ value: 'salesTrainingAd', label: '💼 Sales Training Ad' },
	{ value: 'leadershipTrainingAd', label: '🧑‍💼 Leadership Training Ad' },
	{ value: 'onlineTrainingAd', label: '💻 Online Training Ad' },
	{ value: 'elearningAd', label: '📚 eLearning Ad' },
	{ value: 'onlineCourseAd', label: '🎓 Online Course Ad' },
	{ value: 'certificateProgramAd', label: '🎓 Certificate Program Ad' },
	{ value: 'degreeProgramAd', label: '🎓 Degree Program Ad' },
	{ value: 'graduateProgramAd', label: '🎓 Graduate Program Ad' },
	{ value: 'phdProgramAd', label: '🎓 PhD Program Ad' },
	{ value: 'postdoctoralAd', label: '🎓 Postdoctoral Ad' },
	{ value: 'researchAd', label: '🔬 Research Ad' },
	{ value: 'scholarshipAd', label: '🎓 Scholarship Ad' },
	{ value: 'fellowshipAd', label: '🎓 Fellowship Ad' },
	{ value: 'internshipAd', label: '💼 Internship Ad' },
	{ value: 'apprenticeshipAd', label: '🛠️ Apprenticeship Ad' },
	{ value: 'jobTrainingAd', label: '🧑‍💼 Job Training Ad' },
	{ value: 'skillsDevelopmentAd', label: '🧠 Skills Development Ad' },
	{ value: 'careerCoachingAd', label: '👔 Career Coaching Ad' },
	{ value: 'resumeServiceAd', label: '📄 Resume Service Ad' },
	{ value: 'jobPlacementAd', label: '💼 Job Placement Ad' },
	{ value: 'recruitmentAd', label: '🧑‍💼 Recruitment Ad' },
	{ value: 'headhuntingAd', label: '🧑‍💼 Headhunting Ad' },
	{ value: 'executiveSearchAd', label: '🧑‍💼 Executive Search Ad' },
	{ value: 'tempAgencyAd', label: '💼 Temp Agency Ad' },
	{ value: 'staffingAgencyAd', label: '🧑‍💼 Staffing Agency Ad' },
	{ value: 'hrConsultingAd', label: '🧑‍💼 HR Consulting Ad' },
	{ value: 'outplacementAd', label: '💼 Outplacement Ad' },
	{ value: 'careerTransitionAd', label: '🧑‍💼 Career Transition Ad' },
	{ value: 'payrollServiceAd', label: '💼 Payroll Service Ad' },
	{ value: 'benefitsAdministrationAd', label: '💼 Benefits Administration Ad' },
	{ value: 'complianceAd', label: '📑 Compliance Ad' },
	{ value: 'riskManagementAd', label: '📑 Risk Management Ad' },
	{ value: 'legalServiceAd', label: '⚖️ Legal Service Ad' },
	{ value: 'taxServiceAd', label: '📑 Tax Service Ad' },
	{ value: 'auditServiceAd', label: '📑 Audit Service Ad' },
	{ value: 'accountingServiceAd', label: '📑 Accounting Service Ad' },
	{ value: 'bookkeepingServiceAd', label: '📑 Bookkeeping Service Ad' },
	{ value: 'financialPlanningAd', label: '📑 Financial Planning Ad' },
	{ value: 'investmentManagementAd', label: '📑 Investment Management Ad' },
	{ value: 'estatePlanningAd', label: '📑 Estate Planning Ad' },
	{ value: 'wealthManagementAd', label: '📑 Wealth Management Ad' },
	{ value: 'insuranceAd', label: '📑 Insurance Ad' },
	{ value: 'mortgageServiceAd', label: '📑 Mortgage Service Ad' },
	{ value: 'realEstateServiceAd', label: '📑 Real Estate Service Ad' },
	{ value: 'propertyManagementAd', label: '📑 Property Management Ad' },
	{ value: 'homeInspectionAd', label: '📑 Home Inspection Ad' },
	{ value: 'appraisalServiceAd', label: '📑 Appraisal Service Ad' },
	{ value: 'titleServiceAd', label: '📑 Title Service Ad' },
	{ value: 'closingServiceAd', label: '📑 Closing Service Ad' },
	{ value: 'movingServiceAd', label: '📦 Moving Service Ad' },
	{ value: 'storageServiceAd', label: '📦 Storage Service Ad' },
	{ value: 'cleaningServiceAd', label: '🧹 Cleaning Service Ad' },
	{ value: 'landscapingServiceAd', label: '🌿 Landscaping Service Ad' },
	{ value: 'pestControlAd', label: '🕷️ Pest Control Ad' },
	{ value: 'homeRepairAd', label: '🔧 Home Repair Ad' },
	{ value: 'renovationServiceAd', label: '🔨 Renovation Service Ad' },
	{ value: 'remodelingServiceAd', label: '🔨 Remodeling Service Ad' },
	{ value: 'constructionServiceAd', label: '🏗️ Construction Service Ad' },
	{ value: 'architecturalServiceAd', label: '🏛️ Architectural Service Ad' },
	{ value: 'interiorDesignAd', label: '🛋️ Interior Design Ad' },
	{ value: 'homeStagingAd', label: '🛋️ Home Staging Ad' },
	{ value: 'handymanServiceAd', label: '🔧 Handyman Service Ad' },
	{ value: 'electricalServiceAd', label: '💡 Electrical Service Ad' },
	{ value: 'plumbingServiceAd', label: '🚰 Plumbing Service Ad' },
	{ value: 'hvacServiceAd', label: '❄️ HVAC Service Ad' },
	{ value: 'solarEnergyAd', label: '🌞 Solar Energy Ad' },
	{ value: 'roofingServiceAd', label: '🏠 Roofing Service Ad' },
	{ value: 'paintingServiceAd', label: '🎨 Painting Service Ad' },
	{ value: 'flooringServiceAd', label: '🛋️ Flooring Service Ad' },
	{ value: 'windowTreatmentAd', label: '🪟 Window Treatment Ad' },
	{ value: 'furnitureAd', label: '🛋️ Furniture Ad' },
	{ value: 'applianceAd', label: '📺 Appliance Ad' },
	{ value: 'homeAutomationAd', label: '🏠 Home Automation Ad' },
	{ value: 'securitySystemAd', label: '🚨 Security System Ad' },
	{ value: 'homeTheaterAd', label: '📺 Home Theater Ad' },
	{ value: 'audioVideoAd', label: '🔊 Audio Video Ad' },
	{ value: 'smartHomeAd', label: '🏠 Smart Home Ad' },
	{ value: 'gardenSuppliesAd', label: '🌿 Garden Supplies Ad' },
	{ value: 'petSuppliesAd', label: '🐾 Pet Supplies Ad' },
	{ value: 'kitchenSuppliesAd', label: '🍽️ Kitchen Supplies Ad' },
	{ value: 'officeSuppliesAd', label: '📦 Office Supplies Ad' },
	{ value: 'schoolSuppliesAd', label: '📚 School Supplies Ad' },
	{ value: 'craftSuppliesAd', label: '🎨 Craft Supplies Ad' },
	{ value: 'hobbySuppliesAd', label: '🎣 Hobby Supplies Ad' },
	{ value: 'diySuppliesAd', label: '🛠️ DIY Supplies Ad' },
	{ value: 'automotiveSuppliesAd', label: '🚗 Automotive Supplies Ad' },
	{ value: 'toolsAd', label: '🔧 Tools Ad' },
	{ value: 'hardwareAd', label: '🔩 Hardware Ad' },
	{ value: 'electricalSuppliesAd', label: '💡 Electrical Supplies Ad' },
	{ value: 'plumbingSuppliesAd', label: '🚰 Plumbing Supplies Ad' },
	{ value: 'constructionSuppliesAd', label: '🏗️ Construction Supplies Ad' },
	{ value: 'buildingMaterialsAd', label: '🏗️ Building Materials Ad' },
	{ value: 'paintSuppliesAd', label: '🎨 Paint Supplies Ad' },
	{ value: 'flooringSuppliesAd', label: '🛋️ Flooring Supplies Ad' },
	{ value: 'homeDecorAd', label: '🛋️ Home Decor Ad' },
	{ value: 'furnitureAd', label: '🛋️ Furniture Ad' },
	{ value: 'applianceAd', label: '📺 Appliance Ad' },
	{ value: 'lightingAd', label: '💡 Lighting Ad' },
	{ value: 'smartHomeAd', label: '🏠 Smart Home Ad' },
	{ value: 'homeTheaterAd', label: '📺 Home Theater Ad' },
	{ value: 'securitySystemAd', label: '🚨 Security System Ad' },
	{ value: 'audioVideoAd', label: '🔊 Audio Video Ad' },
	{ value: 'smartHomeAd', label: '🏠 Smart Home Ad' },
	{ value: 'gardenSuppliesAd', label: '🌿 Garden Supplies Ad' },
	{ value: 'petSuppliesAd', label: '🐾 Pet Supplies Ad' },
	{ value: 'kitchenSuppliesAd', label: '🍽️ Kitchen Supplies Ad' },
	{ value: 'officeSuppliesAd', label: '📦 Office Supplies Ad' },
	{ value: 'schoolSuppliesAd', label: '📚 School Supplies Ad' },
	{ value: 'craftSuppliesAd', label: '🎨 Craft Supplies Ad' },
	{ value: 'hobbySuppliesAd', label: '🎣 Hobby Supplies Ad' },
	{ value: 'diySuppliesAd', label: '🛠️ DIY Supplies Ad' },
	{ value: 'automotiveSuppliesAd', label: '🚗 Automotive Supplies Ad' },
	{ value: 'toolsAd', label: '🔧 Tools Ad' },
	{ value: 'hardwareAd', label: '🔩 Hardware Ad' },
	{ value: 'electricalSuppliesAd', label: '💡 Electrical Supplies Ad' },
	{ value: 'plumbingSuppliesAd', label: '🚰 Plumbing Supplies Ad' },
	{ value: 'constructionSuppliesAd', label: '🏗️ Construction Supplies Ad' },
	{ value: 'buildingMaterialsAd', label: '🏗️ Building Materials Ad' },
	{ value: 'paintSuppliesAd', label: '🎨 Paint Supplies Ad' },
	{ value: 'flooringSuppliesAd', label: '🛋️ Flooring Supplies Ad' },
	{ value: 'homeDecorAd', label: '🛋️ Home Decor Ad' },
	{ value: 'other', label: '📦 Other (Specify)' },
];

export default
	CategoryOptions
