const params = [
	'nature',
	'landscape',
	'urban',
	'architecture',
	'portrait',
	'animals',
	'food',
	'travel',
	'beach',
	'mountain',
	'cityscape',
	'waterfall',
	'sunset',
	'sunrise',
	'forest',
	'desert',
	'night',
	'snow',
	'winter',
	'summer',
	'spring',
	'autumn',
	'flower',
	'tree',
	'sky',
	'ocean',
	'river',
	'lake',
	'wildlife',
	'bridge',
	'building',
	'street',
	'vehicle',
	'people',
	'fashion',
	'technology',
	'abstract',
	'minimalism',
	'vintage',
	'modern',
	'art',
	'black and white',
	'colorful',
	'sun',
	'clouds',
	'rain',
	'storm',
	'countryside',
	'deserted',
	'night sky',
	'starry sky',
	'horizon',
	'tropical',
	'exotic',
	'historic',
	'contemporary',
	'rustic',
	'elegant',
	'playful',
	'romantic',
	'energetic',
	'serene',
	'dynamic',
	'calm',
	'busy',
	'quiet',
	'vibrant',
	'soft',
	'hard',
	'bright',
	'dark',
	'sharp',
	'blurred',
	'detailed',
	'simple',
	'geometric',
	'natural',
	'urban decay',
	'reflections',
	'textures',
	'patterns',
	'symmetry',
	'asymmetry',
	'close-up',
	'wide-angle',
	'high contrast',
	'low contrast',
	'pastel',
	'bold',
	'retro',
	'futuristic',
	'playground',
	'desolate',
	'crowded',
	'isolated',
	'busy street',
	'peaceful',
	'rustic',
	'charming',
	'mysterious',
	'majestic',
	'eerie',
	'whimsical',
	'gothic',
];

export default params