import { lazy } from 'react';

// feature
export const AdsProductScreen = lazy(() => import('../../Screens/Features/AdsProduct/AdsProductScreen'));
export const StoryTellerScreen = lazy(() => import('../../Screens/Features/StoryTeller/StoryTellerScreen'));
export const GenerateImageScreen = lazy(() => import('../../Screens/Features/GenerateImage/GenerateImageScreen'));

export const AddSubtitleScreen = lazy(() => import('../../Screens/AddSubtitleScreen/AddSubtitleScreen'));
export const VoiceDubbingScreen = lazy(() => import('../../Screens/VoiceDubbingScreen/VoiceDubbingScreen'));

// transaction
export const Checkout = lazy(() => import('../../Screens/Checkout/Checkout'));
export const TransactionDetailScreen = lazy(() => import('../../Screens/TransactionDetailScreen/TransactionDetailScreen'));
export const TransactionHistoryScreen = lazy(() => import('../../Screens/TransactionHistoryScreen/TransactionHistoryScreen'));

// profile
export const ProfileScreen = lazy(() => import('../../Screens/ProfileScreen/ProfileScreen'));
export const MyGalleriesScreen = lazy(() => import('../../Screens/MyGalleries/MyGalleries'));
export const HomeScreen = lazy(() => import('../../Screens/HomeScreen/HomeScreen'));
export const LikedImages = lazy(() => import('../../Screens/LikedImages/LikedImages'));