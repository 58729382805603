import React, { Suspense } from 'react';

import NavbarUI from '../NavbarUI/NavbarUI';
import Footer from '../Footer/Footer';

import LoadingSpinnerOverlay from '../LoadingSpinnerOverlay/LoadingSpinnerOverlay';
import CookieConsent from '../CookieConsent/CookieConsent';

const Layout = ({ element, isHeader = true, isFooter = true }) => {
	return (
		<div className='relative'>
			{isHeader && <NavbarUI />}
			<Suspense fallback={<LoadingSpinnerOverlay />} className='w-full'>
				<div className='flex flex-col mx-auto box-border' style={{ minHeight: 'calc(100vh - 81px)' }}>
					<div className='flex flex-col container flex-1 flex-grow h-full mx-auto'>{element}</div>
					<CookieConsent />
					{isFooter && <Footer />}
				</div>
			</Suspense>
		</div>
	);
};

export default Layout;
