import { lazy } from 'react';

export const AboutScreen = lazy(() => import('../../Screens/AboutScreen/AboutScreen'));
export const Galleries = lazy(() => import('../../Screens/Galleries/Galleries'));
export const ContactScreen = lazy(() => import('../../Screens/ContactScreen/ContactScreen'));
export const PrivacyPolicyScreen = lazy(() => import('../../Screens/PrivacyPolicyScreen/PrivacyPolicyScreen'));
export const TermsConditions = lazy(() => import('../../Screens/TermsConditions/TermsConditions'));
export const FAQ = lazy(() => import('../../Screens/Faq/FAQ'));
export const SupportScreen = lazy(() => import('../../Screens/SupportScreen/SupportScreen'));
export const CookiesPolicy = lazy(() => import('../../Screens/CookiesPolicy/CookiesPolicy'));
export const PricingScreen = lazy(() => import('../../Screens/ServicesScreen/ServicesScreen'));
export const NotFoundScreen = lazy(() => import('../../Screens/NotFoundScreen/NotFoundScreen'));
export const ForgetPassword = lazy(() => import('../../Screens/ForgetPassword/ForgetPassword'));
export const ResetPassword = lazy(() => import('../../Screens/ResetPassword/ResetPassword'));
export const ImageHotpotScreen = lazy(() => import('../../Screens/ImageHotpotScreen/ImageHotpotScreen'));
export const StartedAdsProduct = lazy(() => import('../../Screens/GetStartedScreen/AdsProduct/StartedAdsProduct'));
export const StartedReelsVideo = lazy(() => import('../../Screens/GetStartedScreen/ReelsVideo/StartedReelsVideo'));
export const StartedGenerateImage = lazy(() => import('../../Screens/GetStartedScreen/GenerateImage/StartedGenerateImage'));
export const StartedSubtitleVideo = lazy(() => import('../../Screens/GetStartedScreen/SubtitleVideo/StartedSubtitleVideo'));
export const StartedVoiceDubbing = lazy(() => import('../../Screens/GetStartedScreen/VoiceDubbing/StartedVoiceDubbing'));
