import React from 'react';
import { Route } from 'react-router-dom';

import Layout from '../../Components/Layout/Layout';
import LandingScreen from '../../Screens/LandingScreen/LandingScreen';
import {
  AboutScreen,
  Galleries,
  ContactScreen,
  PrivacyPolicyScreen,
  TermsConditions,
  FAQ,
  SupportScreen,
  CookiesPolicy,
  PricingScreen,
  NotFoundScreen,
  ForgetPassword,
  ResetPassword,
  ImageHotpotScreen,
  StartedAdsProduct,
  StartedReelsVideo,
  StartedGenerateImage,
  StartedSubtitleVideo,
  StartedVoiceDubbing,
} from './LazyImports';

const routeConfig = [
  // navbar
  { path: '/', component: LandingScreen, exact: true },
  { path: '/pricing', component: PricingScreen },
  { path: '/galleries', component: Galleries, isFooter: false },
  
  // footer
  { path: '/about', component: AboutScreen },
  { path: '/contact', component: ContactScreen },
  { path: '/support', component: SupportScreen },
  { path: '/cookies', component: CookiesPolicy },
  { path: '/privacy', component: PrivacyPolicyScreen },
  { path: '/terms', component: TermsConditions },
  
  // starter
  { path: '/started-ads-product', component: StartedAdsProduct },
  { path: '/started-reels-story', component: StartedReelsVideo },
  { path: '/started-generate-image', component: StartedGenerateImage },
  { path: '/started-subtitle-video', component: StartedSubtitleVideo },
  { path: '/started-voice-dubbing', component: StartedVoiceDubbing },
  
  // password
  { path: '/forget-password', component: ForgetPassword },
  { path: '/reset-password/:token', component: ResetPassword },

  // others
  { path: '/sample-images', component: ImageHotpotScreen },
  { path: '/faq-screen', component: FAQ },
  { path: '*', component: NotFoundScreen },
];

const PublicRoutes = () => (
  <>
    {routeConfig.map(({ path, component, exact, isFooter = true }) => (
      <Route
        key={path}
        path={path}
        exact={exact}
        element={<Layout element={React.createElement(component)} isFooter={isFooter} />}
      />
    ))}
  </>
);

export default PublicRoutes;
