// LoadingSpinnerOverlay.js
import React from 'react';
import { Spinner } from '@nextui-org/react';

const LoadingSpinnerOverlay = () => {
  return (
    <div className="fixed top-0 left-0 flex justify-center items-center z-50 w-[100%] h-[100%]">
      <Spinner color='secondary' animation="border" role="status" className="w-8 h-8 text-white" />
    </div>
  );
};

export default LoadingSpinnerOverlay;
