import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { BrowserRouter } from 'react-router-dom';

import { AuthContext } from './AuthContext';
import { ThemeContext } from './ThemeContext';
import { ProductContext } from './ProductContext';
import useAuth from './useAuth';
import useTheme from './useTheme';
import useProduct from './useProduct';

const AppProviders = ({ children }) => {
	const auth = useAuth();
	const theme = useTheme();
	const product = useProduct();

	return (
		<BrowserRouter>
			{/* <GoogleOAuthProvider clientId={process.env.REACT_APP_CLIENT_ID}> */}
				<ThemeContext.Provider value={theme}>
					<ProductContext.Provider value={product}>
						<AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
					</ProductContext.Provider>
				</ThemeContext.Provider>
			{/* </GoogleOAuthProvider> */}
		</BrowserRouter>
	);
};

export default AppProviders;
