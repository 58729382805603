const  StylesOptions = [
	{ value: '40', label: '3D Black White', description: 'Generate black and white 3D artwork.' },
	{ value: '41', label: '3D General 1', description: 'Generate general 3D artwork style 1.' },
	{ value: '43', label: '3D General 2', description: 'Generate general 3D artwork style 2.' },
	{ value: '44', label: '3D General 3', description: 'Generate general 3D artwork style 3.' },
	{ value: '46', label: '3D Minecraft 1', description: 'Generate 3D artwork inspired by Minecraft style.' },
	{ value: '100', label: '3D Portrait 1', description: 'Generate 3D portrait artwork.' },
	{ value: '42', label: '3D Print 1', description: 'Generate 3D print-ready artwork.' },
	{ value: '47', label: '3D Roblox 1', description: 'Generate 3D artwork inspired by Roblox style.' },
	{ value: '204', label: '3D Toy 1', description: 'Generate 3D artwork of toy designs.' },
	{ value: '45', label: '3D Voxel 1', description: 'Generate voxel-based 3D artwork.' },
	{ value: '17', label: 'Acrylic Art', description: 'Generate artwork with acrylic paint style.' },
	{ value: '60', label: 'Animation 1', description: 'Generate animated artwork style 1.' },
	{ value: '61', label: 'Animation 2', description: 'Generate animated artwork style 2.' },
	{ value: '70', label: 'Animation 3', description: 'Generate animated artwork style 3.' },
	{ value: '79', label: 'Animation 4', description: 'Generate animated artwork style 4.' },
	{ value: '173', label: 'Animation 5', description: 'Generate animated artwork style 5.' },
	{ value: '29', label: 'Anime 1', description: 'Generate anime artwork style 1.' },
	{ value: '186', label: 'Anime 2', description: 'Generate anime artwork style 2.' },
	{ value: '187', label: 'Anime 3', description: 'Generate anime artwork style 3.' },
	{ value: '189', label: 'Anime 4', description: 'Generate anime artwork style 4.' },
	{ value: '191', label: 'Anime 5', description: 'Generate anime artwork style 5.' },
	{ value: '199', label: 'Anime 6', description: 'Generate anime artwork style 6.' },
	{ value: '31', label: 'Anime Berserk', description: 'Generate artwork inspired by Berserk anime.' },
	{ value: '30', label: 'Anime Black White', description: 'Generate black and white anime artwork.' },
	{ value: '158', label: 'Anime Cute 1', description: 'Generate cute anime artwork style 1.' },
	{ value: '185', label: 'Anime Fantasy 1', description: 'Generate fantasy-themed anime artwork style 1.' },
	{ value: '32', label: 'Anime Korean 1', description: 'Generate Korean-style anime artwork.' },
	{ value: '190', label: 'Anime Realistic 1', description: 'Generate realistic anime artwork style 1.' },
	{ value: '192', label: 'Anime Van Gogh 1', description: 'Generate anime artwork inspired by Van Gogh.' },
	{ value: '75', label: 'Architecture Exterior 1', description: 'Generate exterior architecture artwork style 1.' },
	{ value: '63', label: 'Architecture General 1', description: 'Generate general architecture artwork style 1.' },
	{ value: '74', label: 'Architecture Interior 1', description: 'Generate interior architecture artwork style 1.' },
	{
		value: '62',
		label: 'Architecture Interior Modern 1',
		description: 'Generate modern interior architecture artwork style 1.',
	},
	{ value: '184', label: 'Bacon Art 1', description: 'Generate artwork featuring bacon.' },
	{ value: '202', label: 'Bioluminescence 1', description: 'Generate artwork with bioluminescent elements.' },
	{ value: '72', label: 'Cartoon 1', description: 'Generate cartoon-style artwork.' },
	{ value: '54', label: 'Charcoal 1', description: 'Generate charcoal drawing artwork style 1.' },
	{ value: '55', label: 'Charcoal 2', description: 'Generate charcoal drawing artwork style 2.' },
	{ value: '56', label: 'Charcoal 3', description: 'Generate charcoal drawing artwork style 3.' },
	{ value: '150', label: 'Chinese Art 1', description: 'Generate Chinese art style 1.' },
	{ value: '151', label: 'Chinese Art 2', description: 'Generate Chinese art style 2.' },
	{ value: '152', label: 'Chinese Art 3', description: 'Generate Chinese art style 3.' },
	{ value: '10', label: 'Comic Book 1', description: 'Generate comic book artwork style 1.' },
	{ value: '11', label: 'Comic Book 2', description: 'Generate comic book artwork style 2.' },
	{ value: '12', label: 'Comic Book 3', description: 'Generate comic book artwork style 3.' },
	{ value: '73', label: 'Comic Book 4', description: 'Generate comic book artwork style 4.' },
	{ value: '76', label: 'Comic Book 5', description: 'Generate comic book artwork style 5.' },
	{ value: '183', label: 'Comic Book 6', description: 'Generate comic book artwork style 6.' },
	{ value: '71', label: 'Concept Art 2', description: 'Generate concept art style 2.' },
	{ value: '77', label: 'Concept Art 3', description: 'Generate concept art style 3.' },
	{ value: '84', label: 'Concept Art 4', description: 'Generate concept art style 4.' },
	{ value: '128', label: 'Concept Art 5', description: 'Generate concept art style 5.' },
	{ value: '126', label: 'Concept Art 6', description: 'Generate concept art style 6.' },
	{ value: '146', label: 'Concept Art 7', description: 'Generate concept art style 7.' },
	{ value: '105', label: 'Cute Art 1', description: 'Generate cute artwork style 1.' },
	{ value: '207', label: 'Cyberorganic 1', description: 'Generate cyberorganic artwork.' },
	{ value: '148', label: 'Cyberpunk 1', description: 'Generate cyberpunk artwork style 1.' },
	{ value: '149', label: 'Cyberpunk 2', description: 'Generate cyberpunk artwork style 2.' },
	{ value: '195', label: 'Demon Black White 1', description: 'Generate black and white artwork featuring demons.' },
	{ value: '13', label: 'Doom 1', description: 'Generate artwork inspired by Doom style 1.' },
	{ value: '14', label: 'Doom 2', description: 'Generate artwork inspired by Doom style 2.' },
	{ value: '200', label: 'Doom 3', description: 'Generate artwork inspired by Doom style 3.' },
	{ value: '26', label: 'Fantasy 1', description: 'Generate fantasy artwork style 1.' },
	{ value: '27', label: 'Fantasy 2', description: 'Generate fantasy artwork style 2.' },
	{ value: '28', label: 'Fantasy 3', description: 'Generate fantasy artwork style 3.' },
	{ value: '201', label: 'Fast Blur 1', description: 'Generate artwork with a fast blur effect.' },
	{ value: '159', label: 'Fractal Pattern 1', description: 'Generate artwork with fractal patterns.' },
	{ value: '182', label: 'Game Art 1', description: 'Generate game art style 1.' },
	{ value: '59', label: 'Gothic', description: 'Generate gothic-style artwork.' },
	{ value: '18', label: 'Graffiti', description: 'Generate artwork inspired by graffiti.' },
	{ value: '206', label: 'Halftone Dystopian 1', description: 'Generate dystopian artwork with halftone effect.' },
	{ value: '19', label: 'Art 1', description: 'Generate artwork style 1.' },
	{ value: '145', label: 'Art 10', description: 'Generate artwork style 10.' },
	{ value: '178', label: 'Art 11', description: 'Generate artwork style 11.' },
	{ value: '179', label: 'Art 12', description: 'Generate artwork style 12.' },
	{ value: '20', label: 'Art 2', description: 'Generate artwork style 2.' },
	{ value: '21', label: 'Art 3', description: 'Generate artwork style 3.' },
	{ value: '22', label: 'Art 5', description: 'Generate artwork style 5.' },
	{ value: '139', label: 'Art 8', description: 'Generate artwork style 8.' },
	{ value: '140', label: 'Art 9', description: 'Generate artwork style 9.' },
	{ value: '181', label: 'Ephemeral Wisp 1', description: 'Generate ephemeral wisp artwork.' },
	{ value: '121', label: 'Icon 3D 1', description: 'Generate 3D icons.' },
	{ value: '6', label: 'Icon Black White', description: 'Generate black and white icons.' },
	{ value: '120', label: 'Icon Black White 2', description: 'Generate black and white icons style 2.' },
	{ value: '122', label: 'Icon Cute 1', description: 'Generate cute icons style 1.' },
	{ value: '7', label: 'Icon Flat', description: 'Generate flat icons.' },
	{ value: '119', label: 'Icon Minimal 1', description: 'Generate minimalistic icons style 1.' },
	{ value: '9', label: 'Icon Sticker', description: 'Generate sticker icons.' },
	{ value: '8', label: 'Icon Sticker Black White', description: 'Generate black and white sticker icons.' },
	{ value: '103', label: 'Illustration Art 1', description: 'Generate illustration artwork style 1.' },
	{ value: '104', label: 'Illustration Art 2', description: 'Generate illustration artwork style 2.' },
	{ value: '130', label: 'Illustration Art 3', description: 'Generate illustration artwork style 3.' },
	{ value: '69', label: 'Illustration Flat', description: 'Generate flat illustration artwork.' },
	{ value: '53', label: 'Illustration General 1', description: 'Generate general illustration artwork style 1.' },
	{ value: '123', label: 'Illustration General 2', description: 'Generate general illustration artwork style 2.' },
	{ value: '118', label: 'Illustration General 4', description: 'Generate general illustration artwork style 4.' },
	{ value: '127', label: 'Illustration General 5', description: 'Generate general illustration artwork style 5.' },
	{
		value: '169',
		label: 'Illustration Palette 1',
		description: 'Generate illustration with a specific color palette style 1.',
	},
	{ value: '82', label: 'Illustration Smooth', description: 'Generate smooth illustration artwork.' },
	{ value: '196', label: 'Iridescent Marble 1', description: 'Generate artwork with iridescent marble effect.' },
	{ value: '203', label: 'Iridescent Metal 1', description: 'Generate artwork with iridescent metal effect.' },
	{ value: '124', label: 'Isometric 1', description: 'Generate isometric artwork style 1.' },
	{ value: '125', label: 'Isometric 2', description: 'Generate isometric artwork style 2.' },
	{ value: '16', label: 'Japanese Art', description: 'Generate Japanese art style.' },
	{ value: '153', label: 'Japanese Art 2', description: 'Generate Japanese art style 2.' },
	{ value: '58', label: 'Line Art', description: 'Generate line art style.' },
	{ value: '102', label: 'Line Art 2', description: 'Generate line art style 2.' },
	{ value: '67', label: 'Logo Clean 1', description: 'Generate clean logo style 1.' },
	{ value: '65', label: 'Logo Detailed 1', description: 'Generate detailed logo style 1.' },
	{ value: '66', label: 'Logo Draft 1', description: 'Generate draft logo style 1.' },
	{ value: '68', label: 'Logo Hipster 1', description: 'Generate hipster logo style 1.' },
	{ value: '116', label: 'Logo Illustration 1', description: 'Generate illustrated logo style 1.' },
	{ value: '198', label: 'Logo Minimal 1', description: 'Generate minimalistic logo style 1.' },
	{ value: '87', label: 'Logo Sticker 1', description: 'Generate sticker logo style 1.' },
	{ value: '96', label: 'Low Poly 1', description: 'Generate low poly artwork style 1.' },
	{ value: '97', label: 'Low Poly 2', description: 'Generate low poly artwork style 2.' },
	{ value: '39', label: 'Oil Painting 1', description: 'Generate oil painting artwork style 1.' },
	{ value: '147', label: 'Oil Painting 2', description: 'Generate oil painting artwork style 2.' },
	{ value: '165', label: 'Painting Black White 1', description: 'Generate black and white painting artwork.' },
	{ value: '89', label: 'Painting Claude Monet 1', description: 'Generate artwork inspired by Claude Monet.' },
	{ value: '160', label: 'Painting Fusion 1', description: 'Generate fusion painting artwork style 1.' },
	{ value: '162', label: 'Painting Fusion 3', description: 'Generate fusion painting artwork style 3.' },
	{ value: '166', label: 'Painting Fusion 4', description: 'Generate fusion painting artwork style 4.' },
	{ value: '167', label: 'Painting Fusion 5', description: 'Generate fusion painting artwork style 5.' },
	{ value: '168', label: 'Painting Geometric 1', description: 'Generate geometric painting artwork style 1.' },
	{ value: '88', label: 'Painting Huang Gongwang 1', description: 'Generate artwork inspired by Huang Gongwang.' },
	{ value: '90', label: 'Painting Pablo Picasso 1', description: 'Generate artwork inspired by Pablo Picasso.' },
	{ value: '91', label: 'Painting Paul Cezanne 1', description: 'Generate artwork inspired by Paul Cezanne.' },
	{ value: '92', label: 'Painting Salvador Dali 1', description: 'Generate artwork inspired by Salvador Dali.' },
	{
		value: '93',
		label: 'Painting Vincent Van Gogh 1',
		description: 'Generate artwork inspired by Vincent Van Gogh.',
	},
	{ value: '193', label: 'Photo Black White 1', description: 'Generate black and white photograph style.' },
	{ value: '161', label: 'Photo Cinematic 1', description: 'Generate cinematic photograph style 1.' },
	{ value: '194', label: 'Photo Cinematic 2', description: 'Generate cinematic photograph style 2.' },
	{ value: '164', label: 'Photo Dystopian 1', description: 'Generate dystopian photograph style 1.' },
	{ value: '171', label: 'Photo Dystopian 2', description: 'Generate dystopian photograph style 2.' },
	{ value: '114', label: 'Photo Fashion 1', description: 'Generate fashion photograph style 1.' },
	{ value: '197', label: 'Photo Fashion 2', description: 'Generate fashion photograph style 2.' },
	{ value: '49', label: 'Photo General 1', description: 'Generate general photograph style 1.' },
	{ value: '180', label: 'Photo General 2', description: 'Generate general photograph style 2.' },
	{
		value: '48',
		label: 'Photo General Volumetric Lighting 1',
		description: 'Generate general photograph with volumetric lighting.',
	},
	{ value: '154', label: 'Photo Moody 1', description: 'Generate moody photograph style.' },
	{ value: '24', label: 'Pixel Art', description: 'Generate pixel art.' },
	{ value: '205', label: 'Pizza Art 1', description: 'Generate artwork featuring pizza.' },
	{ value: '81', label: 'Pop Art', description: 'Generate pop art.' },
	{ value: '33', label: 'Portrait 1', description: 'Generate portrait artwork style 1.' },
	{ value: '138', label: 'Portrait 10', description: 'Generate portrait artwork style 10.' },
	{ value: '34', label: 'Portrait 2', description: 'Generate portrait artwork style 2.' },
	{ value: '35', label: 'Portrait 3', description: 'Generate portrait artwork style 3.' },
	{ value: '135', label: 'Portrait 4', description: 'Generate portrait artwork style 4.' },
	{ value: '132', label: 'Portrait 5', description: 'Generate portrait artwork style 5.' },
	{ value: '131', label: 'Portrait 6', description: 'Generate portrait artwork style 6.' },
	{ value: '136', label: 'Portrait 8', description: 'Generate portrait artwork style 8.' },
	{ value: '137', label: 'Portrait 9', description: 'Generate portrait artwork style 9.' },
	{ value: '107', label: 'Portrait Anime 1', description: 'Generate anime-style portrait artwork style 1.' },
	{ value: '108', label: 'Portrait Anime 2', description: 'Generate anime-style portrait artwork style 2.' },
	{ value: '109', label: 'Portrait Anime 3', description: 'Generate anime-style portrait artwork style 3.' },
	{ value: '110', label: 'Portrait Anime 4', description: 'Generate anime-style portrait artwork style 4.' },
	{ value: '134', label: 'Portrait Anime 5', description: 'Generate anime-style portrait artwork style 5.' },
	{ value: '174', label: 'Portrait Anime 6', description: 'Generate anime-style portrait artwork style 6.' },
	{ value: '175', label: 'Portrait Anime 7', description: 'Generate anime-style portrait artwork style 7.' },
	{ value: '188', label: 'Portrait Anime 8', description: 'Generate anime-style portrait artwork style 8.' },
	{
		value: '141',
		label: 'Portrait Concept Art 1',
		description: 'Generate concept art-style portrait artwork style 1.',
	},
	{
		value: '142',
		label: 'Portrait Concept Art 2',
		description: 'Generate concept art-style portrait artwork style 2.',
	},
	{
		value: '144',
		label: 'Portrait Concept Art 3',
		description: 'Generate concept art-style portrait artwork style 3.',
	},
	{ value: '95', label: 'Portrait Figurine 1', description: 'Generate figurine-style portrait artwork.' },
	{ value: '133', label: 'Portrait Game 6', description: 'Generate game-style portrait artwork style 6.' },
	{ value: '83', label: 'Portrait Game 1', description: 'Generate game-style portrait artwork style 1.' },
	{ value: '98', label: 'Portrait Game 2', description: 'Generate game-style portrait artwork style 2.' },
	{ value: '99', label: 'Portrait Game 3', description: 'Generate game-style portrait artwork style 3.' },
	{ value: '113', label: 'Portrait Game 4', description: 'Generate game-style portrait artwork style 4.' },
	{ value: '129', label: 'Portrait Game 5', description: 'Generate game-style portrait artwork style 5.' },
	{ value: '143', label: 'Portrait Game 7', description: 'Generate game-style portrait artwork style 7.' },
	{ value: '38', label: 'Portrait Gothic', description: 'Generate gothic-style portrait artwork.' },
	{ value: '115', label: 'Portrait Gothic 2', description: 'Generate gothic-style portrait artwork style 2.' },
	{ value: '37', label: 'Portrait Marble', description: 'Generate marble-style portrait artwork.' },
	{ value: '36', label: 'Portrait Mugshot', description: 'Generate mugshot-style portrait artwork.' },
	{ value: '172', label: 'Poster War Zone 1', description: 'Generate war zone-themed poster artwork.' },
	{ value: '101', label: 'Product Concept Art 1', description: 'Generate concept art for products style 1.' },
	{ value: '117', label: 'Psychedelic 1', description: 'Generate psychedelic artwork style 1.' },
	{ value: '80', label: 'Retro Art', description: 'Generate retro-style artwork.' },
	{ value: '64', label: 'Sci-fi 1', description: 'Generate sci-fi artwork style 1.' },
	{ value: '85', label: 'Sci-fi 2', description: 'Generate sci-fi artwork style 2.' },
	{ value: '86', label: 'Sci-fi 3', description: 'Generate sci-fi artwork style 3.' },
	{ value: '25', label: 'Sculpture General 1', description: 'Generate general sculpture artwork style 1.' },
	{ value: '163', label: 'Sculpture Glass 1', description: 'Generate glass sculpture artwork.' },
	{ value: '1', label: 'Sketch General 1', description: 'Generate general sketch artwork style 1.' },
	{ value: '3', label: 'Sketch General 3', description: 'Generate general sketch artwork style 3.' },
	{ value: '4', label: 'Sketch Scribble Black White 1', description: 'Generate black and white scribble sketch artwork.' },
	{ value: '5', label: 'Sketch Scribble Color 1', description: 'Generate color scribble sketch artwork.' },
	{ value: '78', label: 'Stained Glass 1', description: 'Generate stained glass-style artwork.' },
	{ value: '57', label: 'Steampunk', description: 'Generate steampunk-style artwork.' },
	{ value: '15', label: 'Watercolor 1', description: 'Generate watercolor artwork style 1.' },
	{ value: '155', label: 'Watercolor 2', description: 'Generate watercolor artwork style 2.' },
	{ value: '156', label: 'Watercolor 3', description: 'Generate watercolor artwork style 3.' },
	{ value: '170', label: 'Watercolor Black White 1', description: 'Generate black and white watercolor artwork.' }
];

export const StylesOptionsLeonardo = [
  { value: 'BOKEH', label: 'Bokeh', description: 'Generate artwork with a bokeh effect.' },
  { value: 'CINEMATIC', label: 'Cinematic', description: 'Generate cinematic-style artwork.' },
  { value: 'CINEMATIC_CLOSEUP', label: 'Cinematic (Closeup)', description: 'Generate closeup cinematic-style artwork.' },
  { value: 'CREATIVE', label: 'Creative', description: 'Generate creative-style artwork.' },
  { value: 'FASHION', label: 'Fashion', description: 'Generate fashion-style artwork.' },
  { value: 'FILM', label: 'Film', description: 'Generate film-style artwork.' },
  { value: 'FOOD', label: 'Food', description: 'Generate food-themed artwork.' },
  { value: 'HDR', label: 'HDR', description: 'Generate HDR-style artwork.' },
  { value: 'LONG_EXPOSURE', label: 'Long Exposure', description: 'Generate long exposure-style artwork.' },
  { value: 'MACRO', label: 'Macro', description: 'Generate macro-style artwork.' },
  { value: 'MINIMALISTIC', label: 'Minimalistic', description: 'Generate minimalistic-style artwork.' },
  { value: 'MONOCHROME', label: 'Monochrome', description: 'Generate monochrome-style artwork.' },
  { value: 'MOODY', label: 'Moody', description: 'Generate moody-style artwork.' },
  { value: 'NEUTRAL', label: 'Neutral', description: 'Generate neutral-style artwork.' },
  { value: 'PORTRAIT', label: 'Portrait', description: 'Generate portrait-style artwork.' },
  { value: 'RETRO', label: 'Retro', description: 'Generate retro-style artwork.' },
  { value: 'STOCK_PHOTO', label: 'Stock Photo', description: 'Generate stock photo-style artwork.' },
  { value: 'VIBRANT', label: 'Vibrant', description: 'Generate vibrant-style artwork.' },
  { value: 'UNPROCESSED', label: 'Unprocessed', description: 'Generate unprocessed-style artwork.' }
];

export default StylesOptions
