import React from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
import AdminRoutes from '../AdminRoutes/AdminRoutes';
import {
	AddSubtitleScreen,
	AIPoweredScriptWritingScreen,
	Checkout,
	AdsProductScreen,
	StoryTellerScreen,
	ProfileScreen,
	MyGalleriesScreen,
	GenerateImageScreen,
	HomeScreen,
	LikedImages,
	TransactionDetailScreen,
	TransactionHistoryScreen,
	VoiceDubbingScreen,
} from './LazyImports';

const routeConfig = [
	// feature
	{ path: '/feature/ads-product', component: AdsProductScreen, isFooter: false },
	{ path: '/feature/story-teller', component: StoryTellerScreen, isFooter: false },
	{ path: '/feature/image-creation', component: GenerateImageScreen, isFooter: false },
	{ path: '/feature/voice-dubbing', component: VoiceDubbingScreen },
	{ path: '/feature/subtitled-video', component: AddSubtitleScreen },

	// transaction
	{ path: '/transaction-history', component: TransactionHistoryScreen },
	{ path: '/transaction/:id', component: TransactionDetailScreen },
	{ path: '/checkout', component: Checkout },

	// user
	// { path: '/home', component: HomeScreen },
	{ path: '/my-profile', component: ProfileScreen },
	{ path: '/my-galleries', component: MyGalleriesScreen, isFooter: false },
	// { path: '/liked-images', component: LikedImages },
];

const AuthenticatedRoutes = (role) => (
	<>
		{routeConfig.map(({ path, component, exact, isFooter = true }) => (
			<Route
				key={path}
				path={path}
				exact={exact}
				element={<ProtectedRoute element={React.createElement(component)} isFooter={isFooter} />}
			/>
		))}
		{role === 'admin' && AdminRoutes()}
	</>
);

export default AuthenticatedRoutes;
