//jpg or jpeg
export const PeopleAds1 = require("./peopleAds1.jpg");
export const BgRegister = require("./bgregister.jpg");
export const CarouselImage1 = require("./carousel-1.jpg");
export const CarouselImage2 = require("./carousel-2.jpg");
export const CarouselImage3 = require("./carousel-3.jpg");
export const WallGallery = require("./wallgalery.jpg");

//png
export const AdsIcon = require("./logotype.png");
export const AdsIconColor = require("./logotypeColor.png");
export const Advertisement1 = require("./advertisement.png");
export const Tiktok = require("./tiktok.png");
export const Instagram = require("./instagram.png");
export const Twitter = require("./twitter.png");
export const TwitterColor = require("./twitterColor.png");
export const DownloadGeneratorText = require("./DownloadGeneratorText.png");
export const Civic = require("./civic.png");
export const Down = require("./down.png");
export const GooglePay = require("./google-pay.png");
export const Paypal = require("./paypal.png");
export const Visa = require("./visa.png");
export const MasterCard = require("./masterCard.png");
export const Upgrade = require("./upgrade.png");
export const AvatarDefault = require("./user.png");
export const uploadImage = require("./photo.png");
export const illuActivityUser = require("./activity_user_illu.png");
export const ICUpload = require("./ic_upload.png");
export const stepSelectLanguange = require("./GetStarted/selectlanguage.png");
export const stepProduct = require("./GetStarted/stepproduct.png");
export const stepSound = require("./GetStarted/stepSound.png");
export const stepImage = require("./GetStarted/stepImage.png");
export const IllustrationBot = require("./GetStarted/illustrationBot.png");
export const reelsStepLanguage = require("./GetStarted/reels/step-language.png");
export const reelsStepCaption = require("./GetStarted/reels/stap-caption-2.png");
export const reelsStepGenerateVoice = require("./GetStarted/reels/step-generate-voice.png");
export const reelsStepGenerateImage = require("./GetStarted/reels/step-generate-image.png");
export const subtitleStepLanguage = require("./GetStarted/subtitle-video/step-language.png");
export const subtitleStepType = require("./GetStarted/subtitle-video/step-type.png");
export const dubbingStepUpload = require("./GetStarted/dubbing/upload.png");
export const dubbingStepLanguage = require("./GetStarted/dubbing/select-language.png");
export const dubbingStepDownload = require("./GetStarted/dubbing/download.png");
export const generateImageStep = require("./GetStarted/generate-image/step-input-form.png");
export const landingImage = require("./landingImage.jpg");
export const chatGptIcon = require("./landingpage/chatgpt.png");
export const elevenlabsIcon = require("./landingpage/elevenlab.jpg");
export const hotpotIcon = require("./landingpage/hotpot.png");
export const shotstackIcon = require("./landingpage/shotstack.png");
export const ouveeCircle = require("./GetStarted/ouvee-circle-1.jpg");
export const ouveeCircle2 = require("./GetStarted/ouvee-circle-2.jpg");
export const ouveeCircle3 = require("./GetStarted/ouvee-cirlce-3.jpg");
export const ouveeCircle4 = require("./GetStarted/ouvee-cirlce-4.jpg");
export const ouveeCircle5 = require("./GetStarted/ouvee-cirlce-5.jpg");
export const adsLanguageStep = require("./GetStarted/adsprod/language.png");
export const adsProductStep = require("./GetStarted/adsprod/product.png");
export const adsAudioStep = require("./GetStarted/adsprod/audio.png");
export const adsOverviewStep = require("./GetStarted/adsprod/overview.png");
export const adsUploadImageStep = require("./GetStarted/adsprod/upload-image.png");
export const reelslanguage = require("./GetStarted/reels/language.png");
export const reelscaption = require("./GetStarted/reels/caption-result.png");
export const reelsvoice = require("./GetStarted/reels/voice.png");
export const reelsimage = require("./GetStarted/reels/image.png");
export const firefox = require("./firefox.png");
export const chrome = require("./chrome.png");
export const internetExplorer = require("./internetExplorer.png");
export const microsoft = require("./microsoft.png");
export const opera = require("./opera.png");
export const safari = require("./safari.png");
export const customerService = require("./customerService.png");
export const callCenter = require("./callCenter.png");
export const customerCare = require("./customerCare.png");
export const FilmReel = require("./film-reel.png");
export const MagicWand = require("./magic-wand.png");
export const AdsImage = require("./video-marketing.png");
export const icCopy = require("./copy.png");
export const icExpand = require("./open.png");
export const icVisa = require('./CC/visa.png')
export const icMasterCard = require('./CC/mc.png')
export const icMs = require('./CC/ms.png')
export const icMM = require('./CC/mm.png')

//gif
export const GeneratorText = require("./GeneratorText.gif");
export const ProductVid1 = require("./ad-product-sample-gif1.gif");
export const ReelsVideo1 = require("./reels-video1.gif");
export const ReelsVideo2 = require("./reels-story-3.gif");
export const ReelsVideo3 = require("./reels-story-4.gif");
export const ProductGif1 = require("./productGif1.gif");
export const ProductGif2 = require("./productGif2.gif");
export const ProductGif3 = require("./productGif3.gif");
export const ProductGif4 = require("./productGif4.gif");
export const AvatarMan = require("./avatar-man.gif");
