const Languages = [
	{ language: 'English (US)', placeHolder: '🇺🇸 English (US)' },
	{ language: 'English (UK)', placeHolder: '🇬🇧 English (UK)' },
	{ language: 'English (Welsh)', placeHolder: '🏴 English (Welsh)' },
	{ language: 'Welsh', placeHolder: '🏴 Welsh' },
	{ language: 'Danish', placeHolder: '🇩🇰 Danish' },
	{ language: 'German', placeHolder: '🇩🇪 German' },
	{ language: 'English (AU)', placeHolder: '🇦🇺 English (AU)' },
	{ language: 'English (India)', placeHolder: '🇮🇳 English (India)' },
	{ language: 'Spanish', placeHolder: '🇪🇸 Spanish' },
	{ language: 'French (Canada)', placeHolder: '🇨🇦 French (Canada)' },
	{ language: 'French', placeHolder: '🇫🇷 French' },
	{ language: 'Icelandic', placeHolder: '🇮🇸 Icelandic' },
	{ language: 'Italian', placeHolder: '🇮🇹 Italian' },
	{ language: 'Japanese', placeHolder: '🇯🇵 Japanese' },
	{ language: 'Norwegian', placeHolder: '🇳🇴 Norwegian' },
	{ language: 'Dutch', placeHolder: '🇳🇱 Dutch' },
	{ language: 'Polish', placeHolder: '🇵🇱 Polish' },
	{ language: 'Portuguese (BR)', placeHolder: '🇧🇷 Portuguese (BR)' },
	{ language: 'Portuguese', placeHolder: '🇵🇹 Portuguese' },
	{ language: 'Romanian', placeHolder: '🇷🇴 Romanian' },
	{ language: 'Russian', placeHolder: '🇷🇺 Russian' },
	{ language: 'Swedish', placeHolder: '🇸🇪 Swedish' },
	{ language: 'Turkish', placeHolder: '🇹🇷 Turkish' },
	{ language: 'Spanish (US)', placeHolder: '🇺🇸 Spanish (US)' },
	{ language: 'Arabic', placeHolder: '🇸🇦 Arabic' },
	{ language: 'Chinese', placeHolder: '🇨🇳 Chinese' },
	{ language: 'Hindi', placeHolder: '🇮🇳 Hindi' },
	{ language: 'Korean', placeHolder: '🇰🇷 Korean' },
	{ language: 'Spanish (MX)', placeHolder: '🇲🇽 Spanish (MX)' },
	{ language: 'French (Canadian)', placeHolder: '🇨🇦 French (Canadian)' },
	{ language: 'English (New Zealand)', placeHolder: '🇳🇿 English (New Zealand)' },
	{ language: 'English (South Africa)', placeHolder: '🇿🇦 English (South Africa)' },
	{ language: 'Vietnamese', placeHolder: '🇻🇳 Vietnamese' },
	{ language: 'Filipino', placeHolder: '🇵🇭 Filipino' },
	{ language: 'Indonesian', placeHolder: '🇮🇩 Indonesian' },
	{ language: 'Czech', placeHolder: '🇨🇿 Czech' },
	{ language: 'Greek', placeHolder: '🇬🇷 Greek' },
	{ language: 'Hungarian', placeHolder: '🇭🇺 Hungarian' },
	{ language: 'Slovak', placeHolder: '🇸🇰 Slovak' },
	{ language: 'Ukrainian', placeHolder: '🇺🇦 Ukrainian' },
	{ language: 'Finnish', placeHolder: '🇫🇮 Finnish' },
	{ language: 'Bengali (India)', placeHolder: '🇮🇳 Bengali (India)' },
	{ language: 'Gujarati (India)', placeHolder: '🇮🇳 Gujarati (India)' },
	{ language: 'Kannada (India)', placeHolder: '🇮🇳 Kannada (India)' },
	{ language: 'Malayalam (India)', placeHolder: '🇮🇳 Malayalam (India)' },
	{ language: 'Chinese (TW)', placeHolder: '🇹🇼 Chinese (TW)' },
	{ language: 'Tamil (India)', placeHolder: '🇮🇳 Tamil (India)' },
	{ language: 'Telugu (India)', placeHolder: '🇮🇳 Telugu (India)' },
	{ language: 'Thai', placeHolder: '🇹🇭 Thai' },
	{ language: 'Chinese (Cantonese)', placeHolder: '🇭🇰 Chinese (Cantonese)' },
	{ language: 'Afrikaans', placeHolder: '🇿🇦 Afrikaans' },
	{ language: 'Bulgarian', placeHolder: '🇧🇬 Bulgarian' },
	{ language: 'Catalan', placeHolder: '🇪🇸 Catalan' },
	{ language: 'Latvian', placeHolder: '🇱🇻 Latvian' },
	{ language: 'Serbian', placeHolder: '🇷🇸 Serbian' },
	{ language: 'Malay', placeHolder: '🇲🇾 Malay' },
	{ language: 'Dutch (Belgium)', placeHolder: '🇧🇪 Dutch (Belgium)' },
	{ language: 'Punjabi (India)', placeHolder: '🇮🇳 Punjabi (India)' },
	{ language: 'English (Australia)', placeHolder: '🇦🇺 English (Australia)' },
	{ language: 'Portuguese (Brazil)', placeHolder: '🇧🇷 Portuguese (Brazil)' },
	{ language: 'Swedish (Sweden)', placeHolder: '🇸🇪 Swedish (Sweden)' },
	{ language: 'Arabic (Egypt)', placeHolder: '🇪🇬 Arabic (Egypt)' },
	{ language: 'Arabic (Saudi Arabia)', placeHolder: '🇸🇦 Arabic (Saudi Arabia)' },
	{ language: 'English (Canada)', placeHolder: '🇨🇦 English (Canada)' },
	{ language: 'Croatian', placeHolder: '🇭🇷 Croatian' },
	{ language: 'English (Ireland)', placeHolder: '🇮🇪 English (Ireland)' },
	{ language: 'French (Switzerland)', placeHolder: '🇨🇭 French (Switzerland)' },
	{ language: 'German (Austria)', placeHolder: '🇦🇹 German (Austria)' },
	{ language: 'German (Switzerland)', placeHolder: '🇨🇭 German (Switzerland)' },
	{ language: 'Hebrew', placeHolder: '🇮🇱 Hebrew' },
	{ language: 'Slovenian', placeHolder: '🇸🇮 Slovenian' },
	{ language: 'Maltese', placeHolder: '🇲🇹 Maltese' },
	{ language: 'Lithuanian', placeHolder: '🇱🇹 Lithuanian' },
	{ language: 'Estonian', placeHolder: '🇪🇪 Estonian' },
	{ language: 'Irish', placeHolder: '🇮🇪 Irish' },
	{ language: 'English (Philippines)', placeHolder: '🇵🇭 English (Philippines)' },
	{ language: 'French (Belgium)', placeHolder: '🇧🇪 French (Belgium)' },
	{ language: 'Urdu', placeHolder: '🇵🇰 Urdu' },
	{ language: 'English (Hong Kong)', placeHolder: '🇭🇰 English (Hong Kong)' },
	{ language: 'English (Singapore)', placeHolder: '🇸🇬 English (Singapore)' },
	{ language: 'Marathi (India)', placeHolder: '🇮🇳 Marathi (India)' },
	{ language: 'Spanish (Argentina)', placeHolder: '🇦🇷 Spanish (Argentina)' },
	{ language: 'Spanish (Colombia)', placeHolder: '🇨🇴 Spanish (Colombia)' },
	{ language: 'Swahili (Kenya)', placeHolder: '🇰🇪 Swahili (Kenya)' },
	{ language: 'Amharic', placeHolder: '🇪🇹 Amharic' },
	{ language: 'Arabic (Algeria)', placeHolder: '🇩🇿 Arabic (Algeria)' },
	{ language: 'Arabic (Bahrain)', placeHolder: '🇧🇭 Arabic (Bahrain)' },
	{ language: 'Arabic (Iraq)', placeHolder: '🇮🇶 Arabic (Iraq)' },
	{ language: 'Arabic (Jordan)', placeHolder: '🇯🇴 Arabic (Jordan)' },
	{ language: 'Arabic (Kuwait)', placeHolder: '🇰🇼 Arabic (Kuwait)' },
	{ language: 'Arabic (Libya)', placeHolder: '🇱🇾 Arabic (Libya)' },
	{ language: 'Arabic (Morocco)', placeHolder: '🇲🇦 Arabic (Morocco)' },
	{ language: 'Arabic (Qatar)', placeHolder: '🇶🇦 Arabic (Qatar)' },
	{ language: 'Arabic (Syria)', placeHolder: '🇸🇾 Arabic (Syria)' },
	{ language: 'Arabic (Tunisia)', placeHolder: '🇹🇳 Arabic (Tunisia)' },
	{ language: 'Arabic (United Arab Emirates)', placeHolder: '🇦🇪 Arabic (United Arab Emirates)' },
	{ language: 'Arabic (Yemen)', placeHolder: '🇾🇪 Arabic (Yemen)' },
	{ language: 'Bengali', placeHolder: '🇧🇩 Bengali' },
	{ language: 'Burmese', placeHolder: '🇲🇲 Burmese' },
	{ language: 'English (Kenya)', placeHolder: '🇰🇪 English (Kenya)' },
	{ language: 'English (Nigeria)', placeHolder: '🇳🇬 English (Nigeria)' },
	{ language: 'English (Tanzania)', placeHolder: '🇹🇿 English (Tanzania)' },
	{ language: 'Galician', placeHolder: '🇪🇸 Galician' },
	{ language: 'Javanese', placeHolder: '🇮🇩 Javanese' },
	{ language: 'Khmer', placeHolder: '🇰🇭 Khmer' },
	{ language: 'Persian', placeHolder: '🇮🇷 Persian' },
	{ language: 'Somali', placeHolder: '🇸🇴 Somali' },
	{ language: 'Spanish (Bolivia)', placeHolder: '🇧🇴 Spanish (Bolivia)' },
	{ language: 'Spanish (Chile)', placeHolder: '🇨🇱 Spanish (Chile)' },
	{ language: 'Spanish (Costa Rica)', placeHolder: '🇨🇷 Spanish (Costa Rica)' },
	{ language: 'Spanish (Cuba)', placeHolder: '🇨🇺 Spanish (Cuba)' },
	{ language: 'Spanish (Dominican Republic)', placeHolder: '🇩🇴 Spanish (Dominican Republic)' },
	{ language: 'Spanish (Ecuador)', placeHolder: '🇪🇨 Spanish (Ecuador)' },
	{ language: 'Spanish (El Salvador)', placeHolder: '🇸🇻 Spanish (El Salvador)' },
	{ language: 'Spanish (Equatorial Guinea)', placeHolder: '🇬🇶 Spanish (Equatorial Guinea)' },
	{ language: 'Spanish (Guatemala)', placeHolder: '🇬🇹 Spanish (Guatemala)' },
	{ language: 'Spanish (Honduras)', placeHolder: '🇭🇳 Spanish (Honduras)' },
	{ language: 'Spanish (Nicaragua)', placeHolder: '🇳🇮 Spanish (Nicaragua)' },
	{ language: 'Spanish (Panama)', placeHolder: '🇵🇦 Spanish (Panama)' },
	{ language: 'Spanish (Paraguay)', placeHolder: '🇵🇾 Spanish (Paraguay)' },
	{ language: 'Spanish (Peru)', placeHolder: '🇵🇪 Spanish (Peru)' },
	{ language: 'Spanish (Puerto Rico)', placeHolder: '🇵🇷 Spanish (Puerto Rico)' },
	{ language: 'Spanish (Uruguay)', placeHolder: '🇺🇾 Spanish (Uruguay)' },
	{ language: 'Spanish (Venezuela)', placeHolder: '🇻🇪 Spanish (Venezuela)' },
	{ language: 'Sundanese', placeHolder: '🇮🇩 Sundanese' },
	{ language: 'Swahili (Tanzania)', placeHolder: '🇹🇿 Swahili (Tanzania)' },
	{ language: 'Tamil (Singapore)', placeHolder: '🇸🇬 Tamil (Singapore)' },
	{ language: 'Tamil (Sri Lanka)', placeHolder: '🇱🇰 Tamil (Sri Lanka)' },
	{ language: 'Urdu (India)', placeHolder: '🇮🇳 Urdu (India)' },
	{ language: 'Uzbek', placeHolder: '🇺🇿 Uzbek' },
	{ language: 'Zulu', placeHolder: '🇿🇦 Zulu' },
	{ language: 'Kazakh (Kazakhstan)', placeHolder: '🇰🇿 Kazakh (Kazakhstan)' },
	{ language: 'Lao (Laos)', placeHolder: '🇱🇦 Lao (Laos)' },
	{ language: 'Macedonian', placeHolder: '🇲🇰 Macedonian' },
	{ language: 'Pashto (Afghanistan)', placeHolder: '🇦🇫 Pashto (Afghanistan)' },
	{ language: 'Sinhala (Sri Lanka)', placeHolder: '🇱🇰 Sinhala (Sri Lanka)' },
	{ language: 'Albanian (Albania)', placeHolder: '🇦🇱 Albanian (Albania)' },
	{ language: 'Arabic (Lebanon)', placeHolder: '🇱🇧 Arabic (Lebanon)' },
	{ language: 'Arabic (Oman)', placeHolder: '🇴🇲 Arabic (Oman)' },
	{ language: 'Azerbaijani (Azerbaijan)', placeHolder: '🇦🇿 Azerbaijani (Azerbaijan)' },
	{ language: 'Bosnian (Bosnia and Herzegovina)', placeHolder: '🇧🇦 Bosnian (Bosnia and Herzegovina)' },
	{ language: 'Georgian (Georgia)', placeHolder: '🇬🇪 Georgian (Georgia)' },
	{ language: 'Mongolian (Mongolia)', placeHolder: '🇲🇳 Mongolian (Mongolia)' },
	{ language: 'Nepali (Nepal)', placeHolder: '🇳🇵 Nepali (Nepal)' },
	{ language: 'Tamil (Malaysia)', placeHolder: '🇲🇾 Tamil (Malaysia)' },
	{ language: 'Chinese (Mandarin, Simplified)', placeHolder: '🇨🇳 Chinese (Mandarin, Simplified)' },
	{ language: 'Italian (Italy)', placeHolder: '🇮🇹 Italian (Italy)' },
	{ language: 'Spanish (Mexico)', placeHolder: '🇲🇽 Spanish (Mexico)' },
];

const VoiceLanguages = [
	{
		value: 'Matthew',
		name: 'Matthew',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/0-sample-mathew-speech_20171218030814983.mp3?alt=media&token=63ec628e-aeed-4f9b-b6ba-0d684f9d033e',
	},
	{
		value: 'Joanna',
		name: 'Joanna',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-KgVhgobnKf5ImspJwLW.mp3?generation=1490902613862000&alt=media',
	},
	{
		value: 'Joey',
		name: 'Joey',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVIl2_XCjOOW4SCvlr.mp3?generation=1490895832648000&alt=media',
	},
	{
		value: 'Salli',
		name: 'Salli',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-KgVgmqm6Cd3so_R1rsw.mp3?generation=1490902327090000&alt=media',
	},
	{
		value: 'Amy',
		name: 'Amy',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVMvY3PjkOOHLo3rwH.mp3?generation=1490896906472000&alt=media',
	},
	{
		value: 'Brian',
		name: 'Brian',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-KgVeFVXKvcJV5pTGeXT.mp3?generation=1490901753456656&alt=media',
	},
	{
		value: 'Emma',
		name: 'Emma',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVMZR3Na89X_Jxu7-Z.mp3?generation=1490896816185000&alt=media',
	},
	{
		value: 'Geraint',
		name: 'Geraint',
		language: 'English (Welsh)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVLtlohVq67Sz7hw-x.mp3?generation=1490896629761000&alt=media',
	},
	{
		value: 'Gwyneth',
		name: 'Gwyneth',
		language: 'Welsh',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FGwyneth.mp3?alt=media&token=ae6f2307-571a-468d-988f-5c18534d8a15',
	},
	{
		value: 'Mads',
		name: 'Mads',
		language: 'Danish',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FMads.mp3?alt=media&token=e304661e-7174-4026-8e0c-b30afb7ac9a2',
	},
	{
		value: 'Naja',
		name: 'Naja',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FNaja.mp3?alt=media&token=959b45e8-fb37-4f22-9013-05428fdd3246',
	},
	{
		value: 'Hans',
		name: 'Hans',
		language: 'German',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/a1.mp3?alt=media&token=9eb1e854-969b-4d5b-9c61-f7a5340c7c62',
	},
	{
		value: 'Marlene',
		name: 'Marlene',
		language: 'German',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/a.mp3?alt=media&token=18ba5695-92fb-4605-b0f8-94f70b529a74',
	},
	{
		value: 'Nicole',
		name: 'Nicole',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVNWvbLR0CcfBa6BJN.mp3?generation=1490897260103000&alt=media',
	},
	{
		value: 'Russell',
		name: 'Russell',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVNOsZztJzvF0enGsT.mp3?generation=1490897242061000&alt=media',
	},
	{
		value: 'Raveena',
		name: 'Raveena',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-testing-412e4.appspot.com/o/full_-KgVMJ3aErhvcJdro38R.mp3?generation=1490896731578000&alt=media',
	},
	{
		value: 'Conchita',
		name: 'Conchita',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FConchita.mp3?alt=media&token=eddf8f9f-1955-4c64-b9f3-eba108bb44cf',
	},
	{
		value: 'Enrique',
		name: 'Enrique',
		language: 'Spanish',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FEnrique.mp3?alt=media&token=d63e84ae-0956-4aa1-ac4e-d1a632ed6314',
	},
	{
		value: 'Chantal',
		name: 'Chantal',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FChantal.mp3?alt=media&token=b99c5a0b-74f9-4a60-abee-e4255b938cee',
	},
	{
		value: 'Celine',
		name: 'Celine',
		language: 'French',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/celine.mp3?alt=media&token=c1c2518f-248f-4fad-802e-648debf8fc2c',
	},
	{
		value: 'Mathieu',
		name: 'Mathieu',
		language: 'French',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/mathieu.mp3?alt=media&token=b5cace53-24a1-446f-8616-a312319e4cba',
	},
	{
		value: 'Dora',
		name: 'Dora',
		language: 'Icelandic',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FD%C3%B3ra.mp3?alt=media&token=39b9c9c3-82b8-4c59-83eb-3d24940962fb',
	},
	{
		value: 'Karl',
		name: 'Karl',
		language: 'Icelandic',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FKarl.mp3?alt=media&token=37eceb3b-0377-429d-b12e-65dbc44f7805',
	},
	{
		value: 'Carla',
		name: 'Carla',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FCarla.mp3?alt=media&token=bcaef246-a165-4f43-aeea-5e8776295420',
	},
	{
		value: 'Giorgio',
		name: 'Giorgio',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FGiorgio.mp3?alt=media&token=ba66dbaf-63e2-455f-844f-ab63a3df3303',
	},
	{
		value: 'Mizuki',
		name: 'Mizuki',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FMizuki.mp3?alt=media&token=92639bd0-0467-43c6-b1a9-a005b2894fb9',
	},
	{
		value: 'Liv',
		name: 'Liv',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FLiv.mp3?alt=media&token=7e156cc7-bd9a-4ded-ac71-c43cb805711a',
	},
	{
		value: 'Lotte',
		name: 'Lotte',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FLotte.mp3?alt=media&token=2bb8eb47-4c38-4e59-9762-2cdbf3bad46f',
	},
	{
		value: 'Ruben',
		name: 'Ruben',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FRuben.mp3?alt=media&token=358ea32e-6344-4ee0-b47d-8d681e20e44e',
	},
	{
		value: 'Ewa',
		name: 'Ewa',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FEwa.mp3?alt=media&token=5d695c6d-3a5d-4267-9cb5-6c4dbac0179d',
	},
	{
		value: 'Jacek',
		name: 'Jacek',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FJacek.mp3?alt=media&token=720253d6-d729-4d33-83e2-4604630ee166',
	},
	{
		value: 'Jan',
		name: 'Jan',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FJan.mp3?alt=media&token=d3d5521d-c535-4d90-8364-6146670f264e',
	},
	{
		value: 'Maja',
		name: 'Maja',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FMaja.mp3?alt=media&token=6206a7c0-e844-4b99-bc8e-6139bb9341e5',
	},
	{
		value: 'Ricardo',
		name: 'Ricardo',
		language: 'Portuguese (BR)',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/ricardo.mp3?alt=media&token=6478fc45-88da-4232-b47c-f49005d1ea4c',
	},
	{
		value: 'Vitoria',
		name: 'Vitoria',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/vitro.mp3?alt=media&token=01d80348-4a0e-4399-b06a-04e5f86f72d4',
	},
	{
		value: 'Cristiano',
		name: 'Cristiano',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/cristiano-pr.mp3?alt=media&token=e6bf7009-4369-4f58-aa10-f7b25dbb54d1',
	},
	{
		value: 'Ines',
		name: 'Ines',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/ines-pr.mp3?alt=media&token=4eae6877-1c51-4427-8083-6caab3469b0f',
	},
	{
		value: 'Carmen',
		name: 'Carmen',
		language: 'Romanian',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FCarmen.mp3?alt=media&token=dc6cd29a-5055-4ee8-ab6f-d048186d1594',
	},
	{
		value: 'Maxim',
		name: 'Maxim',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/maxim.mp3?alt=media&token=a0bb06cc-2e30-49e7-ad38-2b15fde919b1',
	},
	{
		value: 'Tatyana',
		name: 'Tatyana',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/tatayana.mp3?alt=media&token=e8c3aa94-c9dc-4867-8e28-e0e680ab2883',
	},
	{
		value: 'Astrid',
		name: 'Astrid',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FAstrid.mp3?alt=media&token=daf72177-fcdc-45bc-82fa-3b76c610b932',
	},
	{
		value: 'Filiz',
		name: 'Filiz',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/samples%2FFiliz.mp3?alt=media&token=b949ca8f-fd70-4acf-a55f-2caff0f24ebc',
	},
	{
		value: 'Vicki',
		name: 'Vicki',
		language: 'German',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/vicki.mp3?alt=media&token=dba4480d-1ad8-44fc-82a4-b006d95fc2f9',
	},
	{
		value: 'Noah',
		name: 'Noah',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogZgW_92qU-g1m9Ggp.mp3?generation=1568410881344476&alt=media',
	},
	{
		value: 'Scarlett',
		name: 'Scarlett',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogY_-vcgXGYSusc9P8.mp3?generation=1568410588201308&alt=media',
	},
	{
		value: 'Jessica',
		name: 'Jessica',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogXFKxxLFk2j0tRh8w.mp3?generation=1568410241359811&alt=media',
	},
	{
		value: 'Lucas',
		name: 'Lucas',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-Log_ErN4hyuxgSyWVua.mp3?generation=1568411026629323&alt=media',
	},
	{
		value: 'Karen',
		name: 'Karen',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-Log_6suBUyY6tHc4L7_.mp3?generation=1568410993906132&alt=media',
	},
	{
		value: 'Victoria',
		name: 'Victoria',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogXjRYDhRnXoWh1IPK.mp3?generation=1568410368931237&alt=media',
	},
	{
		value: 'Ariana',
		name: 'Ariana',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogZNENVx8MbdgmEHEu.mp3?generation=1568410798307223&alt=media',
	},
	{
		value: 'Jennifer',
		name: 'Jennifer',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogWz9FOwQGZOraxF5o.mp3?generation=1568410183377035&alt=media',
	},
	{
		value: 'Emilia',
		name: 'Emilia',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-Log_xztPcPrYlK7njiY.mp3?generation=1568411215756565&alt=media',
	},
	{
		value: 'Logan',
		name: 'Logan',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogaK7lNTBxsnG4LXXs.mp3?generation=1568411310551159&alt=media',
	},
	{
		value: 'Anna',
		name: 'Anna',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LogaFSlFdkd0_de45l9.mp3?generation=1568411291097477&alt=media',
	},
	{
		value: 'Camila',
		name: 'Camila',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWi4gqZ7XveysGb8f3.mp3?generation=1577892471776082&alt=media',
	},
	{
		value: 'Lupe',
		name: 'Lupe',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWicBn6Rjsvn6Yi7Cn.mp3?generation=1577892613586843&alt=media',
	},
	{
		value: 'Gabriella',
		name: 'Gabriella',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://firebasestorage.googleapis.com/v0/b/play-68705.appspot.com/o/speech_20200502112008760.mp3?alt=media&token=6cecedbc-58bb-48ff-a1a7-96bbf02905ff',
	},
	{
		value: 'Matilde',
		name: 'Matilde',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWiHN2ThCm2w6HNlbx.mp3?generation=1577892524056406&alt=media',
	},
	{
		value: 'Isabella',
		name: 'Isabella',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWipo5_sYZqDHC0Tuq.mp3?generation=1577892670114310&alt=media',
	},
	{
		value: 'Zeina',
		name: 'Zeina',
		language: 'Arabic',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWgyp5cNhiXhSnGVkp.mp3?generation=1577892182650502&alt=media',
	},
	{
		value: 'Zhiyu',
		name: 'Zhiyu',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWhH8fapjDXxvPMMk8.mp3?generation=1577892261557151&alt=media ',
	},
	{
		value: 'Aditi',
		name: 'Aditi',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWkk-QtGFH-bs1LrKQ.mp3?generation=1577893170919694&alt=media ',
	},
	{
		value: 'Lea',
		name: 'Léa',
		language: 'French',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWkNiI87eh_JYZjHeQ.mp3?generation=1577893074461106&alt=media',
	},
	{
		value: 'Bianca',
		name: 'Bianca',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWkYcTnJR-p5XZO54L.mp3?generation=1577893118823990&alt=media',
	},
	{
		value: 'Miguel',
		name: 'Miguel',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWjE_Ota4LsQUMKHcY.mp3?generation=1577892774614999&alt=media',
	},
	{
		value: 'Penelope',
		name: 'Penelope',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWjQ7rRSkKvM2kL4Mq.mp3?generation=1577892822127244&alt=media',
	},
	{
		value: 'Seoyeon',
		name: 'Seoyeon',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWl-G6Oge0_BiqT_Ys.mp3?generation=1577893236905756&alt=media',
	},
	{
		value: 'Takumi',
		name: 'Takumi',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWlEVg48kq3Mr4vIJi.mp3?generation=1577893299166845&alt=media',
	},
	{
		value: 'Lucia',
		name: 'Lucia',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWjmfGFqFTI8WWnfin.mp3?generation=1577892919023354&alt=media',
	},
	{
		value: 'Mia',
		name: 'Mia',
		language: 'Spanish (MX)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LxWjwvL1IufJkN2CDfb.mp3?generation=1577892960781199&alt=media',
	},
	{
		value: 'Kevin',
		name: 'Kevin',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MNf1YAjOv08Blxke9e7.mp3',
	},
	{
		value: 'SeoyeonNeural',
		name: 'Seoyeon',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7qhC8fFDYHUDMvNkc.mp3',
	},
	{
		value: 'Gabrielle',
		name: 'Gabrielle',
		language: 'French (Canadian)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7qJZbI5dYKJ0qGgAf.mp3',
	},
	{
		value: 'VickiNeural',
		name: 'Vicki',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7qwOmypiqiIUn7jK5.mp3',
	},
	{
		value: 'Aria',
		name: 'Aria',
		language: 'English (New Zealand)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MmMil2qQMMUsPtYD8mW.mp3',
	},
	{
		value: 'Ayanda',
		name: 'Ayanda',
		language: 'English (South Africa)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MmMjIkOO4yHiwCCkNiJ.mp3',
	},
	{
		value: 'BiancaNeural',
		name: 'Bianca',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/BiancaNeural.mp3',
	},
	{
		value: 'LuciaNeural',
		name: 'Lucia',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/LuciaNeural.mp3',
	},
	{
		value: 'LeaNeural',
		name: 'Léa',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/LeaNeural.mp3',
	},
	{
		value: 'TakumiNeural',
		name: 'Takumi',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/TakumiNeural.mp3',
	},
	{
		value: 'en-US-Wavenet-A',
		name: 'James',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L9221lipExv_CkzIPB2.mp3?generation=1522617039958470&alt=media',
	},
	{
		value: 'en-US-Wavenet-B',
		name: 'Robert',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L922AzTmsp3o6vbEHja.mp3?generation=1522617077599546&alt=media',
	},
	{
		value: 'en-US-Wavenet-C',
		name: 'Patricia',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L922U22r6AkNB5neK_a.mp3?generation=1522617156348034&alt=media',
	},
	{
		value: 'en-US-Wavenet-D',
		name: 'Richard',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L922ZPVzHtQhUQWXoql.mp3?generation=1522617177761625&alt=media',
	},
	{
		value: 'en-US-Wavenet-E',
		name: 'Elizabeth',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L922ccDPTBRkh2a-OPC.mp3?generation=1522617195128886&alt=media',
	},
	{
		value: 'en-US-Wavenet-F',
		name: 'Linda',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L922hv4G__UzXDtQiaK.mp3?generation=1522617216961872&alt=media',
	},
	{
		value: 'en-US-Standard-B',
		name: 'Joseph',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L923OBYVWRXyjidKeza.mp3?generation=1522617393696202&alt=media',
	},
	{
		value: 'en-US-Standard-C',
		name: 'Sarah',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L923SPzNmFnpynKrLFD.mp3?generation=1522617411107453&alt=media',
	},
	{
		value: 'en-US-Standard-D',
		name: 'Charles',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L923_nGArb_c994Ksfb.mp3?generation=1522617445720251&alt=media',
	},
	{
		value: 'en-US-Standard-E',
		name: 'Nancy',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L923dyr-fVj18jKUZF9.mp3?generation=1522617462655974&alt=media',
	},
	{
		value: 'en-AU-Standard-A',
		name: 'Charlotte',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L9210HA-NVGzYcMAZfz.mp3?generation=1522616773273417&alt=media',
	},
	{
		value: 'en-AU-Standard-B',
		name: 'Oliver',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921NM0fEHtpVmjVpom.mp3?generation=1522616866081866&alt=media',
	},
	{
		value: 'en-AU-Standard-C',
		name: 'Olivia',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921RrJaARdr3tBkQfP.mp3?generation=1522616884878418&alt=media',
	},
	{
		value: 'en-AU-Standard-D',
		name: 'William',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921XJraXxWptOh8dj9.mp3?generation=1522616907012445&alt=media',
	},
	{
		value: 'en-AU-Wavenet-A',
		name: 'Chloe',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQA_8KBa_gpxoO6UJPE.mp3?generation=1541013809392628&alt=media',
	},
	{
		value: 'en-AU-Wavenet-B',
		name: 'Ethan',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQA_Rsg5B0I7qcINRtp.mp3?generation=1541013888282089&alt=media',
	},
	{
		value: 'en-AU-Wavenet-C',
		name: 'Ava',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQA_WzIjA082fQEb74T.mp3?generation=1541013910287852&alt=media',
	},
	{
		value: 'en-AU-Wavenet-D',
		name: 'Jackson',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQA_bVFtl-7c_fHIa4E.mp3?generation=1541013931631971&alt=media',
	},
	{
		value: 'en-GB-Standard-A',
		name: 'Amelia',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921f0tUI3vZaN0c1VM.mp3?generation=1522616942374930&alt=media',
	},
	{
		value: 'en-GB-Standard-B',
		name: 'Jack',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921m2Z4NoZStjD_AV6.mp3?generation=1522616971411564&alt=media',
	},
	{
		value: 'en-GB-Standard-C',
		name: 'Mia',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921qRIALLO29qrLerX.mp3?generation=1522616989155611&alt=media',
	},
	{
		value: 'en-GB-Standard-D',
		name: 'Thomas',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L921vgvrH9P6qCJgjH4.mp3?generation=1522617011138861&alt=media',
	},
	{
		value: 'en-GB-Standard-F',
		name: 'Sandy',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-GB-Standard-F.wav',
	},
	{
		value: 'en-GB-Wavenet-A',
		name: 'Isla',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAaA0KoRwYqEFL75Bw.mp3?generation=1541014077276567&alt=media',
	},
	{
		value: 'en-GB-Wavenet-B',
		name: 'Harry',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAaNU9-PM3vIRYvYjT.mp3?generation=1541014132385070&alt=media',
	},
	{
		value: 'en-GB-Wavenet-C',
		name: 'Hannah',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAaTNolT67VXJZLZ8P.mp3?generation=1541014156447725&alt=media',
	},
	{
		value: 'en-GB-Wavenet-D',
		name: 'Charlie',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAaYzxSCupOIZ4kTxV.mp3?generation=1541014179637263&alt=media',
	},
	{
		value: 'en-GB-Wavenet-F',
		name: 'Kathy',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-GB-Wavenet-F.wav',
	},
	{
		value: 'fr-FR-Standard-A',
		name: 'Louise',
		language: 'French',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbNGVHkq-tkibHRum.mp3?generation=1541014394087052&alt=media',
	},
	{
		value: 'fr-FR-Standard-B',
		name: 'Paul',
		language: 'French',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbSgg9Bdx98qncoOT.mp3?generation=1541014415985283&alt=media',
	},
	{
		value: 'fr-FR-Standard-C',
		name: 'Emma',
		language: 'French',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L923vnmOO3QFSVOFZbU.mp3?generation=1522617536572176&alt=media',
	},
	{
		value: 'fr-FR-Standard-D',
		name: 'Nathan',
		language: 'French',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L9240JvANVoimsroxfR.mp3?generation=1522617558473289&alt=media',
	},
	{
		value: 'fr-FR-Standard-E',
		name: 'Amélie',
		language: 'French',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fr-FR-Standard-E.wav',
	},
	{
		value: 'fr-FR-Wavenet-A',
		name: 'Adele',
		language: 'French',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbZg8yrp1sGFxmjYn.mp3?generation=1541014444619035&alt=media',
	},
	{
		value: 'fr-FR-Wavenet-B',
		name: 'Raphael',
		language: 'French',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbePA-21ck-dg3lvs.mp3?generation=1541014468028993&alt=media',
	},
	{
		value: 'fr-FR-Wavenet-C',
		name: 'Lina',
		language: 'French',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbiEw_gYRWamoY5QA.mp3?generation=1541014484583866&alt=media',
	},
	{
		value: 'fr-FR-Wavenet-D',
		name: 'Victor',
		language: 'French',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAbpGm2-Kf1b6Rp7rp.mp3?generation=1541014517428182&alt=media',
	},
	{
		value: 'fr-FR-Wavenet-E',
		name: 'Bella',
		language: 'French',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fr-FR-Wavenet-E.wav',
	},
	{
		value: 'fr-CA-Standard-A',
		name: 'Lola',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L9247C4JRNOf6ER7tbD.mp3?generation=1522617587086683&alt=media',
	},
	{
		value: 'fr-CA-Standard-B',
		name: 'Gabriel',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L924CBjdScK5csvuN_P.mp3?generation=1522617607177710&alt=media',
	},
	{
		value: 'fr-CA-Standard-C',
		name: 'Camille',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L924H8IeC-fH1LVOPXg.mp3?generation=1522617627817767&alt=media',
	},
	{
		value: 'fr-CA-Standard-D',
		name: 'Arthur',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L924LYiIIEAaFpOzHZA.mp3?generation=1522617645991034&alt=media',
	},
	{
		value: 'de-DE-Standard-A',
		name: 'Lara',
		language: 'German',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L924_sACJisZp7qnHHT.mp3?generation=1522617708348511&alt=media',
	},
	{
		value: 'de-DE-Standard-B',
		name: 'Leon',
		language: 'German',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L924eGnGaKpyv6veC4k.mp3?generation=1522617726144594&alt=media',
	},
	{
		value: 'de-DE-Standard-E',
		name: 'Maximilian',
		language: 'German',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Standard-E.wav',
	},
	{
		value: 'de-DE-Standard-F',
		name: 'Clara',
		language: 'German',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Standard-F.wav',
	},
	{
		value: 'de-DE-Wavenet-A',
		name: 'Marie',
		language: 'German',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAcfcr1Lk_zyuhNaGp.mp3?generation=1541014735484604&alt=media',
	},
	{
		value: 'de-DE-Wavenet-B',
		name: 'Otto',
		language: 'German',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAck2_bRvPkGnQs2p1.mp3?generation=1541014753268737&alt=media',
	},
	{
		value: 'de-DE-Wavenet-C',
		name: 'Mila',
		language: 'German',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAcrm6hydmyZ6ubvul.mp3?generation=1541014785445956&alt=media',
	},
	{
		value: 'de-DE-Wavenet-D',
		name: 'Kurt',
		language: 'German',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAcy0Xjb8tVTSBESLN.mp3?generation=1541014810821320&alt=media',
	},
	{
		value: 'de-DE-Wavenet-E',
		name: 'Moritz',
		language: 'German',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Wavenet-E.wav',
	},
	{
		value: 'de-DE-Wavenet-F',
		name: 'Louisa',
		language: 'German',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Wavenet-F.wav',
	},
	{
		value: 'nl-NL-Standard-A',
		name: 'Sophie',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L95MH1xBVUthlPxyX5_.mp3?generation=1522672676340930&alt=media',
	},
	{
		value: 'nl-NL-Wavenet-A',
		name: 'Julia',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAguHtfde_hXpsKHUM.mp3?generation=1541015842527540&alt=media',
	},
	{
		value: 'it-IT-Standard-A',
		name: 'Giulia',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAdmPk8V-Y4oqovhUw.mp3?generation=1541015025512262&alt=media',
	},
	{
		value: 'it-IT-Wavenet-A',
		name: 'Martina',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAdyhAR4EVlMJGrZTp.mp3?generation=1541015076025194&alt=media',
	},
	{
		value: 'ja-JP-Standard-A',
		name: 'Hana',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L925rNYFrOFk6mzG2xm.mp3?generation=1522618042248373&alt=media',
	},
	{
		value: 'ja-JP-Wavenet-A',
		name: 'Yui',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAeJOB7y338xxay2E1.mp3?generation=1541015164749315&alt=media',
	},
	{
		value: 'ko-KR-Standard-A',
		name: 'Seo-yeon',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAgOeG00TgPeXQ_PRU.mp3?generation=1541015708689944&alt=media',
	},
	{
		value: 'ko-KR-Wavenet-A',
		name: 'Ha-yoon',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LQAgV5tUlbfzqbpLwuE.mp3?generation=1541015735680246&alt=media',
	},
	{
		value: 'pt-BR-Standard-A',
		name: 'Maria',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L925cxln2xviT1uu06s.mp3?generation=1522617982991887&alt=media',
	},
	{
		value: 'es-ES-Standard-A',
		name: 'Nora',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L925GfVP0BiQDMCo5RX.mp3?generation=1522617887668496&alt=media',
	},
	{
		value: 'sv-SE-Standard-A',
		name: 'Alice',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L95Lo4SNhicdJsNH9uu.mp3?generation=1522672557818515&alt=media',
	},
	{
		value: 'tr-TR-Standard-A',
		name: 'Mira',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-L95M1GCFhGaZ9u5yBEt.mp3?generation=1522672613867552&alt=media',
	},
	{
		value: 'ru-RU-Standard-A',
		name: 'Galina',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Standard-A.mp3?generation=1577739011726330&alt=media',
	},
	{
		value: 'ru-RU-Standard-B',
		name: 'Abram',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Standard-B.mp3?generation=1577739014423122&alt=media',
	},
	{
		value: 'ru-RU-Standard-C',
		name: 'Katina',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Standard-C.mp3?generation=1577739016564093&alt=media',
	},
	{
		value: 'ru-RU-Standard-D',
		name: 'Borya',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Standard-D.mp3?generation=1577739019110135&alt=media',
	},
	{
		value: 'ru-RU-Standard-E',
		name: 'Lada',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ru-RU-Standard-E.wav',
	},
	{
		value: 'cmn-CN-Standard-A',
		name: 'Yu Yan',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Standard-A.mp3?generation=1577739022757971&alt=media',
	},
	{
		value: 'cmn-CN-Standard-B',
		name: 'Zhang Wei',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Standard-B.mp3?generation=1577739024779796&alt=media',
	},
	{
		value: 'cmn-CN-Standard-C',
		name: 'Wang Fang',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Standard-C.mp3?generation=1577739027176084&alt=media',
	},
	{
		value: 'cmn-CN-Standard-D',
		name: 'Chunhua',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-CN-Standard-D.wav',
	},
	{
		value: 'ko-KR-Standard-B',
		name: 'Bo-young',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Standard-B.mp3?generation=1577739032777051&alt=media',
	},
	{
		value: 'ko-KR-Standard-C',
		name: 'Baul',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Standard-C.mp3?generation=1577739035427096&alt=media',
	},
	{
		value: 'ko-KR-Standard-D',
		name: 'Chae-koo',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Standard-D.mp3?generation=1577739038153498&alt=media',
	},
	{
		value: 'ja-JP-Standard-B',
		name: 'Himari',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Standard-B.mp3?generation=1577739040541073&alt=media',
	},
	{
		value: 'ja-JP-Standard-C',
		name: 'Daiki',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Standard-C.mp3?generation=1577739043091979&alt=media',
	},
	{
		value: 'ja-JP-Standard-D',
		name: 'Eito',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Standard-D.mp3?generation=1577739046075102&alt=media',
	},
	{
		value: 'vi-VN-Standard-A',
		name: 'Bich',
		language: 'Vietnamese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Standard-A.mp3?generation=1577739048458246&alt=media',
	},
	{
		value: 'vi-VN-Standard-B',
		name: 'Chi',
		language: 'Vietnamese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Standard-B.mp3?generation=1577739050975752&alt=media',
	},
	{
		value: 'vi-VN-Standard-C',
		name: 'Cam',
		language: 'Vietnamese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Standard-C.mp3?generation=1577739053306565&alt=media',
	},
	{
		value: 'vi-VN-Standard-D',
		name: 'Danh',
		language: 'Vietnamese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Standard-D.mp3?generation=1577739055778029&alt=media',
	},
	{
		value: 'fil-PH-Standard-A',
		name: 'Dalisay',
		language: 'Filipino',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fil-PH-Standard-A.mp3?generation=1577739058248553&alt=media',
	},
	{
		value: 'fil-PH-Standard-B',
		name: 'Rosa',
		language: 'Filipino',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Standard-B.wav',
	},
	{
		value: 'fil-PH-Standard-C',
		name: 'Armando',
		language: 'Filipino',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Standard-C.wav',
	},
	{
		value: 'fil-PH-Standard-D',
		name: 'Aries',
		language: 'Filipino',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Standard-D.wav',
	},
	{
		value: 'id-ID-Standard-A',
		name: 'Farah',
		language: 'Indonesian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Standard-A.mp3?generation=1577739060496907&alt=media',
	},
	{
		value: 'id-ID-Standard-B',
		name: 'Angga',
		language: 'Indonesian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Standard-B.mp3?generation=1577739063177442&alt=media',
	},
	{
		value: 'id-ID-Standard-C',
		name: 'Farel',
		language: 'Indonesian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Standard-C.mp3?generation=1577739065530130&alt=media',
	},
	{
		value: 'id-ID-Standard-D',
		name: 'Andini',
		language: 'Indonesian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/id-ID-Standard-D.wav',
	},
	{
		value: 'nl-NL-Standard-B',
		name: 'Bram',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Standard-B.mp3?generation=1577739067887387&alt=media',
	},
	{
		value: 'nl-NL-Standard-C',
		name: 'Lucas',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Standard-C.mp3?generation=1577739070718970&alt=media',
	},
	{
		value: 'nl-NL-Standard-D',
		name: 'Tess',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Standard-D.mp3?generation=1577739073394658&alt=media',
	},
	{
		value: 'nl-NL-Standard-E',
		name: 'Fenna',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Standard-E.mp3?generation=1577739076407888&alt=media',
	},
	{
		value: 'cs-CZ-Standard-A',
		name: 'Adina',
		language: 'Czech',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cs-CZ-Standard-A.mp3?generation=1577739078563687&alt=media',
	},
	{
		value: 'el-GR-Standard-A',
		name: 'Gaia',
		language: 'Greek',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/el-GR-Standard-A.mp3?generation=1577739080795937&alt=media',
	},
	{
		value: 'hu-HU-Standard-A',
		name: 'Adel',
		language: 'Hungarian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hu-HU-Standard-A.mp3?generation=1577739083089621&alt=media',
	},
	{
		value: 'pl-PL-Standard-A',
		name: 'Zofia',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Standard-A.mp3?generation=1577739088445536&alt=media',
	},
	{
		value: 'pl-PL-Standard-B',
		name: 'Kacper',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Standard-B.mp3?generation=1577739090988285&alt=media',
	},
	{
		value: 'pl-PL-Standard-C',
		name: 'Filip',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Standard-C.mp3?generation=1577739093364355&alt=media',
	},
	{
		value: 'pl-PL-Standard-D',
		name: 'Aleksandra',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Standard-D.mp3?generation=1577739096517775&alt=media',
	},
	{
		value: 'pl-PL-Standard-E',
		name: 'Lena',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Standard-E.mp3?generation=1577739086103631&alt=media',
	},
	{
		value: 'sk-SK-Standard-A',
		name: 'Natalia',
		language: 'Slovak',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/sk-SK-Standard-A.mp3?generation=1577739099046243&alt=media',
	},
	{
		value: 'tr-TR-Standard-B',
		name: 'Ahmet',
		language: 'Turkish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Standard-B.mp3?generation=1577739101152837&alt=media',
	},
	{
		value: 'tr-TR-Standard-C',
		name: 'Aysel',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Standard-C.mp3?generation=1577739103408828&alt=media',
	},
	{
		value: 'tr-TR-Standard-D',
		name: 'Aysun',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Standard-D.mp3?generation=1577739105651089&alt=media',
	},
	{
		value: 'tr-TR-Standard-E',
		name: 'Ayaz',
		language: 'Turkish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Standard-E.mp3?generation=1577739108489383&alt=media',
	},
	{
		value: 'uk-UA-Standard-A',
		name: 'Yulia',
		language: 'Ukrainian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/uk-UA-Standard-A.mp3?generation=1577739110551869&alt=media',
	},
	{
		value: 'en-IN-Standard-A',
		name: 'Alisha',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Standard-A.mp3?generation=1577739112789160&alt=media',
	},
	{
		value: 'en-IN-Standard-B',
		name: 'Sai',
		language: 'English (India)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Standard-B.mp3?generation=1577739114824760&alt=media',
	},
	{
		value: 'en-IN-Standard-C',
		name: 'Ayaan',
		language: 'English (India)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Standard-C.mp3?generation=1577739117076008&alt=media',
	},
	{
		value: 'en-IN-Standard-D',
		name: 'Ananya',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-IN-Standard-D.wav',
	},
	{
		value: 'hi-IN-Standard-A',
		name: 'Esha',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Standard-A.mp3?generation=1577739119452901&alt=media',
	},
	{
		value: 'hi-IN-Standard-B',
		name: 'Ansh',
		language: 'Hindi',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Standard-B.mp3?generation=1577739121684251&alt=media',
	},
	{
		value: 'hi-IN-Standard-C',
		name: 'Krishna',
		language: 'Hindi',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Standard-C.mp3?generation=1577739131934950&alt=media',
	},
	{
		value: 'hi-IN-Standard-D',
		name: 'Adhira',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/hi-IN-Standard-D.wav',
	},
	{
		value: 'da-DK-Standard-A',
		name: 'Agathe',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/da-DK-Standard-A.mp3?generation=1577739133929183&alt=media',
	},
	{
		value: 'da-DK-Standard-C',
		name: 'Oscar',
		language: 'Danish',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Standard-C.wav',
	},
	{
		value: 'da-DK-Standard-D',
		name: 'Alberte',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Standard-D.wav',
	},
	{
		value: 'da-DK-Standard-E',
		name: 'Caroline',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Standard-E.wav',
	},
	{
		value: 'fi-FI-Standard-A',
		name: 'Anja',
		language: 'Finnish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fi-FI-Standard-A.mp3?generation=1577739136364976&alt=media',
	},
	{
		value: 'pt-PT-Standard-A',
		name: 'Leonor',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Standard-A.mp3?generation=1577739138631598&alt=media',
	},
	{
		value: 'pt-PT-Standard-B',
		name: 'Duda',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Standard-B.mp3?generation=1577739141336142&alt=media',
	},
	{
		value: 'pt-PT-Standard-C',
		name: 'Emilio',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Standard-C.mp3?generation=1577739149572372&alt=media',
	},
	{
		value: 'pt-PT-Standard-D',
		name: 'Beatriz',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Standard-D.mp3?generation=1577739152232305&alt=media',
	},
	{
		value: 'nb-NO-Standard-A',
		name: 'Anja',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Standard-A.mp3?generation=1577739157027630&alt=media',
	},
	{
		value: 'nb-NO-Standard-B',
		name: 'Filip',
		language: 'Norwegian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Standard-B.mp3?generation=1577739159581968&alt=media',
	},
	{
		value: 'nb-NO-Standard-C',
		name: 'Hella',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Standard-C.mp3?generation=1577739162331056&alt=media',
	},
	{
		value: 'nb-NO-Standard-D',
		name: 'Jakob',
		language: 'Norwegian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Standard-D.mp3?generation=1577739164615116&alt=media',
	},
	{
		value: 'nb-no-Standard-E',
		name: 'Agot',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-no-Standard-E.mp3?generation=1577739154403791&alt=media',
	},
	{
		value: 'it-IT-Standard-B',
		name: 'Amara',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Standard-B.mp3?generation=1577739167986420&alt=media',
	},
	{
		value: 'it-IT-Standard-C',
		name: 'Simone',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Standard-C.mp3?generation=1577739170434918&alt=media',
	},
	{
		value: 'it-IT-Standard-D',
		name: 'Diego',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Standard-D.mp3?generation=1577739178105559&alt=media',
	},
	{
		value: 'ar-XA-Wavenet-A',
		name: 'Aisha',
		language: 'Arabic',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Wavenet-A.mp3?generation=1577738276541750&alt=media',
	},
	{
		value: 'ar-XA-Wavenet-B',
		name: 'Amjad',
		language: 'Arabic',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Wavenet-B.mp3?generation=1577738278824389&alt=media',
	},
	{
		value: 'ar-XA-Wavenet-C',
		name: 'Hazem',
		language: 'Arabic',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Wavenet-C.mp3?generation=1577738281429059&alt=media',
	},
	{
		value: 'cmn-CN-Wavenet-A',
		name: 'Zhi Ruo',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Wavenet-A.mp3?generation=1577738283601861&alt=media',
	},
	{
		value: 'cmn-CN-Wavenet-B',
		name: 'Li Wei',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Wavenet-B.mp3?generation=1577738285782054&alt=media',
	},
	{
		value: 'cmn-CN-Wavenet-C',
		name: 'Li Na',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cmn-CN-Wavenet-C.mp3?generation=1577738288030285&alt=media',
	},
	{
		value: 'cmn-CN-Wavenet-D',
		name: 'Meifen',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-CN-Wavenet-D.wav',
	},
	{
		value: 'cs-CZ-Wavenet-A',
		name: 'Adriana',
		language: 'Czech',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/cs-CZ-Wavenet-A.mp3?generation=1577738290065529&alt=media',
	},
	{
		value: 'da-DK-Wavenet-A',
		name: 'Agnes',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/da-DK-Wavenet-A.mp3?generation=1577738292729608&alt=media',
	},
	{
		value: 'da-DK-Wavenet-C',
		name: 'Malthe',
		language: 'Danish',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Wavenet-C.wav',
	},
	{
		value: 'da-DK-Wavenet-D',
		name: 'Josefine',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Wavenet-D.wav',
	},
	{
		value: 'da-DK-Wavenet-E',
		name: 'Sofie',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/da-DK-Wavenet-E.wav',
	},
	{
		value: 'el-GR-Wavenet-A',
		name: 'Hera',
		language: 'Greek',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/el-GR-Wavenet-A.mp3?generation=1577738294839977&alt=media',
	},
	{
		value: 'en-IN-Wavenet-A',
		name: 'Amara',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Wavenet-A.mp3?generation=1577738297793278&alt=media',
	},
	{
		value: 'en-IN-Wavenet-B',
		name: 'Vivaan',
		language: 'English (India)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Wavenet-B.mp3?generation=1577738300354038&alt=media',
	},
	{
		value: 'en-IN-Wavenet-C',
		name: 'Aditya',
		language: 'English (India)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/en-IN-Wavenet-C.mp3?generation=1577738302754049&alt=media',
	},
	{
		value: 'en-IN-Wavenet-D',
		name: 'Amoli',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-IN-Wavenet-D.wav',
	},
	{
		value: 'fi-FI-Wavenet-A',
		name: 'Anneli',
		language: 'Finnish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fi-FI-Wavenet-A.mp3?generation=1577738304902879&alt=media',
	},
	{
		value: 'fil-PH-Wavenet-A',
		name: 'Analyn',
		language: 'Filipino',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fil-PH-Wavenet-A.mp3?generation=1577738307208123&alt=media',
	},
	{
		value: 'fil-PH-Wavenet-B',
		name: 'Althea',
		language: 'Filipino',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Wavenet-B.wav',
	},
	{
		value: 'fil-PH-Wavenet-C',
		name: 'Alec',
		language: 'Filipino',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Wavenet-C.wav',
	},
	{
		value: 'fil-PH-Wavenet-D',
		name: 'Adonis',
		language: 'Filipino',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/fil-PH-Wavenet-D.wav',
	},
	{
		value: 'fr-CA-Wavenet-A',
		name: 'Brigitte',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fr-CA-Wavenet-A.mp3?generation=1577738309588769&alt=media',
	},
	{
		value: 'fr-CA-Wavenet-B',
		name: 'Victor',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fr-CA-Wavenet-B.mp3?generation=1577738312009079&alt=media',
	},
	{
		value: 'fr-CA-Wavenet-C',
		name: 'Charlotte',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fr-CA-Wavenet-C.mp3?generation=1577738314380445&alt=media',
	},
	{
		value: 'fr-CA-Wavenet-D',
		name: 'Jules',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/fr-CA-Wavenet-D.mp3?generation=1577738316852341&alt=media',
	},
	{
		value: 'hi-IN-Wavenet-A',
		name: 'Deepa',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Wavenet-A.mp3?generation=1577738319088814&alt=media',
	},
	{
		value: 'hi-IN-Wavenet-B',
		name: 'Ishaan',
		language: 'Hindi',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Wavenet-B.mp3?generation=1577738321652301&alt=media',
	},
	{
		value: 'hi-IN-Wavenet-C',
		name: 'Rudra',
		language: 'Hindi',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hi-IN-Wavenet-C.mp3?generation=1577738323871516&alt=media',
	},
	{
		value: 'hi-IN-Wavenet-D',
		name: 'Jyothi',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/hi-IN-Wavenet-D.wav',
	},
	{
		value: 'hu-HU-Wavenet-A',
		name: 'Aliz',
		language: 'Hungarian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/hu-HU-Wavenet-A.mp3?generation=1577738326155297&alt=media',
	},
	{
		value: 'id-ID-Wavenet-A',
		name: 'Cindy',
		language: 'Indonesian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Wavenet-A.mp3?generation=1577738328897775&alt=media',
	},
	{
		value: 'id-ID-Wavenet-B',
		name: 'Andy',
		language: 'Indonesian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Wavenet-B.mp3?generation=1577738331357407&alt=media',
	},
	{
		value: 'id-ID-Wavenet-C',
		name: 'Aditya',
		language: 'Indonesian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/id-ID-Wavenet-C.mp3?generation=1577738333665083&alt=media',
	},
	{
		value: 'id-ID-Wavenet-D',
		name: 'Widya',
		language: 'Indonesian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/id-ID-Wavenet-D.wav',
	},
	{
		value: 'it-IT-Wavenet-B',
		name: 'Editta',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Wavenet-B.mp3?generation=1577738335867160&alt=media',
	},
	{
		value: 'it-IT-Wavenet-C',
		name: 'Lorenzo',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Wavenet-C.mp3?generation=1577738338481471&alt=media',
	},
	{
		value: 'it-IT-Wavenet-D',
		name: 'Mattia',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/it-IT-Wavenet-D.mp3?generation=1577738341837645&alt=media',
	},
	{
		value: 'ja-JP-Wavenet-B',
		name: 'Hina',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Wavenet-B.mp3?generation=1577738344156604&alt=media',
	},
	{
		value: 'ja-JP-Wavenet-C',
		name: 'Akira',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Wavenet-C.mp3?generation=1577738351965290&alt=media',
	},
	{
		value: 'ja-JP-Wavenet-D',
		name: 'Aito',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ja-JP-Wavenet-D.mp3?generation=1577738354188304&alt=media',
	},
	{
		value: 'ko-KR-Wavenet-B',
		name: 'Chan-sook',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Wavenet-B.mp3?generation=1577738356888285&alt=media',
	},
	{
		value: 'ko-KR-Wavenet-C',
		name: 'Cheol',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Wavenet-C.mp3?generation=1577738359324598&alt=media',
	},
	{
		value: 'ko-KR-Wavenet-D',
		name: 'Chang-min',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ko-KR-Wavenet-D.mp3?generation=1577738362072975&alt=media',
	},
	{
		value: 'nb-NO-Wavenet-A',
		name: 'Lise',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Wavenet-A.mp3?generation=1577738367012660&alt=media',
	},
	{
		value: 'nb-NO-Wavenet-B',
		name: 'Aksel',
		language: 'Norwegian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Wavenet-B.mp3?generation=1577738369136322&alt=media',
	},
	{
		value: 'nb-NO-Wavenet-C',
		name: 'Margit',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Wavenet-C.mp3?generation=1577738371778721&alt=media',
	},
	{
		value: 'nb-NO-Wavenet-D',
		name: 'Mathias',
		language: 'Norwegian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-NO-Wavenet-D.mp3?generation=1577738373991797&alt=media',
	},
	{
		value: 'nb-no-Wavenet-E',
		name: 'Karina',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nb-no-Wavenet-E.mp3?generation=1577738364449208&alt=media',
	},
	{
		value: 'nl-NL-Wavenet-B',
		name: 'Daan',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Wavenet-B.mp3?generation=1577738376220848&alt=media',
	},
	{
		value: 'nl-NL-Wavenet-C',
		name: 'Luuk',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Wavenet-C.mp3?generation=1577738378689491&alt=media',
	},
	{
		value: 'nl-NL-Wavenet-D',
		name: 'Zoe',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Wavenet-D.mp3?generation=1577738381136976&alt=media',
	},
	{
		value: 'nl-NL-Wavenet-E',
		name: 'Eva',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/nl-NL-Wavenet-E.mp3?generation=1577738383516076&alt=media',
	},
	{
		value: 'pl-PL-Wavenet-A',
		name: 'Alicja',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Wavenet-A.mp3?generation=1577738386356317&alt=media',
	},
	{
		value: 'pl-PL-Wavenet-B',
		name: 'Szymon',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Wavenet-B.mp3?generation=1577738394141260&alt=media',
	},
	{
		value: 'pl-PL-Wavenet-C',
		name: 'Antoni',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Wavenet-C.mp3?generation=1577738398769596&alt=media',
	},
	{
		value: 'pl-PL-Wavenet-D',
		name: 'Nikola',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Wavenet-D.mp3?generation=1577738401456134&alt=media',
	},
	{
		value: 'pl-PL-Wavenet-E',
		name: 'Oliwia',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pl-PL-Wavenet-E.mp3?generation=1577738408758701&alt=media',
	},
	{
		value: 'pt-BR-Wavenet-A',
		name: 'Ines',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-BR-Wavenet-A.mp3?generation=1577738411192281&alt=media',
	},
	{
		value: 'pt-PT-Wavenet-A',
		name: 'Ana',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Wavenet-A.mp3?generation=1577738413431936&alt=media',
	},
	{
		value: 'pt-PT-Wavenet-B',
		name: 'Jordao',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Wavenet-B.mp3?generation=1577738416138251&alt=media',
	},
	{
		value: 'pt-PT-Wavenet-C',
		name: 'Marco',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Wavenet-C.mp3?generation=1577738418793257&alt=media',
	},
	{
		value: 'pt-PT-Wavenet-D',
		name: 'Mariana',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/pt-PT-Wavenet-D.mp3?generation=1577738420859360&alt=media',
	},
	{
		value: 'ru-RU-Wavenet-A',
		name: 'Annika',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Wavenet-A.mp3?generation=1577738424041004&alt=media',
	},
	{
		value: 'ru-RU-Wavenet-B',
		name: 'Alyosha',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Wavenet-B.mp3?generation=1577738431705078&alt=media',
	},
	{
		value: 'ru-RU-Wavenet-C',
		name: 'Khristina',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Wavenet-C.mp3?generation=1577738433887601&alt=media',
	},
	{
		value: 'ru-RU-Wavenet-D',
		name: 'Artyom',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ru-RU-Wavenet-D.mp3?generation=1577738437044750&alt=media',
	},
	{
		value: 'ru-RU-Wavenet-E',
		name: 'Irina',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ru-RU-Wavenet-E.wav',
	},
	{
		value: 'sk-SK-Wavenet-A',
		name: 'Nela',
		language: 'Slovak',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/sk-SK-Wavenet-A.mp3?generation=1577738439633502&alt=media',
	},
	{
		value: 'sv-SE-Wavenet-A',
		name: 'Agnes',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/sv-SE-Wavenet-A.mp3?generation=1577738443028242&alt=media',
	},
	{
		value: 'tr-TR-Wavenet-A',
		name: 'Aylin',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Wavenet-A.mp3?generation=1577738450225890&alt=media',
	},
	{
		value: 'tr-TR-Wavenet-B',
		name: 'Berat',
		language: 'Turkish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Wavenet-B.mp3?generation=1577738452735133&alt=media',
	},
	{
		value: 'tr-TR-Wavenet-C',
		name: 'Berna',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Wavenet-C.mp3?generation=1577738454946864&alt=media',
	},
	{
		value: 'tr-TR-Wavenet-D',
		name: 'Basak',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Wavenet-D.mp3?generation=1577738457027041&alt=media',
	},
	{
		value: 'tr-TR-Wavenet-E',
		name: 'Omer',
		language: 'Turkish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/tr-TR-Wavenet-E.mp3?generation=1577738459913023&alt=media',
	},
	{
		value: 'uk-UA-Wavenet-A',
		name: 'Natalia',
		language: 'Ukrainian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/uk-UA-Wavenet-A.mp3?generation=1577738462098259&alt=media',
	},
	{
		value: 'vi-VN-Wavenet-A',
		name: 'Hau',
		language: 'Vietnamese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Wavenet-A.mp3?generation=1577738464428077&alt=media',
	},
	{
		value: 'vi-VN-Wavenet-B',
		name: 'Dung',
		language: 'Vietnamese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Wavenet-B.mp3?generation=1577738610954777&alt=media',
	},
	{
		value: 'vi-VN-Wavenet-C',
		name: 'Hoa',
		language: 'Vietnamese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Wavenet-C.mp3?generation=1577738613163180&alt=media',
	},
	{
		value: 'vi-VN-Wavenet-D',
		name: 'Duong',
		language: 'Vietnamese',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/vi-VN-Wavenet-D.mp3?generation=1577738615731465&alt=media',
	},
	{
		value: 'ar-XA-Standard-A',
		name: 'Alya',
		language: 'Arabic',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Standard-A.mp3?generation=1577738618139375&alt=media',
	},
	{
		value: 'ar-XA-Standard-B',
		name: 'Idris',
		language: 'Arabic',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Standard-B.mp3?generation=1577738641731649&alt=media',
	},
	{
		value: 'ar-XA-Standard-C',
		name: 'Jalal',
		language: 'Arabic',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/ar-XA-Standard-C.mp3?generation=1577738644019839&alt=media',
	},
	{
		value: 'ar-XA-Standard-D',
		name: 'Salma',
		language: 'Arabic',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ar-XA-Standard-D.wav',
	},
	{
		value: 'bn-IN-Standard-A',
		name: 'Bansuri',
		language: 'Bengali (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/bn-IN-Standard-A.wav',
	},
	{
		value: 'bn-IN-Standard-B',
		name: 'Vimridh',
		language: 'Bengali (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/bn-IN-Standard-B.wav',
	},
	{
		value: 'gu-IN-Standard-A',
		name: 'Iditri',
		language: 'Gujarati (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/gu-IN-Standard-A.wav',
	},
	{
		value: 'gu-IN-Standard-B',
		name: 'Aamod',
		language: 'Gujarati (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/gu-IN-Standard-B.wav',
	},
	{
		value: 'kn-IN-Standard-A',
		name: 'Amvi',
		language: 'Kannada (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/kn-IN-Standard-A.wav',
	},
	{
		value: 'kn-IN-Standard-B',
		name: 'Vaj',
		language: 'Kannada (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/kn-IN-Standard-B.wav',
	},
	{
		value: 'ml-IN-Standard-A',
		name: 'Tha',
		language: 'Malayalam (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ml-IN-Standard-A.wav',
	},
	{
		value: 'ml-IN-Standard-B',
		name: 'Roy',
		language: 'Malayalam (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ml-IN-Standard-B.wav',
	},
	{
		value: 'cmn-TW-Standard-A',
		name: 'Hui-chun',
		language: 'Chinese (TW)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Standard-A.wav',
	},
	{
		value: 'cmn-TW-Standard-B',
		name: 'Chien-hung',
		language: 'Chinese (TW)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Standard-B.wav',
	},
	{
		value: 'cmn-TW-Standard-C',
		name: 'Hsin-hung',
		language: 'Chinese (TW)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Standard-C.wav',
	},
	{
		value: 'cmn-TW-Wavenet-A',
		name: 'Mei-ling',
		language: 'Chinese (TW)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Wavenet-A.wav',
	},
	{
		value: 'cmn-TW-Wavenet-B',
		name: 'Tsung-han',
		language: 'Chinese (TW)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Wavenet-B.wav',
	},
	{
		value: 'cmn-TW-Wavenet-C',
		name: 'Yan-ting',
		language: 'Chinese (TW)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/cmn-TW-Wavenet-C.wav',
	},
	{
		value: 'ta-IN-Standard-A',
		name: 'Laiqa',
		language: 'Tamil (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ta-IN-Standard-A.wav',
	},
	{
		value: 'ta-IN-Standard-B',
		name: 'Panmoli',
		language: 'Tamil (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ta-IN-Standard-B.wav',
	},
	{
		value: 'te-IN-Standard-A',
		name: 'Riya',
		language: 'Telugu (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/te-IN-Standard-A.wav',
	},
	{
		value: 'te-IN-Standard-B',
		name: 'Charish',
		language: 'Telugu (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/te-IN-Standard-B.wav',
	},
	{
		value: 'th-TH-Standard-A',
		name: 'Kwang',
		language: 'Thai',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/th-TH-Standard-A.wav',
	},
	{
		value: 'en-US-Standard-G',
		name: 'Margie',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-G.wav',
	},
	{
		value: 'en-US-Standard-H',
		name: 'Cerys',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-H.wav',
	},
	{
		value: 'en-US-Standard-I',
		name: 'Shane',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-I.wav',
	},
	{
		value: 'en-US-Standard-J',
		name: 'Ronald',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-J.wav',
	},
	{
		value: 'en-US-Wavenet-G',
		name: 'Margie',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Wavenet-G.wav',
	},
	{
		value: 'en-US-Wavenet-H',
		name: 'Cerys',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Wavenet-H.wav',
	},
	{
		value: 'en-US-Wavenet-I',
		name: 'Shane',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Wavenet-I.wav',
	},
	{
		value: 'en-US-Wavenet-J',
		name: 'Ronald',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Wavenet-J.wav',
	},
	{
		value: 'yue-HK-Standard-A',
		name: 'Lixue',
		language: 'Chinese (Cantonese)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/yue-HK-Standard-A.wav',
	},
	{
		value: 'yue-HK-Standard-B',
		name: 'Kuan-yin',
		language: 'Chinese (Cantonese)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/yue-HK-Standard-B.wav',
	},
	{
		value: 'yue-HK-Standard-C',
		name: 'Mingmei',
		language: 'Chinese (Cantonese)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/yue-HK-Standard-C.wav',
	},
	{
		value: 'yue-HK-Standard-D',
		name: 'Jiahao',
		language: 'Chinese (Cantonese)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/yue-HK-Standard-D.wav',
	},
	{
		value: 'bn-IN-Wavenet-A',
		name: 'Ahana',
		language: 'Bengali (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/bn-IN-Wavenet-A.wav',
	},
	{
		value: 'bn-IN-Wavenet-B',
		name: 'Anik',
		language: 'Bengali (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/bn-IN-Wavenet-B.wav',
	},
	{
		value: 'ro-RO-Standard-A',
		name: 'Mihaela',
		language: 'Romanian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ro-RO-Standard-A.wav',
	},
	{
		value: 'ro-RO-Wavenet-A',
		name: 'Andreea',
		language: 'Romanian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgmKYHc9ULCNTZEWFP.mp3',
	},
	{
		value: 'es-US-Standard-A',
		name: 'Jimena',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Standard-A.wav',
	},
	{
		value: 'es-US-Standard-B',
		name: 'Rodrigo',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Standard-B.wav',
	},
	{
		value: 'es-US-Standard-C',
		name: 'Felipe',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Standard-C.wav',
	},
	{
		value: 'es-US-Wavenet-A',
		name: 'Jimena',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Wavenet-A.wav',
	},
	{
		value: 'es-US-Wavenet-B',
		name: 'Rodrigo',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Wavenet-B.wav',
	},
	{
		value: 'es-US-Wavenet-C',
		name: 'Felipe',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-US-Wavenet-C.wav',
	},
	{
		value: 'af-ZA-Standard-A',
		name: 'Imka',
		language: 'Afrikaans',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/af-ZA-Standard-A.wav',
	},
	{
		value: 'bg-bg-Standard-A',
		name: 'Petia',
		language: 'Bulgarian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/bg-bg-Standard-A.wav',
	},
	{
		value: 'ca-es-Standard-A',
		name: 'Nuria',
		language: 'Catalan',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ca-es-Standard-A.wav',
	},
	{
		value: 'is-is-Standard-A',
		name: 'Alfdis',
		language: 'Icelandic',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/is-is-Standard-A.wav',
	},
	{
		value: 'lv-lv-Standard-A',
		name: 'Valerijs',
		language: 'Latvian',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/lv-lv-Standard-A.wav',
	},
	{
		value: 'sr-rs-Standard-A',
		name: 'Vrstan',
		language: 'Serbian',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/sr-rs-Standard-A.wav',
	},
	{
		value: 'es-ES-Standard-B',
		name: 'Horacio',
		language: 'Spanish',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Standard-B.wav',
	},
	{
		value: 'es-ES-Standard-C',
		name: 'Gaspara',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Standard-C.wav',
	},
	{
		value: 'es-ES-Standard-D',
		name: 'Paulita',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Standard-D.wav',
	},
	{
		value: 'es-ES-Wavenet-B',
		name: 'Horacio',
		language: 'Spanish',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Wavenet-B.wav',
	},
	{
		value: 'es-ES-Wavenet-C',
		name: 'Gaspara',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Wavenet-C.wav',
	},
	{
		value: 'es-ES-Wavenet-D',
		name: 'Paulita',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/es-ES-Wavenet-D.wav',
	},
	{
		value: 'ms-MY-Wavenet-A',
		name: 'Kembang',
		language: 'Malay',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Wavenet-A.wav',
	},
	{
		value: 'ms-MY-Wavenet-B',
		name: 'Samroze',
		language: 'Malay',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Wavenet-B.wav',
	},
	{
		value: 'ms-MY-Wavenet-C',
		name: 'Mahsuri',
		language: 'Malay',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Wavenet-C.wav',
	},
	{
		value: 'ms-MY-Wavenet-D',
		name: 'Tuah',
		language: 'Malay',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Wavenet-D.wav',
	},
	{
		value: 'nl-BE-Standard-A',
		name: 'Sacnite',
		language: 'Dutch (Belgium)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/nl-BE-Standard-A.wav',
	},
	{
		value: 'nl-BE-Standard-B',
		name: 'Casper',
		language: 'Dutch (Belgium)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/nl-BE-Standard-B.wav',
	},
	{
		value: 'nl-BE-Wavenet-A',
		name: 'Sacnite',
		language: 'Dutch (Belgium)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/nl-BE-Wavenet-A.wav',
	},
	{
		value: 'nl-BE-Wavenet-B',
		name: 'Casper',
		language: 'Dutch (Belgium)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/nl-BE-Wavenet-B.wav',
	},
	{
		value: 'ar-XA-Wavenet-D',
		name: 'Salma',
		language: 'Arabic',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ar-XA-Wavenet-D.wav',
	},
	{
		value: 'en-US-Standard-A',
		name: 'James',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-A.wav',
	},
	{
		value: 'en-US-Standard-F',
		name: 'Linda',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/en-US-Standard-F.wav',
	},
	{
		value: 'de-DE-Standard-C',
		name: 'Mila',
		language: 'German',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Standard-C.wav',
	},
	{
		value: 'de-DE-Standard-D',
		name: 'Kurt',
		language: 'German',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/de-DE-Standard-D.wav',
	},
	{
		value: 'gu-IN-Wavenet-A',
		name: 'Iditri',
		language: 'Gujarati (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/gu-IN-Wavenet-A.wav',
	},
	{
		value: 'gu-IN-Wavenet-B',
		name: 'Aamod',
		language: 'Gujarati (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/gu-IN-Wavenet-B.wav',
	},
	{
		value: 'kn-IN-Wavenet-A',
		name: 'Amvi',
		language: 'Kannada (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/kn-IN-Wavenet-A.wav',
	},
	{
		value: 'kn-IN-Wavenet-B',
		name: 'Vaj',
		language: 'Kannada (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/kn-IN-Wavenet-B.wav',
	},
	{
		value: 'ms-MY-Standard-A',
		name: 'Kembang',
		language: 'Malay',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Standard-A.wav',
	},
	{
		value: 'ms-MY-Standard-B',
		name: 'Samroze',
		language: 'Malay',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Standard-B.wav',
	},
	{
		value: 'ms-MY-Standard-C',
		name: 'Mahsuri',
		language: 'Malay',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Standard-C.wav',
	},
	{
		value: 'ms-MY-Standard-D',
		name: 'Tuah',
		language: 'Malay',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ms-MY-Standard-D.wav',
	},
	{
		value: 'ml-IN-Wavenet-A',
		name: 'Tha',
		language: 'Malayalam (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ml-IN-Wavenet-A.wav',
	},
	{
		value: 'ml-IN-Wavenet-B',
		name: 'Roy',
		language: 'Malayalam (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ml-IN-Wavenet-B.wav',
	},
	{
		value: 'pa-IN-Standard-A',
		name: 'Gurneet',
		language: 'Punjabi (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Standard-A.wav',
	},
	{
		value: 'pa-IN-Standard-B',
		name: 'Ranbir',
		language: 'Punjabi (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Standard-B.wav',
	},
	{
		value: 'pa-IN-Standard-C',
		name: 'Harshida',
		language: 'Punjabi (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Standard-C.wav',
	},
	{
		value: 'pa-IN-Standard-D',
		name: 'Birpal',
		language: 'Punjabi (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Standard-D.wav',
	},
	{
		value: 'pa-IN-Wavenet-A',
		name: 'Gurneet',
		language: 'Punjabi (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Wavenet-A.wav',
	},
	{
		value: 'pa-IN-Wavenet-B',
		name: 'Ranbir',
		language: 'Punjabi (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Wavenet-B.wav',
	},
	{
		value: 'pa-IN-Wavenet-C',
		name: 'Harshida',
		language: 'Punjabi (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Wavenet-C.wav',
	},
	{
		value: 'pa-IN-Wavenet-D',
		name: 'Birpal',
		language: 'Punjabi (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/pa-IN-Wavenet-D.wav',
	},
	{
		value: 'ta-IN-Wavenet-A',
		name: 'Laiqa',
		language: 'Tamil (India)',
		gender: 'Female',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ta-IN-Wavenet-A.wav',
	},
	{
		value: 'ta-IN-Wavenet-B',
		name: 'Panmoli',
		language: 'Tamil (India)',
		gender: 'Male',
		sample: 'https://cloud.google.com/text-to-speech/docs/audio/ta-IN-Wavenet-B.wav',
	},
	{
		value: 'en-AU-Neural2-A',
		name: 'Johanna',
		language: 'English (Australia)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/en-AU-Neural2-A.wav',
	},
	{
		value: 'en-GB-Neural2-A',
		name: 'Isabella',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/en-GB-Neural2-A.wav',
	},
	{
		value: 'en-US-Neural2-A',
		name: 'Linda',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/en-US-Neural2-A.wav',
	},
	{
		value: 'pt-BR-Standard-B',
		name: 'Santiago',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/pt-BR-Standard-B.wav',
	},
	{
		value: 'pt-BR-Wavenet-B',
		name: 'Francisco',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/pt-BR-Wavenet-B.wav',
	},
	{
		value: 'es-US-Neural2-A',
		name: 'Camila',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/es-US-Neural2-A.wav',
	},
	{
		value: 'sv-SE-Standard-B',
		name: 'Alice',
		language: 'Swedish (Sweden)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Standard-B.wav',
	},
	{
		value: 'sv-SE-Standard-C',
		name: 'Olivia',
		language: 'Swedish (Sweden)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Standard-C.wav',
	},
	{
		value: 'sv-SE-Standard-D',
		name: 'Lucas',
		language: 'Swedish (Sweden)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Standard-D.wav',
	},
	{
		value: 'sv-SE-Standard-E',
		name: 'Liam',
		language: 'Swedish (Sweden)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Standard-E.wav',
	},
	{
		value: 'sv-SE-Wavenet-B',
		name: 'Maja',
		language: 'Swedish (Sweden)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Wavenet-B.wav',
	},
	{
		value: 'sv-SE-Wavenet-C',
		name: 'Oscar',
		language: 'Swedish (Sweden)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Wavenet-C.wav',
	},
	{
		value: 'sv-SE-Wavenet-D',
		name: 'Lilly',
		language: 'Swedish (Sweden)',
		gender: 'Female',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Wavenet-D.wav',
	},
	{
		value: 'sv-SE-Wavenet-E',
		name: 'Matteo',
		language: 'Swedish (Sweden)',
		gender: 'Male',
		sample: 'https://cloud.google.com/static/text-to-speech/docs/audio/sv-SE-Wavenet-E.wav',
	},
	{
		value: 'pt-BR_IsabelaV3Voice',
		name: 'Carolina',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXkGmqlZKffNV1DVRD.mp3?generation=1572541156847471&alt=media',
	},
	{
		value: 'en-GB_KateV3Voice',
		name: 'Lily',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXkeeH9kX9l39gQWgy.mp3?generation=1572541260499895&alt=media',
	},
	{
		value: 'en-US_AllisonV3Voice',
		name: 'Grace',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXjJKmmuZnvoZ803EU.mp3?generation=1572540907760362&alt=media',
	},
	{
		value: 'en-US_LisaV3Voice',
		name: 'Camila',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXjlnpiI_xfsLoFOzd.mp3?generation=1572541010905215&alt=media',
	},
	{
		value: 'en-US_MichaelV3Voice',
		name: 'Mark',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXk-Lz3bdr08IFxp7Z.mp3?generation=1572541061467173&alt=media',
	},
	{
		value: 'de-DE_BirgitV3Voice',
		name: 'Lina',
		language: 'German',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXl3v_RRYnFA9uTjY4.mp3?generation=1572541368431776&alt=media',
	},
	{
		value: 'de-DE_DieterV3Voice',
		name: 'Elias',
		language: 'German',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXlTCP500HRWiivQEL.mp3?generation=1572541448831879&alt=media',
	},
	{
		value: 'it-IT_FrancescaV3Voice',
		name: 'Angelina',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXlmin5vaVHXjI1jMU.mp3?generation=1572541532109287&alt=media',
	},
	{
		value: 'ja-JP_EmiV3Voice',
		name: 'Airi',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXm6ux9_DjrM7HPzM7.mp3?generation=1572541622846285&alt=media',
	},
	{
		value: 'es-ES_EnriqueV3Voice',
		name: 'Leonardo',
		language: 'Spanish',
		gender: 'Male',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXmUvBHm8d-2U5SkGy.mp3?generation=1572541715976296&alt=media',
	},
	{
		value: 'es-ES_LauraV3Voice',
		name: 'Lucia',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/full_-LsXmlhpvxUEy9hTmu6v.mp3?generation=1572541789144215&alt=media',
	},
	{
		value: 'ar-MS_OmarVoice',
		name: 'Omar',
		language: 'Arabic',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/ar-MS_OmarVoice.wav',
	},
	{
		value: 'zh-CN_LiNaVoice',
		name: 'Lina',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/zh-CN_LiNaVoice.wav',
	},
	{
		value: 'zh-CN_WangWeiVoice',
		name: 'Wang',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/zh-CN_WangWeiVoice.wav',
	},
	{
		value: 'zh-CN_ZhangJingVoice',
		name: 'Zhang',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/zh-CN_ZhangJingVoice.wav',
	},
	{
		value: 'nl-NL_EmmaVoice',
		name: 'Emma',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/nl-NL_EmmaVoice.wav',
	},
	{
		value: 'nl-NL_LiamVoice',
		name: 'Liam',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/nl-NL_LiamVoice.wav',
	},
	{
		value: 'fr-FR_ReneeV3Voice',
		name: 'Alice',
		language: 'French',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/fr-FR_ReneeV3Voice.wav',
	},
	{
		value: 'es-LA_SofiaV3Voice',
		name: 'Luciana',
		language: 'Spanish (MX)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/SofiaV3.wav?generation=1577745458751639&alt=media',
	},
	{
		value: 'es-US_SofiaV3Voice',
		name: 'Valeria',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://www.googleapis.com/download/storage/v1/b/play-68705.appspot.com/o/SofiaV3.wav?generation=1577745458751639&alt=media',
	},
	{
		value: 'en-US_EmilyV3Voice',
		name: 'Emily',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-US_EmilyV3Voice.wav',
	},
	{
		value: 'en-US_HenryV3Voice',
		name: 'Henry',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-US_HenryV3Voice.wav',
	},
	{
		value: 'en-US_KevinV3Voice',
		name: 'Kevinn',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-US_KevinV3Voice.wav',
	},
	{
		value: 'en-US_OliviaV3Voice',
		name: 'Olivia',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-US_OliviaV3Voice.wav',
	},
	{
		value: 'de-DE_ErikaV3Voice',
		name: 'Erika',
		language: 'German',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/de-DE_ErikaV3Voice.wav',
	},
	{
		value: 'en-GB_CharlotteV3Voice',
		name: 'Charlotte',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-GB_CharlotteV3Voice.wav',
	},
	{
		value: 'en-GB_JamesV3Voice',
		name: 'James',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-GB_JamesV3Voice.wav',
	},
	{
		value: 'fr-FR_NicolasV3Voice',
		name: 'Nicolas',
		language: 'French',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/fr-FR_NicolasV3Voice.wav',
	},
	{
		value: 'en-AU_CraigVoice',
		name: 'Craig',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-AU_CraigVoice.wav',
	},
	{
		value: 'en-AU_MadisonVoice',
		name: 'Madison',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-AU_MadisonVoice.wav',
	},
	{
		value: 'ko-KR_HyunjunVoice',
		name: 'Hyunjun',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/ko-KR_HyunjunVoice.wav',
	},
	{
		value: 'ko-KR_SiWooVoice',
		name: 'SiWoo',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/ko-KR_SiWooVoice.wav',
	},
	{
		value: 'fr-CA_LouiseV3Voice',
		name: 'Louise',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/fr-CA_LouiseV3Voice.wav',
	},
	{
		value: 'nl-BE_AdeleVoice',
		name: 'Adele',
		language: 'Dutch (Belgium)',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/nl-BE_AdeleVoice.wav',
	},
	{
		value: 'en-AU_SteveVoice',
		name: 'Steve',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/en-AU_SteveVoice.wav',
	},
	{
		value: 'sv-SE_IngridVoice',
		name: 'Ingrid',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/sv-SE_IngridVoice.wav',
	},
	{
		value: 'cs-CZ_AlenaVoice',
		name: 'Alena',
		language: 'Czech',
		gender: 'Female',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/cs-CZ_AlenaVoice.wav',
	},
	{
		value: 'nl-BE_BramVoice',
		name: 'Bram',
		language: 'Dutch (Belgium)',
		gender: 'Male',
		sample: 'https://watson-developer-cloud.github.io/doc-tutorial-downloads/text-to-speech/samples-latest/nl-BE_BramVoice.wav',
	},
	{
		value: 'ar-EG-SalmaNeural',
		name: 'Salma',
		language: 'Arabic (Egypt)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYCcEzIJUhu--lWf6M.mp3',
	},
	{
		value: 'ar-SA-ZariyahNeural',
		name: 'Zariyah',
		language: 'Arabic (Saudi Arabia)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYDD1-AvOGZnndRCmO.mp3',
	},
	{
		value: 'ca-ES-AlbaNeural',
		name: 'Alba',
		language: 'Catalan',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYDdrnZwj3LSvnXvkB.mp3',
	},
	{
		value: 'da-DK-ChristelNeural',
		name: 'Christel',
		language: 'Danish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYE8QXEVK2_9hFN6lY.mp3',
	},
	{
		value: 'de-DE-KatjaNeural',
		name: 'Katja',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYEURK2L5xK-n9doBP.mp3',
	},
	{
		value: 'en-AU-NatashaNeural',
		name: 'Natasha',
		language: 'English (AU)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1hr4_C_x5OT6_SYY.mp3',
	},
	{
		value: 'en-CA-ClaraNeural',
		name: 'Clara',
		language: 'English (Canada)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1f3Jjzjp1ZT51_3V.mp3',
	},
	{
		value: 'en-GB-LibbyNeural',
		name: 'Libby',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1aYrAovtiQoKX-W5.mp3',
	},
	{
		value: 'en-IN-NeerjaNeural',
		name: 'Neerja',
		language: 'English (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1dvofHBiZpFIyn6l.mp3',
	},
	{
		value: 'en-US-AriaNeural',
		name: 'Aria',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1Yk1-BA_-05yqhxY.mp3',
	},
	{
		value: 'en-US-GuyNeural',
		name: 'Guy',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MNf1Vxt10koB-ObvZjU.mp3',
	},
	{
		value: 'es-ES-ElviraNeural',
		name: 'Elvira',
		language: 'Spanish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYF5hoHdE0N0ww1jgt.mp3',
	},
	{
		value: 'es-MX-DaliaNeural',
		name: 'Dalia',
		language: 'Spanish (MX)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYFL7L3bHUkoNcq0Uj.mp3',
	},
	{
		value: 'fi-FI-NooraNeural',
		name: 'Noora',
		language: 'Finnish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYZ9o8R-VE9QRKHtJG.mp3',
	},
	{
		value: 'fr-CA-SylvieNeural',
		name: 'Sylvie',
		language: 'French (Canada)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYZabyROJL6GfeVaji.mp3',
	},
	{
		value: 'fr-FR-DeniseNeural',
		name: 'Denise',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYZYl7vKW7PFrfWg8x.mp3',
	},
	{
		value: 'hi-IN-SwaraNeural',
		name: 'Swara',
		language: 'Hindi',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWY_F4qWrXf_74KZecj.mp3',
	},
	{
		value: 'it-IT-ElsaNeural',
		name: 'Elsa',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWY_OPnlKOyD0WktVSG.mp3',
	},
	{
		value: 'ja-JP-NanamiNeural',
		name: 'Nanami',
		language: 'Japanese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWY_rI7FQKUMBeJ8CmT.mp3',
	},
	{
		value: 'ko-KR-SunHiNeural',
		name: 'SunHi',
		language: 'Korean',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYa62KdZj9z8ZJRrr9.mp3',
	},
	{
		value: 'nb-NO-IselinNeural',
		name: 'Iselin',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYaKfXYexCFiViFn3X.mp3',
	},
	{
		value: 'nl-NL-ColetteNeural',
		name: 'Colette',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYc8akOw_Upfd4kfcH.mp3',
	},
	{
		value: 'pl-PL-ZofiaNeural',
		name: 'Zofia',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYcTk0JTmt7RGitIFj.mp3',
	},
	{
		value: 'pt-BR-FranciscaNeural',
		name: 'Francisca',
		language: 'Portuguese (BR)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYcj5EPLZDDFSVesND.mp3',
	},
	{
		value: 'tr-TR-EmelNeural',
		name: 'Emel',
		language: 'Turkish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYcuZQREfYIQNW5eVF.mp3',
	},
	{
		value: 'pt-PT-FernandaNeural',
		name: 'Fernanda',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYd1vgjB8Jdq5YmRvI.mp3',
	},
	{
		value: 'ru-RU-DariyaNeural',
		name: 'Dariya',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYdCZh5i100oyQ2i0x.mp3',
	},
	{
		value: 'sv-SE-HilleviNeural',
		name: 'Hillevi',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYoRE3oBjk2-9mzNp9.mp3',
	},
	{
		value: 'th-TH-AcharaNeural',
		name: 'Achara',
		language: 'Thai',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYoZY5fmEcfK7Zr8YR.mp3',
	},
	{
		value: 'zh-CN-XiaoxiaoNeural',
		name: 'Xiaoxiao',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYop0bpUqReHyw353u.mp3',
	},
	{
		value: 'zh-CN-XiaoyouNeural',
		name: 'Xiaoyou',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYow2lHJ7f1cuvr-ME.mp3',
	},
	{
		value: 'zh-CN-YunyangNeural',
		name: 'Yunyang',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWYp1dog__rd2CjOrM4.mp3',
	},
	{
		value: 'zh-CN-YunyeNeural',
		name: 'Yunye',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWYp64XGEGYhlH86PzJ.mp3',
	},
	{
		value: 'zh-HK-HiuGaaiNeural',
		name: 'HiuGaai',
		language: 'Chinese (Cantonese)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYpGdGOMrMEqbeyT7Q.mp3',
	},
	{
		value: 'zh-TW-HsiaoYuNeural',
		name: 'HsiaoYu',
		language: 'Chinese (TW)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYpNToR8pG5hfZICwl.mp3',
	},
	{
		value: 'bg-BG-KalinaNeural',
		name: 'Kalina',
		language: 'Bulgarian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWYpXNxZIak6ce9G7hh.mp3',
	},
	{
		value: 'hr-HR-GabrijelaNeural',
		name: 'Gabrijela',
		language: 'Croatian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZ9bvMICCYKnb9J3aC.mp3',
	},
	{
		value: 'cs-CZ-VlastaNeural',
		name: 'Vlasta',
		language: 'Czech',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZAGo0Vhk1yArj45Y5.mp3',
	},
	{
		value: 'en-AU-WilliamNeural',
		name: 'William',
		language: 'English (AU)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MNf1WWtYyzg0Wp5hClm.mp3',
	},
	{
		value: 'en-IE-EmilyNeural',
		name: 'Emily',
		language: 'English (Ireland)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1X45RyqddzI33h78.mp3',
	},
	{
		value: 'en-GB-RyanNeural',
		name: 'Ryan',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MNf1Xc64UCx6PZIRg7x.mp3',
	},
	{
		value: 'en-US-JennyNeural',
		name: 'Jenny',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MNf1VPqkdybbzkibu5J.mp3',
	},
	{
		value: 'fr-CA-JeanNeural',
		name: 'Jean',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWZAQtsiL45W96kayhm.mp3',
	},
	{
		value: 'fr-FR-HenriNeural',
		name: 'Henri',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-HenriNeural.mp3',
	},
	{
		value: 'fr-CH-ArianeNeural',
		name: 'Ariane',
		language: 'French (Switzerland)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZAYEBWFdrjmUlqrKq.mp3',
	},
	{
		value: 'de-AT-IngridNeural',
		name: 'Ingrid',
		language: 'German (Austria)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZAfrBfbz4stf0_oNq.mp3',
	},
	{
		value: 'de-DE-ConradNeural',
		name: 'Conrad',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWZArdg2DdsN_pLGkNz.mp3',
	},
	{
		value: 'de-CH-LeniNeural',
		name: 'Leni',
		language: 'German (Switzerland)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZAvpuCBOJcXcCelN8.mp3',
	},
	{
		value: 'el-GR-AthinaNeural',
		name: 'Athina',
		language: 'Greek',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZB24uSTWQxFJ-HGrh.mp3',
	},
	{
		value: 'he-IL-HilaNeural',
		name: 'Hila',
		language: 'Hebrew',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZBIRplnjbP1MbkH7R.mp3',
	},
	{
		value: 'hu-HU-NoemiNeural',
		name: 'Noemi',
		language: 'Hungarian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZJJHX-z4kSKuoaRBY.mp3',
	},
	{
		value: 'id-ID-ArdiNeural',
		name: 'Ardi',
		language: 'Indonesian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWZJXcKh2eLLmGzcGPY.mp3',
	},
	{
		value: 'it-IT-IsabellaNeural',
		name: 'Isabella',
		language: 'Italian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZJiBPuoNQu2uQn1nZ.mp3',
	},
	{
		value: 'it-IT-DiegoNeural',
		name: 'Dieggo',
		language: 'Italian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWZJpS-l2vwiUBMY4Nw.mp3',
	},
	{
		value: 'ja-JP-KeitaNeural',
		name: 'Keita',
		language: 'Japanese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWZJzAjBptFTRcxxlkn.mp3',
	},
	{
		value: 'ko-KR-InJoonNeural',
		name: 'InJoon',
		language: 'Korean',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWbmtYewiUaYwLcwnuy.mp3',
	},
	{
		value: 'ms-MY-YasminNeural',
		name: 'Yasmin',
		language: 'Malay',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbn4GFQfxgW4OHDWK_.mp3',
	},
	{
		value: 'pt-BR-AntonioNeural',
		name: 'Antonio',
		language: 'Portuguese (BR)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWbnKJy-MVB1Vcn6Ijh.mp3',
	},
	{
		value: 'ro-RO-AlinaNeural',
		name: 'Alina',
		language: 'Romanian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbnaQJ6yboljjku1CD.mp3',
	},
	{
		value: 'sk-SK-ViktoriaNeural',
		name: 'Viktoria',
		language: 'Slovak',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbnlp8xx185kvCutpx.mp3',
	},
	{
		value: 'sl-SI-PetraNeural',
		name: 'Petra',
		language: 'Slovenian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbnxhB1RxtowKcW7qF.mp3',
	},
	{
		value: 'es-MX-JorgeNeural',
		name: 'Jorge',
		language: 'Spanish (MX)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWboCL1FcJteODUsAF6.mp3',
	},
	{
		value: 'es-ES-AlvaroNeural',
		name: 'Alvaro',
		language: 'Spanish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWboKlpbYqOrNPai5P6.mp3',
	},
	{
		value: 'ta-IN-PallaviNeural',
		name: 'Pallavi',
		language: 'Tamil (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWboX-nS0yLVME-I_fs.mp3',
	},
	{
		value: 'te-IN-ShrutiNeural',
		name: 'Shruti',
		language: 'Telugu (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbohgKb_E67F-RTg7B.mp3',
	},
	{
		value: 'th-TH-PremwadeeNeural',
		name: 'Premwadee',
		language: 'Thai',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWbosqXiK2Op3sN-PJq.mp3',
	},
	{
		value: 'mt-MT-GraceNeural',
		name: 'Grace',
		language: 'Maltese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcPZPUnsTYOQSF184s.mp3',
	},
	{
		value: 'lt-LT-OnaNeural',
		name: 'Ona',
		language: 'Lithuanian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWZJiBPuoNQu2uQn1nZ.mp3',
	},
	{
		value: 'et-EE-AnuNeural',
		name: 'Anu',
		language: 'Estonian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcQ53DbfyM8SFfU72h.mp3',
	},
	{
		value: 'ga-IE-OrlaNeural',
		name: 'Orla',
		language: 'Irish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcQVREhOJ6-I3dHdQt.mp3',
	},
	{
		value: 'lv-LV-EveritaNeural',
		name: 'Everita',
		language: 'Latvian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcRdUFRcuijf3GBMwD.mp3',
	},
	{
		value: 'zh-CN-XiaohanNeural',
		name: 'Xiaohan',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcSCX0nRkbi3WLFVIJ.mp3',
	},
	{
		value: 'zh-CN-XiaomoNeural',
		name: 'Xiaomo',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcSKsPAoODAmictBAE.mp3',
	},
	{
		value: 'zh-CN-XiaoruiNeural',
		name: 'Xiaorui',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/zh-CN-XiaoruiNeural.mp3',
	},
	{
		value: 'zh-CN-XiaoxuanNeural',
		name: 'Xiaoxuan',
		language: 'Chinese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWcSWe6LeLDe2Mg489_.mp3',
	},
	{
		value: 'zh-CN-YunxiNeural',
		name: 'Yunxi',
		language: 'Chinese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcT2T264z196lhpZ1b.mp3',
	},
	{
		value: 'ar-EG-ShakirNeural',
		name: 'Shakir',
		language: 'Arabic (Egypt)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcUuEdX1zA1pqeaC2l.mp3',
	},
	{
		value: 'ar-SA-HamedNeural',
		name: 'Hamed',
		language: 'Arabic (Saudi Arabia)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcUM33iSTx1vhzFq0Q.mp3',
	},
	{
		value: 'bg-BG-BorislavNeural',
		name: 'Borislav',
		language: 'Bulgarian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/bg-BG-BorislavNeural.mp3',
	},
	{
		value: 'ca-ES-JoanaNeural',
		name: 'Joana',
		language: 'Catalan',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgh4s7jzbSI8OGmOiu.mp3',
	},
	{
		value: 'cs-CZ-AntoninNeural',
		name: 'Antonin',
		language: 'Czech',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWghS480KW3Keta9Vbm.mp3',
	},
	{
		value: 'da-DK-JeppeNeural',
		name: 'Jeppe',
		language: 'Danish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWghdnaHKvm66wPjgON.mp3',
	},
	{
		value: 'de-AT-JonasNeural',
		name: 'Jonas',
		language: 'German (Austria)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWghu6aZ4iKBEovNhdU.mp3',
	},
	{
		value: 'de-CH-JanNeural',
		name: 'Jan',
		language: 'German (Switzerland)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgi0IKYUj0ode3Y_Bk.mp3',
	},
	{
		value: 'el-GR-NestorasNeural',
		name: 'Nestoras',
		language: 'Greek',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgiBGxNuTk9M0wBJpU.mp3',
	},
	{
		value: 'en-CA-LiamNeural',
		name: 'Liam',
		language: 'English (Canada)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgiL5dtRS0J5IskfSy.mp3',
	},
	{
		value: 'en-IE-ConnorNeural',
		name: 'Connor',
		language: 'English (Ireland)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgiWErV-f3NBTJH_Cd.mp3',
	},
	{
		value: 'hi-IN-MadhurNeural',
		name: 'Madhur',
		language: 'Hindi',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgidid34N5HNoPKSkT.mp3',
	},
	{
		value: 'te-IN-MohanNeural',
		name: 'Mohan',
		language: 'Telugu (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgim8MgKPVT79YDDrd.mp3',
	},
	{
		value: 'en-IN-PrabhatNeural',
		name: 'Prabhat',
		language: 'English (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgit8wWXO-UyoJKngy.mp3',
	},
	{
		value: 'ta-IN-ValluvarNeural',
		name: 'Valluvar',
		language: 'Tamil (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgj5Q4nhfoYKcL-Swh.mp3',
	},
	{
		value: 'ca-ES-EnricNeural',
		name: 'Enric',
		language: 'Catalan',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgh24T1pzx6Zt82cO0.mp3',
	},
	{
		value: 'et-EE-KertNeural',
		name: 'Kert',
		language: 'Estonian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgjNInqQPFTNEQBxT7.mp3',
	},
	{
		value: 'fi-FI-HarriNeural',
		name: 'Harri',
		language: 'Finnish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgjqWVzyQjleqDHN6t.mp3',
	},
	{
		value: 'fi-FI-SelmaNeural',
		name: 'Selma',
		language: 'Finnish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgjssOQF32XjV3M0v3.mp3',
	},
	{
		value: 'fr-CH-FabriceNeural',
		name: 'Fabrice',
		language: 'French (Switzerland)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgk0QUPuRnaIUUFR1T.mp3',
	},
	{
		value: 'ga-IE-ColmNeural',
		name: 'Colm',
		language: 'Irish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcQ_QlxpwNc2iaSn0h.mp3',
	},
	{
		value: 'he-IL-AvriNeural',
		name: 'Avri',
		language: 'Hebrew',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgkJewjgdwZqCJTxgV.mp3',
	},
	{
		value: 'hr-HR-SreckoNeural',
		name: 'Srecko',
		language: 'Croatian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgkRp4x_nkXpHqlHLi.mp3',
	},
	{
		value: 'hu-HU-TamasNeural',
		name: 'Tamas',
		language: 'Hungarian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/hu-HU-TamasNeural.mp3',
	},
	{
		value: 'id-ID-GadisNeural',
		name: 'Gadis',
		language: 'Indonesian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgkdvj9R9wOSHzPtc-.mp3',
	},
	{
		value: 'lt-LT-LeonasNeural',
		name: 'Leonas',
		language: 'Lithuanian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcPugKHFTVxuKnWa4m.mp3',
	},
	{
		value: 'lv-LV-NilsNeural',
		name: 'Nils',
		language: 'Latvian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcRhdQWJIcj9iTcNYG.mp3',
	},
	{
		value: 'ms-MY-OsmanNeural',
		name: 'Osman',
		language: 'Malay',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgkoDSZRdyYYxiw6Rx.mp3',
	},
	{
		value: 'mt-MT-JosephNeural',
		name: 'Joseph',
		language: 'Maltese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWcPfxCkI7qLIxpzImx.mp3',
	},
	{
		value: 'nb-NO-FinnNeural',
		name: 'Finn',
		language: 'Norwegian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgl-kksP7dJ1rVE0Eq.mp3',
	},
	{
		value: 'nb-NO-PernilleNeural',
		name: 'Pernille',
		language: 'Norwegian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgl232tq25aWA9IvCl.mp3',
	},
	{
		value: 'nl-NL-FennaNeural',
		name: 'Fenna',
		language: 'Dutch',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWc7rP1gSLa6wjgRqz4.mp3',
	},
	{
		value: 'nl-NL-MaartenNeural',
		name: 'Maarten',
		language: 'Dutch',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWglSb-9Pji4lb-avBo.mp3',
	},
	{
		value: 'pl-PL-AgnieszkaNeural',
		name: 'Agnieszka',
		language: 'Polish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWglikDWElFnuFU2dkA.mp3',
	},
	{
		value: 'pl-PL-MarekNeural',
		name: 'Marek',
		language: 'Polish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWglbM_ul3IkCrk5BaB.mp3',
	},
	{
		value: 'pt-PT-DuarteNeural',
		name: 'Duarte',
		language: 'Portuguese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgltLB4g3z8OBXm6xi.mp3',
	},
	{
		value: 'pt-PT-RaquelNeural',
		name: 'Raquel',
		language: 'Portuguese',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWglvx2PLLlQQbzk5Mc.mp3',
	},
	{
		value: 'ro-RO-EmilNeural',
		name: 'Emil',
		language: 'Romanian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgmH-gpLQWIrUI07mr.mp3',
	},
	{
		value: 'ru-RU-DmitryNeural',
		name: 'Dmitry',
		language: 'Russian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgmWGR5BqkUmXmX07U.mp3',
	},
	{
		value: 'ru-RU-SvetlanaNeural',
		name: 'Svetlana',
		language: 'Russian',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgmYhHQZGtJlNXeId4.mp3',
	},
	{
		value: 'sk-SK-LukasNeural',
		name: 'Lukas',
		language: 'Slovak',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgmhH4jYTgk0pPK8n8.mp3',
	},
	{
		value: 'sl-SI-RokNeural',
		name: 'Rok',
		language: 'Slovenian',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgmmBw7BDMGMxXW_GT.mp3',
	},
	{
		value: 'sv-SE-MattiasNeural',
		name: 'Mattias',
		language: 'Swedish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgnBdqaGKVbw6PLT_O.mp3',
	},
	{
		value: 'sv-SE-SofieNeural',
		name: 'Sofie',
		language: 'Swedish',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgnEEhx8VuV4XFnvqL.mp3',
	},
	{
		value: 'th-TH-NiwatNeural',
		name: 'Niwat',
		language: 'Thai',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgnKeDXppDOwNWBW4e.mp3',
	},
	{
		value: 'tr-TR-AhmetNeural',
		name: 'Ahmet',
		language: 'Turkish',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgnQua4VREjrjVjWHC.mp3',
	},
	{
		value: 'vi-VN-NamMinhNeural',
		name: 'NamMinh',
		language: 'Vietnamese',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgnXki9D4K-spRpZTm.mp3',
	},
	{
		value: 'zh-TW-HsiaoChenNeural',
		name: 'HsiaoChen',
		language: 'Chinese (TW)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgnl0urXv5Ob4-pRus.mp3',
	},
	{
		value: 'zh-TW-YunJheNeural',
		name: 'YunJhe',
		language: 'Chinese (TW)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgnqtZenYjF4U0tVlc.mp3',
	},
	{
		value: 'zh-HK-HiuMaanNeural',
		name: 'HiuMaan',
		language: 'Chinese (Cantonese)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MWgo-zR-V3aFGJCRKPT.mp3',
	},
	{
		value: 'zh-HK-WanLungNeural',
		name: 'WanLung',
		language: 'Chinese (Cantonese)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MWgo47PPQ1fmKXK0T7F.mp3',
	},
	{
		value: 'nl-BE-DenaNeural',
		name: 'Dena',
		language: 'Dutch (Belgium)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/nl-BE-DenaNeural.mp3',
	},
	{
		value: 'nl-BE-ArnaudNeural',
		name: 'Arnaud',
		language: 'Dutch (Belgium)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/nl-BE-ArnaudNeural.mp3',
	},
	{
		value: 'en-PH-RosaNeural',
		name: 'Rosa',
		language: 'English (Philippines)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-PH-RosaNeural.mp3',
	},
	{
		value: 'en-PH-JamesNeural',
		name: 'James',
		language: 'English (Philippines)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-PH-JamesNeural.mp3',
	},
	{
		value: 'fr-BE-CharlineNeural',
		name: 'Charline',
		language: 'French (Belgium)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-BE-CharlineNeural.mp3',
	},
	{
		value: 'fr-BE-GerardNeural',
		name: 'Gerard',
		language: 'French (Belgium)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-BE-GerardNeural.mp3',
	},
	{
		value: 'uk-UA-PolinaNeural',
		name: 'Polina',
		language: 'Ukrainian',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/uk-UA-PolinaNeural.mp3',
	},
	{
		value: 'uk-UA-OstapNeural',
		name: 'Ostap',
		language: 'Ukrainian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/uk-UA-OstapNeural.mp3',
	},
	{
		value: 'ur-PK-UzmaNeural',
		name: 'Uzma',
		language: 'Urdu',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ur-PK-UzmaNeural.mp3',
	},
	{
		value: 'ur-PK-AsadNeural',
		name: 'Asad',
		language: 'Urdu',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ur-PK-AsadNeural.mp3',
	},
	{
		value: 'cy-GB-NiaNeural',
		name: 'Nia',
		language: 'Welsh',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/cy-GB-NiaNeural.mp3',
	},
	{
		value: 'cy-GB-AledNeural',
		name: 'Aled',
		language: 'Welsh',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/cy-GB-AledNeural.mp3',
	},
	{
		value: 'fr-CA-AntoineNeural',
		name: 'Antoine',
		language: 'French (Canada)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7riFN23eVjRk29d1z.wav',
	},
	{
		value: 'en-HK-YanNeural',
		name: 'Yan',
		language: 'English (Hong Kong)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-HK-YanNeural.mp3',
	},
	{
		value: 'en-HK-SamNeural',
		name: 'Sam',
		language: 'English (Hong Kong)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-HK-SamNeural.mp3',
	},
	{
		value: 'en-NZ-MollyNeural',
		name: 'Molly',
		language: 'English (New Zealand)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-NZ-MollyNeural.mp3',
	},
	{
		value: 'en-NZ-MitchellNeural',
		name: 'Mitchell',
		language: 'English (New Zealand)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-NZ-MitchellNeural.mp3',
	},
	{
		value: 'en-SG-LunaNeural',
		name: 'Luna',
		language: 'English (Singapore)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-SG-LunaNeural.mp3',
	},
	{
		value: 'en-SG-WayneNeural',
		name: 'Wayne',
		language: 'English (Singapore)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-SG-WayneNeural.mp3',
	},
	{
		value: 'en-ZA-LeahNeural',
		name: 'Leah',
		language: 'English (South Africa)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7t-hP9nYNS_AvO7bN.wav',
	},
	{
		value: 'en-ZA-LukeNeural',
		name: 'Luke',
		language: 'English (South Africa)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7t8KxPE2VFTk_gwaw.wav',
	},
	{
		value: 'gu-IN-DhwaniNeural',
		name: 'Dhwani',
		language: 'Gujarati (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7tpPqVf5BqqPN4qQJ.wav',
	},
	{
		value: 'gu-IN-NiranjanNeural',
		name: 'Niranjan',
		language: 'Gujarati (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7u9pMCWSzDMe6rQhU.wav',
	},
	{
		value: 'mr-IN-AarohiNeural',
		name: 'Aarohi',
		language: 'Marathi (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7ukZq6_VSDg5ERJv4.wav',
	},
	{
		value: 'mr-IN-ManoharNeural',
		name: 'Manohar',
		language: 'Marathi (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7vaPSrwHiyR1-85Pa.wav',
	},
	{
		value: 'es-AR-ElenaNeural',
		name: 'Elena',
		language: 'Spanish (Argentina)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7vsXUMpY9TIp2e4fO.wav',
	},
	{
		value: 'es-AR-TomasNeural',
		name: 'Tomas',
		language: 'Spanish (Argentina)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7vyc6fdgI4E2qKU_l.wav',
	},
	{
		value: 'es-CO-SalomeNeural',
		name: 'Salome',
		language: 'Spanish (Colombia)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7wBfexJQU6KG39tfC.wav',
	},
	{
		value: 'es-CO-GonzaloNeural',
		name: 'Gonzalo',
		language: 'Spanish (Colombia)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7w58qNPuBY8QB7wOh.wav',
	},
	{
		value: 'es-US-PalomaNeural',
		name: 'Paloma',
		language: 'Spanish (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7wZD2IJqjHxe6nW2_.wav',
	},
	{
		value: 'es-US-AlonsoNeural',
		name: 'Alonso',
		language: 'Spanish (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7wTQTYLBfJi_WmCZA.wav',
	},
	{
		value: 'sw-KE-ZuriNeural',
		name: 'Zuri',
		language: 'Swahili (Kenya)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-Mh7wz_NUvQGSyjoYUDV.wav',
	},
	{
		value: 'sw-KE-RafikiNeural',
		name: 'Rafiki',
		language: 'Swahili (Kenya)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-Mh7wngs3QmRecrkyVrK.wav',
	},
	{
		value: 'en-US-AmberNeural',
		name: 'Amber',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaHAb7oKxrafkHwoPr.wav',
	},
	{
		value: 'en-US-AshleyNeural',
		name: 'Ashley',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaJ17yCwdyTA79U3l4.wav',
	},
	{
		value: 'en-US-CoraNeural',
		name: 'Cora',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaKc8PntSUM1tLRsYg.wav',
	},
	{
		value: 'en-US-ElizabethNeural',
		name: 'Beth',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaJSQIwiH93mWxCT8q.wav',
	},
	{
		value: 'en-US-MichelleNeural',
		name: 'Michelle',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaLWod7QmTm4nX4Yxw.wav',
	},
	{
		value: 'en-US-MonicaNeural',
		name: 'Monica',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaLh7ZOVlQb2Yixn9r.wav',
	},
	{
		value: 'en-US-AnaNeural',
		name: 'Ana',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MiaHSmdqzJqJ5O8wnAV.wav',
	},
	{
		value: 'en-US-BrandonNeural',
		name: 'Brandon',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MiaJyCLabOmLJv4jslu.wav',
	},
	{
		value: 'en-US-ChristopherNeural',
		name: 'Chris',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MiaKGhnF1wi3kbUVnED.wav',
	},
	{
		value: 'en-US-JacobNeural',
		name: 'Jacob',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MiaL5eChRJY36MQcht1.wav',
	},
	{
		value: 'en-US-EricNeural',
		name: 'Eric',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/full_-MiaKpPcvJYhP0_4wiqV.wav',
	},
	{
		value: 'en-US-SaraNeural',
		name: 'Sara',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MmMggzQYiUs1EbXmmEv.wav',
	},
	{
		value: 'en-GB-SoniaNeural',
		name: 'Sonia',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/full_-MmMhVrv27OthnM9yjUJ.wav',
	},
	{
		value: 'af-ZA-AdriNeural',
		name: 'Adri',
		language: 'Afrikaans',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/af-ZA-AdriNeural.mp3',
	},
	{
		value: 'af-ZA-WillemNeural',
		name: 'Willem',
		language: 'Afrikaans',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/af-ZA-WillemNeural.mp3',
	},
	{
		value: 'am-ET-MekdesNeural',
		name: 'Mekdes',
		language: 'Amharic',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/am-ET-MekdesNeural.mp3',
	},
	{
		value: 'am-ET-AmehaNeural',
		name: 'Ameha',
		language: 'Amharic',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/am-ET-AmehaNeural.mp3',
	},
	{
		value: 'ar-DZ-AminaNeural',
		name: 'Amina',
		language: 'Arabic (Algeria)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-DZ-AminaNeural.mp3',
	},
	{
		value: 'ar-DZ-IsmaelNeural',
		name: 'Ismael',
		language: 'Arabic (Algeria)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-DZ-IsmaelNeural.mp3',
	},
	{
		value: 'ar-BH-LailaNeural',
		name: 'Laila',
		language: 'Arabic (Bahrain)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-BH-LailaNeural.mp3',
	},
	{
		value: 'ar-BH-AliNeural',
		name: 'Ali',
		language: 'Arabic (Bahrain)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-BH-AliNeural.mp3',
	},
	{
		value: 'ar-IQ-RanaNeural',
		name: 'Rana',
		language: 'Arabic (Iraq)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-IQ-RanaNeural.mp3',
	},
	{
		value: 'ar-IQ-BasselNeural',
		name: 'Bassel',
		language: 'Arabic (Iraq)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-IQ-BasselNeural.mp3',
	},
	{
		value: 'ar-JO-SanaNeural',
		name: 'Sana',
		language: 'Arabic (Jordan)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-JO-SanaNeural.mp3',
	},
	{
		value: 'ar-JO-TaimNeural',
		name: 'Taim',
		language: 'Arabic (Jordan)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-JO-TaimNeural.mp3',
	},
	{
		value: 'ar-KW-NouraNeural',
		name: 'Noura',
		language: 'Arabic (Kuwait)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-KW-NouraNeural.mp3',
	},
	{
		value: 'ar-KW-FahedNeural',
		name: 'Fahed',
		language: 'Arabic (Kuwait)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-KW-FahedNeural.mp3',
	},
	{
		value: 'ar-LY-ImanNeural',
		name: 'Iman',
		language: 'Arabic (Libya)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-LY-ImanNeural.mp3',
	},
	{
		value: 'ar-LY-OmarNeural',
		name: 'Omar',
		language: 'Arabic (Libya)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-LY-OmarNeural.mp3',
	},
	{
		value: 'ar-MA-MounaNeural',
		name: 'Mouna',
		language: 'Arabic (Morocco)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-MA-MounaNeural.mp3',
	},
	{
		value: 'ar-MA-JamalNeural',
		name: 'Jamal',
		language: 'Arabic (Morocco)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-MA-JamalNeural.mp3',
	},
	{
		value: 'ar-QA-AmalNeural',
		name: 'Amal',
		language: 'Arabic (Qatar)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-QA-AmalNeural.mp3',
	},
	{
		value: 'ar-QA-MoazNeural',
		name: 'Moaz',
		language: 'Arabic (Qatar)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-QA-MoazNeural.mp3',
	},
	{
		value: 'ar-SY-AmanyNeural',
		name: 'Amany',
		language: 'Arabic (Syria)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-SY-AmanyNeural.mp3',
	},
	{
		value: 'ar-SY-LaithNeural',
		name: 'Laith',
		language: 'Arabic (Syria)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-SY-LaithNeural.mp3',
	},
	{
		value: 'ar-TN-ReemNeural',
		name: 'Reem',
		language: 'Arabic (Tunisia)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-TN-ReemNeural.mp3',
	},
	{
		value: 'ar-TN-HediNeural',
		name: 'Hedi',
		language: 'Arabic (Tunisia)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-TN-HediNeural.mp3',
	},
	{
		value: 'ar-AE-FatimaNeural',
		name: 'Fatima',
		language: 'Arabic (United Arab Emirates)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-AE-FatimaNeural.mp3',
	},
	{
		value: 'ar-AE-HamdanNeural',
		name: 'Hamdan',
		language: 'Arabic (United Arab Emirates)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-AE-HamdanNeural.mp3',
	},
	{
		value: 'ar-YE-MaryamNeural',
		name: 'Maryam',
		language: 'Arabic (Yemen)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-YE-MaryamNeural.mp3',
	},
	{
		value: 'ar-YE-SalehNeural',
		name: 'Saleh',
		language: 'Arabic (Yemen)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-YE-SalehNeural.mp3',
	},
	{
		value: 'bn-BD-NabanitaNeural',
		name: 'Nabanita',
		language: 'Bengali',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/bn-BD-NabanitaNeural.mp3',
	},
	{
		value: 'bn-BD-PradeepNeural',
		name: 'Pradeep',
		language: 'Bengali',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/bn-BD-PradeepNeural.mp3',
	},
	{
		value: 'my-MM-NilarNeural',
		name: 'Nilar',
		language: 'Burmese',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/my-MM-NilarNeural.mp3',
	},
	{
		value: 'my-MM-ThihaNeural',
		name: 'Thiha',
		language: 'Burmese',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/my-MM-ThihaNeural.mp3',
	},
	{
		value: 'en-KE-AsiliaNeural',
		name: 'Asilia',
		language: 'English (Kenya)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-KE-AsiliaNeural.mp3',
	},
	{
		value: 'en-KE-ChilembaNeural',
		name: 'Chilemba',
		language: 'English (Kenya)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-KE-ChilembaNeural.mp3',
	},
	{
		value: 'en-NG-EzinneNeural',
		name: 'Ezinne',
		language: 'English (Nigeria)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-NG-EzinneNeural.mp3',
	},
	{
		value: 'en-NG-AbeoNeural',
		name: 'Abeo',
		language: 'English (Nigeria)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-NG-AbeoNeural.mp3',
	},
	{
		value: 'en-TZ-ImaniNeural',
		name: 'Imani',
		language: 'English (Tanzania)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-TZ-ImaniNeural.mp3',
	},
	{
		value: 'en-TZ-ElimuNeural',
		name: 'Elimu',
		language: 'English (Tanzania)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-TZ-ElimuNeural.mp3',
	},
	{
		value: 'fil-PH-BlessicaNeural',
		name: 'Blessica',
		language: 'Filipino',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fil-PH-BlessicaNeural.mp3',
	},
	{
		value: 'fil-PH-AngeloNeural',
		name: 'Angelo',
		language: 'Filipino',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fil-PH-AngeloNeural.mp3',
	},
	{
		value: 'gl-ES-SabelaNeural',
		name: 'Sabela',
		language: 'Galician',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/gl-ES-SabelaNeural.mp3',
	},
	{
		value: 'gl-ES-RoiNeural',
		name: 'Roi',
		language: 'Galician',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/gl-ES-RoiNeural.mp3',
	},
	{
		value: 'jv-ID-SitiNeural',
		name: 'Siti',
		language: 'Javanese',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/jv-ID-SitiNeural.mp3',
	},
	{
		value: 'jv-ID-DimasNeural',
		name: 'Dimas',
		language: 'Javanese',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/jv-ID-DimasNeural.mp3',
	},
	{
		value: 'km-KH-SreymomNeural',
		name: 'Sreymom',
		language: 'Khmer',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/km-KH-SreymomNeural.mp3',
	},
	{
		value: 'km-KH-PisethNeural',
		name: 'Piseth',
		language: 'Khmer',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/km-KH-PisethNeural.mp3',
	},
	{
		value: 'fa-IR-DilaraNeural',
		name: 'Dilara',
		language: 'Persian',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fa-IR-DilaraNeural.mp3',
	},
	{
		value: 'fa-IR-FaridNeural',
		name: 'Farid',
		language: 'Persian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fa-IR-FaridNeural.mp3',
	},
	{
		value: 'so-SO-UbaxNeural',
		name: 'Ubax',
		language: 'Somali',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/so-SO-UbaxNeural.mp3',
	},
	{
		value: 'so-SO-MuuseNeural',
		name: 'Muuse',
		language: 'Somali',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/so-SO-MuuseNeural.mp3',
	},
	{
		value: 'es-BO-SofiaNeural',
		name: 'Sofia',
		language: 'Spanish (Bolivia)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-BO-SofiaNeural.mp3',
	},
	{
		value: 'es-BO-MarceloNeural',
		name: 'Marcelo',
		language: 'Spanish (Bolivia)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-BO-MarceloNeural.mp3',
	},
	{
		value: 'es-CL-CatalinaNeural',
		name: 'Catalina',
		language: 'Spanish (Chile)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-CL-CatalinaNeural.mp3',
	},
	{
		value: 'es-CL-LorenzoNeural',
		name: 'Lorenzo',
		language: 'Spanish (Chile)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-CL-LorenzoNeural.mp3',
	},
	{
		value: 'es-CR-MariaNeural',
		name: 'Maria',
		language: 'Spanish (Costa Rica)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-CR-MariaNeural.mp3',
	},
	{
		value: 'es-CR-JuanNeural',
		name: 'Juan',
		language: 'Spanish (Costa Rica)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-CR-JuanNeural.mp3',
	},
	{
		value: 'es-CU-BelkysNeural',
		name: 'Belkys',
		language: 'Spanish (Cuba)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-CU-BelkysNeural.mp3',
	},
	{
		value: 'es-CU-ManuelNeural',
		name: 'Manuel',
		language: 'Spanish (Cuba)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-CU-ManuelNeural.mp3',
	},
	{
		value: 'es-DO-RamonaNeural',
		name: 'Ramona',
		language: 'Spanish (Dominican Republic)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-DO-RamonaNeural.mp3',
	},
	{
		value: 'es-DO-EmilioNeural',
		name: 'Emilio',
		language: 'Spanish (Dominican Republic)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-DO-EmilioNeural.mp3',
	},
	{
		value: 'es-EC-AndreaNeural',
		name: 'Andrea',
		language: 'Spanish (Ecuador)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-EC-AndreaNeural.mp3',
	},
	{
		value: 'es-EC-LuisNeural',
		name: 'Luis',
		language: 'Spanish (Ecuador)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-EC-LuisNeural.mp3',
	},
	{
		value: 'es-SV-LorenaNeural',
		name: 'Lorena',
		language: 'Spanish (El Salvador)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-SV-LorenaNeural.mp3',
	},
	{
		value: 'es-SV-RodrigoNeural',
		name: 'Rodrigo',
		language: 'Spanish (El Salvador)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-SV-RodrigoNeural.mp3',
	},
	{
		value: 'es-GQ-TeresaNeural',
		name: 'Teresa',
		language: 'Spanish (Equatorial Guinea)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-GQ-TeresaNeural.mp3',
	},
	{
		value: 'es-GQ-JavierNeural',
		name: 'Javier',
		language: 'Spanish (Equatorial Guinea)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-GQ-JavierNeural.mp3',
	},
	{
		value: 'es-GT-MartaNeural',
		name: 'Marta',
		language: 'Spanish (Guatemala)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-GT-MartaNeural.mp3',
	},
	{
		value: 'es-GT-AndresNeural',
		name: 'Andres',
		language: 'Spanish (Guatemala)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-GT-AndresNeural.mp3',
	},
	{
		value: 'es-HN-KarlaNeural',
		name: 'Karla',
		language: 'Spanish (Honduras)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-HN-KarlaNeural.mp3',
	},
	{
		value: 'es-HN-CarlosNeural',
		name: 'Carlos',
		language: 'Spanish (Honduras)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-HN-CarlosNeural.mp3',
	},
	{
		value: 'es-NI-YolandaNeural',
		name: 'Yolanda',
		language: 'Spanish (Nicaragua)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-NI-YolandaNeural.mp3',
	},
	{
		value: 'es-NI-FedericoNeural',
		name: 'Federico',
		language: 'Spanish (Nicaragua)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-NI-FedericoNeural.mp3',
	},
	{
		value: 'es-PA-MargaritaNeural',
		name: 'Margarita',
		language: 'Spanish (Panama)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-PA-MargaritaNeural.mp3',
	},
	{
		value: 'es-PA-RobertoNeural',
		name: 'Roberto',
		language: 'Spanish (Panama)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-PA-RobertoNeural.mp3',
	},
	{
		value: 'es-PY-TaniaNeural',
		name: 'Tania',
		language: 'Spanish (Paraguay)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-PY-TaniaNeural.mp3',
	},
	{
		value: 'es-PY-MarioNeural',
		name: 'Mario',
		language: 'Spanish (Paraguay)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-PY-MarioNeural.mp3',
	},
	{
		value: 'es-PE-CamilaNeural',
		name: 'Camila',
		language: 'Spanish (Peru)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-PE-CamilaNeural.mp3',
	},
	{
		value: 'es-PE-AlexNeural',
		name: 'Alex',
		language: 'Spanish (Peru)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-PE-AlexNeural.mp3',
	},
	{
		value: 'es-PR-KarinaNeural',
		name: 'Karina',
		language: 'Spanish (Puerto Rico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-PR-KarinaNeural.mp3',
	},
	{
		value: 'es-PR-VictorNeural',
		name: 'Victor',
		language: 'Spanish (Puerto Rico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-PR-VictorNeural.mp3',
	},
	{
		value: 'es-UY-ValentinaNeural',
		name: 'Valentina',
		language: 'Spanish (Uruguay)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-UY-ValentinaNeural.mp3',
	},
	{
		value: 'es-UY-MateoNeural',
		name: 'Mateo',
		language: 'Spanish (Uruguay)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-UY-MateoNeural.mp3',
	},
	{
		value: 'es-VE-PaolaNeural',
		name: 'Paola',
		language: 'Spanish (Venezuela)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-VE-PaolaNeural.mp3',
	},
	{
		value: 'es-VE-SebastianNeural',
		name: 'Sebastian',
		language: 'Spanish (Venezuela)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-VE-SebastianNeural.mp3',
	},
	{
		value: 'su-ID-TutiNeural',
		name: 'Tuti',
		language: 'Sundanese',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/su-ID-TutiNeural.mp3',
	},
	{
		value: 'su-ID-JajangNeural',
		name: 'Jajang',
		language: 'Sundanese',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/su-ID-JajangNeural.mp3',
	},
	{
		value: 'sw-TZ-RehemaNeural',
		name: 'Rehema',
		language: 'Swahili (Tanzania)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/sw-TZ-RehemaNeural.mp3',
	},
	{
		value: 'sw-TZ-DaudiNeural',
		name: 'Daudi',
		language: 'Swahili (Tanzania)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/sw-TZ-DaudiNeural.mp3',
	},
	{
		value: 'ta-SG-VenbaNeural',
		name: 'Venba',
		language: 'Tamil (Singapore)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ta-SG-VenbaNeural.mp3',
	},
	{
		value: 'ta-SG-AnbuNeural',
		name: 'Anbu',
		language: 'Tamil (Singapore)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ta-SG-AnbuNeural.mp3',
	},
	{
		value: 'ta-LK-SaranyaNeural',
		name: 'Saranya',
		language: 'Tamil (Sri Lanka)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ta-LK-SaranyaNeural.mp3',
	},
	{
		value: 'ta-LK-KumarNeural',
		name: 'Kumar',
		language: 'Tamil (Sri Lanka)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ta-LK-KumarNeural.mp3',
	},
	{
		value: 'ur-IN-GulNeural',
		name: 'Gul',
		language: 'Urdu (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ur-IN-GulNeural.mp3',
	},
	{
		value: 'ur-IN-SalmanNeural',
		name: 'Salman',
		language: 'Urdu (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ur-IN-SalmanNeural.mp3',
	},
	{
		value: 'uz-UZ-MadinaNeural',
		name: 'Madina',
		language: 'Uzbek',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/uz-UZ-MadinaNeural.mp3',
	},
	{
		value: 'uz-UZ-SardorNeural',
		name: 'Sardor',
		language: 'Uzbek',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/uz-UZ-SardorNeural.mp3',
	},
	{
		value: 'zu-ZA-ThandoNeural',
		name: 'Thando',
		language: 'Zulu',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/zu-ZA-ThandoNeural.mp3',
	},
	{
		value: 'zu-ZA-ThembaNeural',
		name: 'Themba',
		language: 'Zulu',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/zu-ZA-ThembaNeural.mp3',
	},
	{
		value: 'en-GB-AbbiNeural',
		name: 'Abbi',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-GB-AbbiNeural.mp3',
	},
	{
		value: 'en-GB-BellaNeural',
		name: 'Bella',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-GB-BellaNeural.mp3',
	},
	{
		value: 'en-GB-HollieNeural',
		name: 'Hollie',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-GB-HollieNeural.mp3',
	},
	{
		value: 'en-GB-OliviaNeural',
		name: 'Olivia',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-GB-OliviaNeural.mp3',
	},
	{
		value: 'en-GB-MaisieNeural',
		name: 'Maisie',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-GB-MaisieNeural.mp3',
	},
	{
		value: 'en-GB-AlfieNeural',
		name: 'Alfie',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-AlfieNeural.mp3',
	},
	{
		value: 'en-GB-ElliotNeural',
		name: 'Elliot',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-ElliotNeural.mp3',
	},
	{
		value: 'en-GB-EthanNeural',
		name: 'Ethan',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-EthanNeural.mp3',
	},
	{
		value: 'en-GB-NoahNeural',
		name: 'Noah',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-NoahNeural.mp3',
	},
	{
		value: 'en-GB-OliverNeural',
		name: 'Oliver',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-OliverNeural.mp3',
	},
	{
		value: 'en-GB-ThomasNeural',
		name: 'Thomas',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-GB-ThomasNeural.mp3',
	},
	{
		value: 'en-US-DavisNeural',
		name: 'Davis',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-US-DavisNeural.mp3',
	},
	{
		value: 'en-US-JaneNeural',
		name: 'Jane',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-US-JaneNeural.mp3',
	},
	{
		value: 'en-US-JasonNeural',
		name: 'Jason',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-US-JasonNeural.mp3',
	},
	{
		value: 'en-US-NancyNeural',
		name: 'Nancy',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/en-US-NancyNeural.mp3',
	},
	{
		value: 'en-US-TonyNeural',
		name: 'Tony',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/en-US-TonyNeural.mp3',
	},
	{
		value: 'fr-FR-BrigitteNeural',
		name: 'Brigitte',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-BrigitteNeural.mp3',
	},
	{
		value: 'fr-FR-CelesteNeural',
		name: 'Celeste',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-CelesteNeural.mp3',
	},
	{
		value: 'fr-FR-CoralieNeural',
		name: 'Coralie',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-CoralieNeural.mp3',
	},
	{
		value: 'fr-FR-JacquelineNeural',
		name: 'Jacqueline',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-JacquelineNeural.mp3',
	},
	{
		value: 'fr-FR-JosephineNeural',
		name: 'Josephine',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-JosephineNeural.mp3',
	},
	{
		value: 'fr-FR-YvetteNeural',
		name: 'Yvette',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-YvetteNeural.mp3',
	},
	{
		value: 'fr-FR-EloiseNeural',
		name: 'Eloise',
		language: 'French',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/fr-FR-EloiseNeural.mp3',
	},
	{
		value: 'fr-FR-AlainNeural',
		name: 'Alain',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-AlainNeural.mp3',
	},
	{
		value: 'fr-FR-ClaudeNeural',
		name: 'Claude',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-ClaudeNeural.mp3',
	},
	{
		value: 'fr-FR-JeromeNeural',
		name: 'Jerome',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-JeromeNeural.mp3',
	},
	{
		value: 'fr-FR-MauriceNeural',
		name: 'Maurice',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-MauriceNeural.mp3',
	},
	{
		value: 'fr-FR-YvesNeural',
		name: 'Yves',
		language: 'French',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/fr-FR-YvesNeural.mp3',
	},
	{
		value: 'de-DE-AmalaNeural',
		name: 'Amala',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-AmalaNeural.mp3',
	},
	{
		value: 'de-DE-ElkeNeural',
		name: 'Elke',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-ElkeNeural.mp3',
	},
	{
		value: 'de-DE-KlarissaNeural',
		name: 'Klarissa',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-KlarissaNeural.mp3',
	},
	{
		value: 'de-DE-LouisaNeural',
		name: 'Louisa',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-LouisaNeural.mp3',
	},
	{
		value: 'de-DE-MajaNeural',
		name: 'Maja',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-MajaNeural.mp3',
	},
	{
		value: 'de-DE-TanjaNeural',
		name: 'Tanja',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-TanjaNeural.mp3',
	},
	{
		value: 'de-DE-GiselaNeural',
		name: 'Gisela',
		language: 'German',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/de-DE-GiselaNeural.mp3',
	},
	{
		value: 'de-DE-BerndNeural',
		name: 'Bernd',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-BerndNeural.mp3',
	},
	{
		value: 'de-DE-ChristophNeural',
		name: 'Christoph',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-ChristophNeural.mp3',
	},
	{
		value: 'de-DE-KasperNeural',
		name: 'Kasper',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-KasperNeural.mp3',
	},
	{
		value: 'de-DE-KillianNeural',
		name: 'Killian',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-KillianNeural.mp3',
	},
	{
		value: 'de-DE-KlausNeural',
		name: 'Klaus',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-KlausNeural.mp3',
	},
	{
		value: 'de-DE-RalfNeural',
		name: 'Ralf',
		language: 'German',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/de-DE-RalfNeural.mp3',
	},
	{
		value: 'bn-IN-TanishaaNeural',
		name: 'Tanishaa',
		language: 'Bengali (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/bn-IN-TanishaaNeural.mp3',
	},
	{
		value: 'bn-IN-BashkarNeural',
		name: 'Bashkar',
		language: 'Bengali (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/bn-IN-BashkarNeural.mp3',
	},
	{
		value: 'is-IS-GudrunNeural',
		name: 'Bashkar',
		language: 'Icelandic',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/is-IS-GudrunNeural.mp3',
	},
	{
		value: 'is-IS-GunnarNeural',
		name: 'Gunnar',
		language: 'Icelandic',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/is-IS-GunnarNeural.mp3',
	},
	{
		value: 'kn-IN-SapnaNeural',
		name: 'Sapna',
		language: 'Kannada (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/kn-IN-SapnaNeural.mp3',
	},
	{
		value: 'kn-IN-GaganNeural',
		name: 'Gagan',
		language: 'Kannada (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/kn-IN-GaganNeural.mp3',
	},
	{
		value: 'kk-KZ-AigulNeural',
		name: 'Aigul',
		language: 'Kazakh (Kazakhstan)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/kk-KZ-AigulNeural.mp3',
	},
	{
		value: 'kk-KZ-DauletNeural',
		name: 'Daulet',
		language: 'Kazakh (Kazakhstan)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/kk-KZ-DauletNeural.mp3',
	},
	{
		value: 'lo-LA-KeomanyNeural',
		name: 'Keomany',
		language: 'Lao (Laos)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/lo-LA-KeomanyNeural.mp3',
	},
	{
		value: 'lo-LA-ChanthavongNeural',
		name: 'Chanthavong',
		language: 'Lao (Laos)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/lo-LA-ChanthavongNeural.mp3',
	},
	{
		value: 'mk-MK-MarijaNeural',
		name: 'Marija',
		language: 'Macedonian',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/mk-MK-MarijaNeural.mp3',
	},
	{
		value: 'mk-MK-AleksandarNeural',
		name: 'Aleksandar',
		language: 'Macedonian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/mk-MK-AleksandarNeural.mp3',
	},
	{
		value: 'ml-IN-SobhanaNeural',
		name: 'Sobhana',
		language: 'Malayalam (India)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ml-IN-SobhanaNeural.mp3',
	},
	{
		value: 'ml-IN-MidhunNeural',
		name: 'Midhun',
		language: 'Malayalam (India)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ml-IN-MidhunNeural.mp3',
	},
	{
		value: 'ps-AF-LatifaNeural',
		name: 'Sobhana',
		language: 'Pashto (Afghanistan)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ps-AF-LatifaNeural.mp3',
	},
	{
		value: 'ps-AF-GulNawazNeural',
		name: 'Midhun',
		language: 'Pashto (Afghanistan)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ps-AF-GulNawazNeural.mp3',
	},
	{
		value: 'sr-RS-SophieNeural',
		name: 'Sophie',
		language: 'Serbian',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/sr-RS-SophieNeural.mp3',
	},
	{
		value: 'sr-RS-NicholasNeural',
		name: 'Nicholas',
		language: 'Serbian',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/sr-RS-NicholasNeural.mp3',
	},
	{
		value: 'si-LK-ThiliniNeural',
		name: 'Thilini',
		language: 'Sinhala (Sri Lanka)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/si-LK-ThiliniNeural.mp3',
	},
	{
		value: 'si-LK-SameeraNeural',
		name: 'Sameera',
		language: 'Sinhala (Sri Lanka)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/si-LK-SameeraNeural.mp3',
	},
	{
		value: 'sq-AL-AnilaNeural',
		name: 'Anila',
		language: 'Albanian (Albania)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/sq-AL-AnilaNeural.mp3',
	},
	{
		value: 'sq-AL-IlirNeural',
		name: 'Ilir',
		language: 'Albanian (Albania)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/sq-AL-IlirNeural.mp3',
	},
	{
		value: 'ar-LB-LaylaNeural',
		name: 'Layla',
		language: 'Arabic (Lebanon)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-LB-LaylaNeural.mp3',
	},
	{
		value: 'ar-LB-RamiNeural',
		name: 'Rami',
		language: 'Arabic (Lebanon)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-LB-RamiNeural.mp3',
	},
	{
		value: 'ar-OM-AyshaNeural',
		name: 'Aysha',
		language: 'Arabic (Oman)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ar-OM-AyshaNeural.mp3',
	},
	{
		value: 'ar-OM-AbdullahNeural',
		name: 'Abdullah',
		language: 'Arabic (Oman)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ar-OM-AbdullahNeural.mp3',
	},
	{
		value: 'az-AZ-BabekNeural',
		name: 'Babek',
		language: 'Azerbaijani (Azerbaijan)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/az-AZ-BabekNeural.mp3',
	},
	{
		value: 'az-AZ-BanuNeural',
		name: 'Banu',
		language: 'Azerbaijani (Azerbaijan)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/az-AZ-BanuNeural.mp3',
	},
	{
		value: 'bs-BA-VesnaNeural',
		name: 'Vesna',
		language: 'Bosnian (Bosnia and Herzegovina)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/bs-BA-VesnaNeural.mp3',
	},
	{
		value: 'bs-BA-GoranNeural',
		name: 'Goran',
		language: 'Bosnian (Bosnia and Herzegovina)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/bs-BA-GoranNeural.mp3',
	},
	{
		value: 'ka-GE-EkaNeural',
		name: 'Eka',
		language: 'Georgian (Georgia)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ka-GE-EkaNeural.mp3',
	},
	{
		value: 'ka-GE-GiorgiNeural',
		name: 'Giorgi',
		language: 'Georgian (Georgia)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ka-GE-GiorgiNeural.mp3',
	},
	{
		value: 'mn-MN-YesuiNeural',
		name: 'Yesui',
		language: 'Mongolian (Mongolia)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/mn-MN-YesuiNeural.mp3',
	},
	{
		value: 'mn-MN-BataaNeural',
		name: 'Bataa',
		language: 'Mongolian (Mongolia)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/mn-MN-BataaNeural.mp3',
	},
	{
		value: 'ne-NP-HemkalaNeural',
		name: 'Hemkala',
		language: 'Nepali (Nepal)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ne-NP-HemkalaNeural.mp3',
	},
	{
		value: 'ne-NP-SagarNeural',
		name: 'Sagar',
		language: 'Nepali (Nepal)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ne-NP-SagarNeural.mp3',
	},
	{
		value: 'ta-MY-KaniNeural',
		name: 'Kani',
		language: 'Tamil (Malaysia)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/ta-MY-KaniNeural.mp3',
	},
	{
		value: 'ta-MY-SuryaNeural',
		name: 'Surya',
		language: 'Tamil (Malaysia)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/ta-MY-SuryaNeural.mp3',
	},
	{
		value: 'zh-CN-YunfengNeural',
		name: 'Yunfeng',
		language: 'Chinese (Mandarin, Simplified)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/zh-CN-YunfengNeural.mp3',
	},
	{
		value: 'zh-CN-YunhaoNeural',
		name: 'Yunhao',
		language: 'Chinese (Mandarin, Simplified)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/zh-CN-YunhaoNeural.mp3',
	},
	{
		value: 'zh-CN-YunjianNeural',
		name: 'Yunjian',
		language: 'Chinese (Mandarin, Simplified)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/zh-CN-YunjianNeural.mp3',
	},
	{
		value: 'zh-CN-liaoning-XiaobeiNeural',
		name: 'liaoning',
		language: 'Chinese (Mandarin, Simplified)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/zh-CN-liaoning-XiaobeiNeural.mp3',
	},
	{
		value: 'it-IT-FabiolaNeural',
		name: 'Fabiola',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-FabiolaNeural.mp3',
	},
	{
		value: 'it-IT-FiammaNeural',
		name: 'Fiamma',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-FiammaNeural.mp3',
	},
	{
		value: 'it-IT-ImeldaNeural',
		name: 'Imelda',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-ImeldaNeural.mp3',
	},
	{
		value: 'it-IT-IrmaNeural',
		name: 'Irma',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-IrmaNeural.mp3',
	},
	{
		value: 'it-IT-PalmiraNeural',
		name: 'Palmira',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-PalmiraNeural.mp3',
	},
	{
		value: 'it-IT-PierinaNeural',
		name: 'Pierina',
		language: 'Italian (Italy)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/it-IT-PierinaNeural.mp3',
	},
	{
		value: 'it-IT-BenignoNeural',
		name: 'Benigno',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-BenignoNeural.mp3',
	},
	{
		value: 'it-IT-CalimeroNeural',
		name: 'Calimero',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-CalimeroNeural.mp3',
	},
	{
		value: 'it-IT-CataldoNeural',
		name: 'Cataldo',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-CataldoNeural.mp3',
	},
	{
		value: 'it-IT-GianniNeural',
		name: 'Gianni',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-GianniNeural.mp3',
	},
	{
		value: 'it-IT-LisandroNeural',
		name: 'Lisandro',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-LisandroNeural.mp3',
	},
	{
		value: 'it-IT-RinaldoNeural',
		name: 'Rinaldo',
		language: 'Italian (Italy)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/it-IT-RinaldoNeural.mp3',
	},
	{
		value: 'pt-BR-BrendaNeural',
		name: 'Brenda',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-BrendaNeural.mp3',
	},
	{
		value: 'pt-BR-ElzaNeural',
		name: 'Elza',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-ElzaNeural.mp3',
	},
	{
		value: 'pt-BR-GiovannaNeural',
		name: 'Giovanna',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-GiovannaNeural.mp3',
	},
	{
		value: 'pt-BR-LeilaNeural',
		name: 'Leila',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-LeilaNeural.mp3',
	},
	{
		value: 'pt-BR-LeticiaNeural',
		name: 'Leticia',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-LeticiaNeural.mp3',
	},
	{
		value: 'pt-BR-ManuelaNeural',
		name: 'Manuela',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-ManuelaNeural.mp3',
	},
	{
		value: 'pt-BR-YaraNeural',
		name: 'Yara',
		language: 'Portuguese (Brazil)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/pt-BR-YaraNeural.mp3',
	},
	{
		value: 'pt-BR-DonatoNeural',
		name: 'Donato',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-DonatoNeural.mp3',
	},
	{
		value: 'pt-BR-FabioNeural',
		name: 'Fabio',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-FabioNeural.mp3',
	},
	{
		value: 'pt-BR-HumbertoNeural',
		name: 'Humberto',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-HumbertoNeural.mp3',
	},
	{
		value: 'pt-BR-JulioNeural',
		name: 'Julio',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-JulioNeural.mp3',
	},
	{
		value: 'pt-BR-NicolauNeural',
		name: 'Nicolau',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-NicolauNeural.mp3',
	},
	{
		value: 'pt-BR-ValerioNeural',
		name: 'Valerio',
		language: 'Portuguese (Brazil)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/pt-BR-ValerioNeural.mp3',
	},
	{
		value: 'es-MX-BeatrizNeural',
		name: 'Beatriz',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-BeatrizNeural.mp3',
	},
	{
		value: 'es-MX-CandelaNeural',
		name: 'Candela',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-CandelaNeural.mp3',
	},
	{
		value: 'es-MX-CarlotaNeural',
		name: 'Carlota',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-CarlotaNeural.mp3',
	},
	{
		value: 'es-MX-LarissaNeural',
		name: 'Larissa',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-LarissaNeural.mp3',
	},
	{
		value: 'es-MX-MarinaNeural',
		name: 'Marina',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-MarinaNeural.mp3',
	},
	{
		value: 'es-MX-NuriaNeural',
		name: 'Nuria',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-NuriaNeural.mp3',
	},
	{
		value: 'es-MX-RenataNeural',
		name: 'Renata',
		language: 'Spanish (Mexico)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/es-MX-RenataNeural.mp3',
	},
	{
		value: 'es-MX-CecilioNeural',
		name: 'Cecilio',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-CecilioNeural.mp3',
	},
	{
		value: 'es-MX-GerardoNeural',
		name: 'Gerardo',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-GerardoNeural.mp3',
	},
	{
		value: 'es-MX-LibertoNeural',
		name: 'Liberto',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-LibertoNeural.mp3',
	},
	{
		value: 'es-MX-LucianoNeural',
		name: 'Luciano',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-LucianoNeural.mp3',
	},
	{
		value: 'es-MX-PelayoNeural',
		name: 'Pelayo',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-PelayoNeural.mp3',
	},
	{
		value: 'es-MX-YagoNeural',
		name: 'Yago',
		language: 'Spanish (Mexico)',
		gender: 'Male',
		sample: 'https://media.play.ht/voice-samples/es-MX-YagoNeural.mp3',
	},
	{
		value: 'zh-CN-XiaoshuangNeural',
		name: 'Xiaoshuang',
		language: 'Chinese (Mandarin, Simplified)',
		gender: 'Female',
		sample: 'https://media.play.ht/voice-samples/zh-CN-XiaoshuangNeural.mp3',
	},
];

const IsoLanguageNames = [
	{ name: 'Abkhazian', code: 'ab' },
	{ name: 'Afar', code: 'aa' },
	{ name: 'Afrikaans', code: 'af' },
	{ name: 'Akan', code: 'ak' },
	{ name: 'Albanian', code: 'sq' },
	{ name: 'Amharic', code: 'am' },
	{ name: 'Arabic', code: 'ar' },
	{ name: 'Aragonese', code: 'an' },
	{ name: 'Armenian', code: 'hy' },
	{ name: 'Assamese', code: 'as' },
	{ name: 'Avaric', code: 'av' },
	{ name: 'Avestan', code: 'ae' },
	{ name: 'Aymara', code: 'ay' },
	{ name: 'Azerbaijani', code: 'az' },
	{ name: 'Bambara', code: 'bm' },
	{ name: 'Bashkir', code: 'ba' },
	{ name: 'Basque', code: 'eu' },
	{ name: 'Belarusian', code: 'be' },
	{ name: 'Bengali', code: 'bn' },
	{ name: 'Bislama', code: 'bi' },
	{ name: 'Bosnian', code: 'bs' },
	{ name: 'Breton', code: 'br' },
	{ name: 'Bulgarian', code: 'bg' },
	{ name: 'Burmese', code: 'my' },
	{ name: 'Catalan, Valencian', code: 'ca' },
	{ name: 'Central Khmer', code: 'km' },
	{ name: 'Chamorro', code: 'ch' },
	{ name: 'Chechen', code: 'ce' },
	{ name: 'Chichewa, Chewa, Nyanja', code: 'ny' },
	{ name: 'Chinese', code: 'zh' },
	{ name: 'Church Slavonic, Old Slavonic, Old Church Slavonic', code: 'cu' },
	{ name: 'Chuvash', code: 'cv' },
	{ name: 'Cornish', code: 'kw' },
	{ name: 'Corsican', code: 'co' },
	{ name: 'Cree', code: 'cr' },
	{ name: 'Croatian', code: 'hr' },
	{ name: 'Czech', code: 'cs' },
	{ name: 'Danish', code: 'da' },
	{ name: 'Divehi, Dhivehi, Maldivian', code: 'dv' },
	{ name: 'Dutch, Flemish', code: 'nl' },
	{ name: 'Dzongkha', code: 'dz' },
	{ name: 'English', code: 'en' },
	{ name: 'Esperanto', code: 'eo' },
	{ name: 'Estonian', code: 'et' },
	{ name: 'Ewe', code: 'ee' },
	{ name: 'Faroese', code: 'fo' },
	{ name: 'Fijian', code: 'fj' },
	{ name: 'Finnish', code: 'fi' },
	{ name: 'French', code: 'fr' },
	{ name: 'Fulah', code: 'ff' },
	{ name: 'Gaelic, Scottish Gaelic', code: 'gd' },
	{ name: 'Galician', code: 'gl' },
	{ name: 'Ganda', code: 'lg' },
	{ name: 'Georgian', code: 'ka' },
	{ name: 'German', code: 'de' },
	{ name: 'Greek, Modern (1453–)', code: 'el' },
	{ name: 'Guarani', code: 'gn' },
	{ name: 'Gujarati', code: 'gu' },
	{ name: 'Haitian, Haitian Creole', code: 'ht' },
	{ name: 'Hausa', code: 'ha' },
	{ name: 'Hebrew', code: 'he' },
	{ name: 'Herero', code: 'hz' },
	{ name: 'Hindi', code: 'hi' },
	{ name: 'Hiri Motu', code: 'ho' },
	{ name: 'Hungarian', code: 'hu' },
	{ name: 'Icelandic', code: 'is' },
	{ name: 'Ido', code: 'io' },
	{ name: 'Igbo', code: 'ig' },
	{ name: 'Indonesian', code: 'id' },
	{ name: 'Interlingua (International Auxiliary Language Association)', code: 'ia' },
	{ name: 'Interlingue, Occidental', code: 'ie' },
	{ name: 'Inuktitut', code: 'iu' },
	{ name: 'Inupiaq', code: 'ik' },
	{ name: 'Irish', code: 'ga' },
	{ name: 'Italian', code: 'it' },
	{ name: 'Japanese', code: 'ja' },
	{ name: 'Javanese', code: 'jv' },
	{ name: 'Kalaallisut, Greenlandic', code: 'kl' },
	{ name: 'Kannada', code: 'kn' },
	{ name: 'Kanuri', code: 'kr' },
	{ name: 'Kashmiri', code: 'ks' },
	{ name: 'Kazakh', code: 'kk' },
	{ name: 'Kikuyu, Gikuyu', code: 'ki' },
	{ name: 'Kinyarwanda', code: 'rw' },
	{ name: 'Kirghiz, Kyrgyz', code: 'ky' },
	{ name: 'Komi', code: 'kv' },
	{ name: 'Kongo', code: 'kg' },
	{ name: 'Korean', code: 'ko' },
	{ name: 'Kuanyama, Kwanyama', code: 'kj' },
	{ name: 'Kurdish', code: 'ku' },
	{ name: 'Lao', code: 'lo' },
	{ name: 'Latin', code: 'la' },
	{ name: 'Latvian', code: 'lv' },
	{ name: 'Limburgan, Limburger, Limburgish', code: 'li' },
	{ name: 'Lingala', code: 'ln' },
	{ name: 'Lithuanian', code: 'lt' },
	{ name: 'Luba-Katanga', code: 'lu' },
	{ name: 'Luxembourgish, Letzeburgesch', code: 'lb' },
	{ name: 'Macedonian', code: 'mk' },
	{ name: 'Malagasy', code: 'mg' },
	{ name: 'Malay', code: 'ms' },
	{ name: 'Malayalam', code: 'ml' },
	{ name: 'Maltese', code: 'mt' },
	{ name: 'Manx', code: 'gv' },
	{ name: 'Maori', code: 'mi' },
	{ name: 'Marathi', code: 'mr' },
	{ name: 'Marshallese', code: 'mh' },
	{ name: 'Mongolian', code: 'mn' },
	{ name: 'Nauru', code: 'na' },
	{ name: 'Navajo, Navaho', code: 'nv' },
	{ name: 'Ndonga', code: 'ng' },
	{ name: 'Nepali', code: 'ne' },
	{ name: 'North Ndebele', code: 'nd' },
	{ name: 'Northern Sami', code: 'se' },
	{ name: 'Norwegian', code: 'no' },
	{ name: 'Norwegian Bokmål', code: 'nb' },
	{ name: 'Norwegian Nynorsk', code: 'nn' },
	{ name: 'Occitan', code: 'oc' },
	{ name: 'Ojibwa', code: 'oj' },
	{ name: 'Oriya', code: 'or' },
	{ name: 'Oromo', code: 'om' },
	{ name: 'Ossetian, Ossetic', code: 'os' },
	{ name: 'Pali', code: 'pi' },
	{ name: 'Pashto, Pushto', code: 'ps' },
	{ name: 'Persian', code: 'fa' },
	{ name: 'Polish', code: 'pl' },
	{ name: 'Portuguese', code: 'pt' },
	{ name: 'Punjabi, Panjabi', code: 'pa' },
	{ name: 'Quechua', code: 'qu' },
	{ name: 'Romanian, Moldavian, Moldovan', code: 'ro' },
	{ name: 'Romansh', code: 'rm' },
	{ name: 'Rundi', code: 'rn' },
	{ name: 'Russian', code: 'ru' },
	{ name: 'Samoan', code: 'sm' },
	{ name: 'Sango', code: 'sg' },
	{ name: 'Sanskrit', code: 'sa' },
	{ name: 'Sardinian', code: 'sc' },
	{ name: 'Serbian', code: 'sr' },
	{ name: 'Shona', code: 'sn' },
	{ name: 'Sichuan Yi, Nuosu', code: 'ii' },
	{ name: 'Sindhi', code: 'sd' },
	{ name: 'Sinhala, Sinhalese', code: 'si' },
	{ name: 'Slovak', code: 'sk' },
	{ name: 'Slovenian', code: 'sl' },
	{ name: 'Somali', code: 'so' },
	{ name: 'South Ndebele', code: 'nr' },
	{ name: 'Southern Sotho', code: 'st' },
	{ name: 'Spanish, Castilian', code: 'es' },
	{ name: 'Sundanese', code: 'su' },
	{ name: 'Swahili', code: 'sw' },
	{ name: 'Swati', code: 'ss' },
	{ name: 'Swedish', code: 'sv' },
	{ name: 'Tagalog', code: 'tl' },
	{ name: 'Tahitian', code: 'ty' },
	{ name: 'Tajik', code: 'tg' },
	{ name: 'Tamil', code: 'ta' },
	{ name: 'Tatar', code: 'tt' },
	{ name: 'Telugu', code: 'te' },
	{ name: 'Thai', code: 'th' },
	{ name: 'Tibetan', code: 'bo' },
	{ name: 'Tigrinya', code: 'ti' },
	{ name: 'Tonga (Tonga Islands)', code: 'to' },
	{ name: 'Tsonga', code: 'ts' },
	{ name: 'Tswana', code: 'tn' },
	{ name: 'Turkish', code: 'tr' },
	{ name: 'Turkmen', code: 'tk' },
	{ name: 'Twi', code: 'tw' },
	{ name: 'Uighur, Uyghur', code: 'ug' },
	{ name: 'Ukrainian', code: 'uk' },
	{ name: 'Urdu', code: 'ur' },
	{ name: 'Uzbek', code: 'uz' },
	{ name: 'Venda', code: 've' },
	{ name: 'Vietnamese', code: 'vi' },
	{ name: 'Volapük', code: 'vo' },
	{ name: 'Walloon', code: 'wa' },
	{ name: 'Welsh', code: 'cy' },
	{ name: 'Western Frisian', code: 'fy' },
	{ name: 'Wolof', code: 'wo' },
	{ name: 'Xhosa', code: 'xh' },
	{ name: 'Yiddish', code: 'yi' },
	{ name: 'Yoruba', code: 'yo' },
	{ name: 'Zhuang, Chuang', code: 'za' },
	{ name: 'Zulu', code: 'zu' },
];

const VoiceLanguagesElevanLabs = [
	{
		id: '21m00Tcm4TlvDq8ikWAM',
		name: 'Rachel',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/21m00Tcm4TlvDq8ikWAM/df6788f9-5c96-470d-8312-aab3b3d8f50a.mp3',
	},
	{
		id: '29vD33N1CtxCmqQRPOHJ',
		name: 'Drew',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/29vD33N1CtxCmqQRPOHJ/e8b52a3f-9732-440f-b78a-16d5e26407a1.mp3',
	},
	{
		id: '2EiwWnXFnvU5JabPnv8n',
		name: 'Clyde',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/2EiwWnXFnvU5JabPnv8n/65d80f52-703f-4cae-a91d-75d4e200ed02.mp3',
	},
	{
		id: '5Q0t7uMcjvnagumLfvZi',
		name: 'Paul',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/5Q0t7uMcjvnagumLfvZi/1094515a-b080-4282-aac7-b1b8a553a3a8.mp3',
	},
	{
		id: 'AZnzlk1XvdvUeBnXmlld',
		name: 'Domi',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/AZnzlk1XvdvUeBnXmlld/508e12d0-a7f7-4d86-a0d3-f3884ff353ed.mp3',
	},
	{
		id: 'CYw3kZ02Hs0563khs1Fj',
		name: 'Dave',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/CYw3kZ02Hs0563khs1Fj/872cb056-45d3-419e-b5c6-de2b387a93a0.mp3',
	},
	{
		id: 'D38z5RcWu1voky8WS1ja',
		name: 'Fin',
		language: 'English (Ireland)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/D38z5RcWu1voky8WS1ja/a470ba64-1e72-46d9-ba9d-030c4155e2d2.mp3',
	},
	{
		id: 'EXAVITQu4vr4xnSDxMaL',
		name: 'Sarah',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/EXAVITQu4vr4xnSDxMaL/6851ec91-9950-471f-8586-357c52539069.mp3',
	},
	{
		id: 'ErXwobaYiN019PkySvjV',
		name: 'Antoni',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/ErXwobaYiN019PkySvjV/ee9ac367-91ee-4a56-818a-2bd1a9dbe83a.mp3',
	},
	{
		id: 'GBv7mTt0atIp3Br8iCZE',
		name: 'Thomas',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/GBv7mTt0atIp3Br8iCZE/98542988-5267-4148-9a9e-baa8c4f14644.mp3',
	},
	{
		id: 'IKne3meq5aSn9XLyUdCD',
		name: 'Charlie',
		language: 'English (Australia)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/IKne3meq5aSn9XLyUdCD/102de6f2-22ed-43e0-a1f1-111fa75c5481.mp3',
	},
	{
		id: 'JBFqnCBsd6RMkjVDRZzb',
		name: 'George',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/JBFqnCBsd6RMkjVDRZzb/365e8ae8-5364-4b07-9a3b-1bfb4a390248.mp3',
	},
	{
		id: 'LcfcDJNUP1GQjkzn1xUU',
		name: 'Emily',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/LcfcDJNUP1GQjkzn1xUU/e4b994b7-9713-4238-84f3-add8fccaaccd.mp3',
	},
	{
		id: 'MF3mGyEYCl7XYWbV9V6O',
		name: 'Elli',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/MF3mGyEYCl7XYWbV9V6O/d8ecadea-9e48-4e5d-868a-2ec3d7397861.mp3',
	},
	{
		id: 'N2lVS1w4EtoT3dr4eOWO',
		name: 'Callum',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/N2lVS1w4EtoT3dr4eOWO/ac833bd8-ffda-4938-9ebc-b0f99ca25481.mp3',
	},
	{
		id: 'ODq5zmih8GrVes37Dizd',
		name: 'Patrick',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/ODq5zmih8GrVes37Dizd/0ebec87a-2569-4976-9ea5-0170854411a9.mp3',
	},
	{
		id: 'SOYHLrjzK2X1ezoPC6cr',
		name: 'Harry',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/SOYHLrjzK2X1ezoPC6cr/86d178f6-f4b6-4e0e-85be-3de19f490794.mp3',
	},
	{
		id: 'TX3LPaxmHKxFdv7VOQHJ',
		name: 'Liam',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/TX3LPaxmHKxFdv7VOQHJ/63148076-6363-42db-aea8-31424308b92c.mp3',
	},
	{
		id: 'ThT5KcBeYPX3keUQqHPh',
		name: 'Dorothy',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/ThT5KcBeYPX3keUQqHPh/981f0855-6598-48d2-9f8f-b6d92fbbe3fc.mp3',
	},
	{
		id: 'TxGEqnHWrfWFTfGW9XjX',
		name: 'Josh',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/TxGEqnHWrfWFTfGW9XjX/3ae2fc71-d5f9-4769-bb71-2a43633cd186.mp3',
	},
	{
		id: 'VR6AewLTigWG4xSOukaG',
		name: 'Arnold',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/VR6AewLTigWG4xSOukaG/316050b7-c4e0-48de-acf9-a882bb7fc43b.mp3',
	},
	{
		id: 'XB0fDUnXU5powFXDhCwa',
		name: 'Charlotte',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/XB0fDUnXU5powFXDhCwa/942356dc-f10d-4d89-bda5-4f8505ee038b.mp3',
	},
	{
		id: 'Xb7hH8MSUJpSbSDYk0k2',
		name: 'Alice',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/Xb7hH8MSUJpSbSDYk0k2/f5409e2f-d9c3-4ac9-9e7d-916a5dbd1ef1.mp3',
	},
	{
		id: 'XrExE9yKIg1WjnnlVkGX',
		name: 'Matilda',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/XrExE9yKIg1WjnnlVkGX/b930e18d-6b4d-466e-bab2-0ae97c6d8535.mp3',
	},
	{
		id: 'ZQe5CZNOzWyzPSCn5a3c',
		name: 'James',
		language: 'English (Australia)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/ZQe5CZNOzWyzPSCn5a3c/35734112-7b72-48df-bc2f-64d5ab2f791b.mp3',
	},
	{
		id: 'Zlb1dXrM653N07WRdFW3',
		name: 'Joseph',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/Zlb1dXrM653N07WRdFW3/daa22039-8b09-4c65-b59f-c79c48646a72.mp3',
	},
	{
		id: 'bVMeCyTHy58xNoL34h3p',
		name: 'Jeremy',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/bVMeCyTHy58xNoL34h3p/66c47d58-26fd-4b30-8a06-07952116a72c.mp3',
	},
	{
		id: 'flq6f7yk4E4fJM5XTYuZ',
		name: 'Michael',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/flq6f7yk4E4fJM5XTYuZ/c6431a82-f7d2-4905-b8a4-a631960633d6.mp3',
	},
	{
		id: 'g5CIjZEefAph4nQFvHAz',
		name: 'Ethan',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/g5CIjZEefAph4nQFvHAz/26acfa99-fdec-43b8-b2ee-e49e75a3ac16.mp3',
	},
	{
		id: 'iP95p4xoKVk53GoZ742B',
		name: 'Chris',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/iP95p4xoKVk53GoZ742B/c1bda571-7123-418e-a796-a2b464b373b4.mp3',
	},
	{
		id: 'jBpfuIE2acCO8z3wKNLl',
		name: 'Gigi',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/jBpfuIE2acCO8z3wKNLl/3a7e4339-78fa-404e-8d10-c3ef5587935b.mp3',
	},
	{
		id: 'jsCqWAovK2LkecY7zXl4',
		name: 'Freya',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/jsCqWAovK2LkecY7zXl4/8e1f5240-556e-4fd5-892c-25df9ea3b593.mp3',
	},
	{
		id: 'nPczCjzI2devNBz1zQrb',
		name: 'Brian',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/nPczCjzI2devNBz1zQrb/f4dbda0c-aff0-45c0-93fa-f5d5ec95a2eb.mp3',
	},
	{
		id: 'oWAxZDx7w5VEj9dCyTzz',
		name: 'Grace',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/oWAxZDx7w5VEj9dCyTzz/84a36d1c-e182-41a8-8c55-dbdd15cd6e72.mp3',
	},
	{
		id: 'onwK4e9ZLuTAKqWW03F9',
		name: 'Daniel',
		language: 'English (UK)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/onwK4e9ZLuTAKqWW03F9/7eee0236-1a72-4b86-b303-5dcadc007ba9.mp3',
	},
	{
		id: 'pFZP5JQG7iQjIQuC4Bku',
		name: 'Lily',
		language: 'English (UK)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/pFZP5JQG7iQjIQuC4Bku/0ab8bd74-fcd2-489d-b70a-3e1bcde8c999.mp3',
	},
	{
		id: 'pMsXgVXv3BLzUgSXRplE',
		name: 'Serena',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/pMsXgVXv3BLzUgSXRplE/d61f18ed-e5b0-4d0b-a33c-5c6e7e33b053.mp3',
	},
	{
		id: 'pNInz6obpgDQGcFmaJgB',
		name: 'Adam',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/pNInz6obpgDQGcFmaJgB/38a69695-2ca9-4b9e-b9ec-f07ced494a58.mp3',
	},
	{
		id: 'piTKgcLEGmPE4e6mEKli',
		name: 'Nicole',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/piTKgcLEGmPE4e6mEKli/c269a54a-e2bc-44d0-bb46-4ed2666d6340.mp3',
	},
	{
		id: 'pqHfZKP75CvOlQylNhV4',
		name: 'Bill',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/pqHfZKP75CvOlQylNhV4/52f0842a-cf81-4715-8cf0-76cfbd77088e.mp3',
	},
	{
		id: 't0jbNlBVZ17f02VDIeMI',
		name: 'Jessie',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/t0jbNlBVZ17f02VDIeMI/e26939e3-61a4-4872-a41d-33922cfbdcdc.mp3',
	},
	{
		id: 'yoZ06aMxZJJ28mfd3POQ',
		name: 'Sam',
		language: 'English (US)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/yoZ06aMxZJJ28mfd3POQ/ac9d1c91-92ce-4b20-8cc2-3187a7da49ec.mp3',
	},
	{
		id: 'z9fAnlkpzviPz146aGWa',
		name: 'Glinda',
		language: 'English (US)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/z9fAnlkpzviPz146aGWa/cbc60443-7b61-4ebb-b8e1-5c03237ea01d.mp3',
	},
	{
		id: 'zcAOhNBS3c14rBihAFp1',
		name: 'Giovanni',
		language: 'English (Italian)',
		gender: 'Male',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/zcAOhNBS3c14rBihAFp1/e7410f8f-4913-4cb8-8907-784abee5aff8.mp3',
	},
	{
		id: 'zrHiDhphv9ZnVXBqCLjz',
		name: 'Mimi',
		language: 'English (Swedish)',
		gender: 'Female',
		sample: 'https://storage.googleapis.com/eleven-public-prod/premade/voices/zrHiDhphv9ZnVXBqCLjz/decbf20b-0f57-4fac-985b-a4f0290ebfc4.mp3',
	},
];

const LanguagesElevenLabs = [
	{ language: 'Chinese', placeHolder: '🇨🇳 Chinese' },
	{ language: 'Korean', placeHolder: '🇰🇷 Korean' },
	{ language: 'Dutch', placeHolder: '🇳🇱 Dutch' },
	{ language: 'Turkish', placeHolder: '🇹🇷 Turkish' },
	{ language: 'Swedish', placeHolder: '🇸🇪 Swedish' },
	{ language: 'Indonesian', placeHolder: '🇮🇩 Indonesian' },
	{ language: 'Filipino', placeHolder: '🇵🇭 Filipino' },
	{ language: 'Japanese', placeHolder: '🇯🇵 Japanese' },
	{ language: 'Ukrainian', placeHolder: '🇺🇦 Ukrainian' },
	{ language: 'Greek', placeHolder: '🇬🇷 Greek' },
	{ language: 'Czech', placeHolder: '🇨🇿 Czech' },
	{ language: 'Finnish', placeHolder: '🇫🇮 Finnish' },
	{ language: 'Romanian', placeHolder: '🇷🇴 Romanian' },
	{ language: 'Russian', placeHolder: '🇷🇺 Russian' },
	{ language: 'Danish', placeHolder: '🇩🇰 Danish' },
	{ language: 'Bulgarian', placeHolder: '🇧🇬 Bulgarian' },
	{ language: 'Malay', placeHolder: '🇲🇾 Malay' },
	{ language: 'Slovak', placeHolder: '🇸🇰 Slovak' },
	{ language: 'Croatian', placeHolder: '🇭🇷 Croatian' },
	{ language: 'Classic Arabic', placeHolder: '🇸🇦 Classic Arabic' },
	{ language: 'Tamil', placeHolder: '🇮🇳 Tamil' },
	{ language: 'English', placeHolder: '🇬🇧 English' },
	{ language: 'Polish', placeHolder: '🇵🇱 Polish' },
	{ language: 'German', placeHolder: '🇩🇪 German' },
	{ language: 'Spanish', placeHolder: '🇪🇸 Spanish' },
	{ language: 'French', placeHolder: '🇫🇷 French' },
	{ language: 'Italian', placeHolder: '🇮🇹 Italian' },
	{ language: 'Hindi', placeHolder: '🇮🇳 Hindi' },
	{ language: 'Portuguese', placeHolder: '🇵🇹 Portuguese' },
];

export default {
	Languages,
	VoiceLanguages,
	IsoLanguageNames,
	VoiceLanguagesElevanLabs,
	LanguagesElevenLabs
}