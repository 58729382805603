import { useState } from 'react';

const useAuth = () => {
	const [token, setToken] = useState('');
	const [username, setUsername] = useState('');
	const [avatar, setAvatar] = useState('');
	const [email, setEmail] = useState('');
	const [name, setName] = useState('');
	const [id, setId] = useState('');
	const [role, setRole] = useState('');
	const [credits, setCredits] = useState(0);

	const [userInfo, setUserInfo] = useState(null)

	const [imageReelsAI, setImageReelsAI] = useState([]);
	const [imageReelsUpload, setImageReelsUpload] = useState([]);

	const resetUseAuth = () => {
		setUserInfo(null);
		setToken('');
		setUsername('');
		setAvatar('');
		setEmail('');
		setName('');
		setId('');
		setRole('');
		setCredits(0);
		setImageReelsAI([]);
		setImageReelsUpload([]);
	};

	return {
		userInfo,
		setUserInfo,
		token,
		setToken,
		username,
		setUsername,
		avatar,
		setAvatar,
		email,
		setEmail,
		name,
		setName,
		id,
		setId,
		role,
		setRole,
		imageReelsAI,
		setImageReelsAI,
		imageReelsUpload,
		setImageReelsUpload,
		credits,
		setCredits,
		resetUseAuth,
	};
};

export default useAuth;
