const Constants = {
  REQUEST_API: {
    POST: 'post',
    GET: 'get',
    PUT: 'put',
    PATCH: 'patch',
    DELETE: 'delete'
  }
};

export default Constants;