import useFetch from '../Utils/Api/useFetch';
import { Constants } from '../Constants';

const useProduct = () => {
	let credits = {
		generate_text: 0,
		generate_image: 0,
		generate_text_to_speech: 0,
		generate_video: 0,
	};
	const typeMapping = {
		'388db4fc': 'generate_image',
		'89d206da': 'generate_video',
		'9d6b25e9': 'generate_text',
		'9e582ca7': 'generate_text_to_speech',
	}

	if (credits.generate_text === 0) {
		const options = {
			methods: Constants.REQUEST_API.GET,
			url: '/crq'
		};

		const { loading, error, data } = useFetch(options);

		if (!!data && !loading) {
			Object.entries(data).map((items) => {
				credits[typeMapping[items[0]]] = items[1]
			})
		}
	}
	
	return {
		credits,
	};
};

export default useProduct;
