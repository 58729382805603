import React, { useContext, useEffect } from 'react';
import { BrowserRouter as Router, useNavigate } from 'react-router-dom';
import { NextUIProvider } from '@nextui-org/react';

import './App.css';
import 'react-toastify/dist/ReactToastify.css';

import AppRoutes from './Routes/AppRoutes';
import { ThemeContext } from './hooks/ThemeContext';

const App = () => {
	const { setIsDark } = useContext(ThemeContext);
	const navigate = useNavigate();

	useEffect(() => {
		const theme = JSON.parse(localStorage.getItem('theme'));

		setIsDark(theme === 'dark' ? true : false);
		document.documentElement.classList.add(theme);
	}, []);

	return (
		<NextUIProvider navigate={navigate}>
			<AppRoutes />
		</NextUIProvider>
	);
};

export default App;
