import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import reportWebVitals from './reportWebVitals';
import AppProviders from './hooks/AppProviders';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	<AppProviders>
		<App />
	</AppProviders>
);

reportWebVitals();
