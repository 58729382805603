let navigateFunction = null;
let cookiesFunction = null;

export const setNavigateFunction = (navigate, cookies) => {
	navigateFunction = navigate;
	cookiesFunction = cookies;
};

export const navigateTo = (path) => {
	if (navigateFunction) {
		navigateFunction(path);
	} else {
		console.error('Navigate function is not set');
	}
};

export const handleForbidden = () => {
  if (navigateFunction) { 
    cookiesFunction.remove('authData');
    
		navigateFunction('/login');
	}
};

export const updateExpiredToken = (newToken, refreshToken) => {
	if (cookiesFunction) {
		const authData = cookiesFunction.get('authData');
		authData.token = newToken;
		authData.refreshToken = refreshToken

		if (authData) { 
			cookiesFunction.set(
				'authData',
				JSON.stringify({
					...authData,
				}),
			);
		}
	}
}
