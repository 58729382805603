import axios from 'axios';

const refreshToken = async (api) => {
	const getNewToken = await api.get(`${process.env.REACT_APP_BACKEND_URL}/generate-token`);

	localStorage.setItem('accessToken', getNewToken.data.details.token);

	return getNewToken.data.details.token;
};

const getIPAddress = async () => {
	const res = await axios.get('https://api.ipify.org/?format=json');

	return res.data.ip;
};

export const createApi = (config) => {
	const accessToken = localStorage.getItem('accessToken');

	const api = axios.create({
		baseURL: process.env.REACT_APP_BACKEND_URL,
		withCredentials: true,
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Origin': '*',
		},
	});

	api.interceptors.request.use(async (config) => {
		if (accessToken && config.headers) {
			config.headers.Authorization = `Bearer ${accessToken}`;
		}

		config.headers['x-traffic-origin'] = await getIPAddress();

		return config;
	});

	api.interceptors.response.use(
		(response) => {
			const responseHeaders = response.headers[`x-route-id`];
			if (responseHeaders) localStorage.setItem(`origin`, responseHeaders);

			return response;
		},
		async (error) => {
			const { status, code } = error.response.data;
			if (status === 'ERR_UNAUTHORIZED' && code === 401) {
				try {
					const newToken = await refreshToken(api);

					const originalRequest = error.config;
					originalRequest.headers.Authorization = `Bearer ${newToken}`;

					return axios(originalRequest);
				} catch (error) {
					return new Promise.reject(error);
				}
			}

			return Promise.reject(error);
		}
	);
	return api(config);
};
