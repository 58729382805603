import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { motion } from 'framer-motion';
import moment from 'moment';
import { Card, CardFooter, Button, Tabs, Tab, CardBody, CardHeader, Divider, Image } from '@nextui-org/react';

import { AuthContext } from '../../hooks/AuthContext';
import fetchApi from '../../Utils/Api/fetchApi';
import { ThemeContext } from '../../hooks/ThemeContext';
import { Constants, MotionsFramer } from '../../Constants';

const { ContainerVariants } = MotionsFramer;

const HomeScreen = () => {
	const { isDark } = useContext(ThemeContext);
	const { token, username } = useContext(AuthContext);
	const [listImage, setListImage] = useState([]);
	const [listVideo, setListVideo] = useState([]);
	const [listSubtitle, setListSubtitle] = useState([]);
	const [combineData, setCombineData] = useState({ image: [], product: [], subtitle: [] });
	const [groupedData, setGroupedData] = useState({});

	useEffect(() => {
		const fetchListProject = async () => {
			const formData = {
				username: username,
			};

		const options = {
			method: Constants.REQUEST_API.GET,
			url: `/my-projects`
		};
		let response = await fetchApi(options);

			if (response?.data.code === 200) {
				const imageData = response.data.data.image || [];
				const productData = response.data.data.product || [];
				const subtitleData = response.data.data.subtitle || [];

				setListImage(imageData.slice(0, 10));
				setListVideo(productData.slice(0, 10));
				setListSubtitle(subtitleData.slice(0, 10));

				setCombineData({
					image: imageData.slice(0, 10),
					product: productData.slice(0, 10),
					subtitle: subtitleData.slice(0, 10),
				});
			}
		};

		if (token) fetchListProject();
	}, [username, token]);

	useEffect(() => {
		const combinedDataCustom = [...combineData.image, ...combineData.product, ...combineData.subtitle];

		const groupedData = combinedDataCustom.reduce((acc, item) => {
			const date = moment(item.created_at);
			let group = date.format('YYYY-MM-DD');

			if (date.isSame(moment(), 'day')) {
				group = 'Today';
			} else if (date.isSame(moment().subtract(1, 'day'), 'day')) {
				group = 'Yesterday';
			}

			if (!acc[group]) {
				acc[group] = [];
			}
			acc[group].push(item);
			return acc;
		}, {});

		setGroupedData(groupedData);
	}, [combineData]);

	return (
		<motion.div className='px-6' variants={ContainerVariants} initial='hidden' animate='visible'>
			<div className='flex mt-52 mb-48 justify-center'>
				<div className='w-full md:w-1/2 text-left'>
					<TypeAnimation
						style={{ textAlign: 'center' }}
						sequence={[
							'Welcome to OuVee 🎉',
							2000,
							'Bienvenido a OuVee 🎉',
							2000,
							'欢迎来到 OuVee 🎉',
							2000,
							'Bienvenue sur OuVee 🎉',
							2000,
							'Willkommen bei OuVee 🎉',
							2000,
							'Benvenuti su OuVee 🎉',
							2000,
							'ようこそ OuVee へ 🎉',
							2000,
							'OuVee میں خوش آمدید 🎉',
							2000,
							'Добро пожаловать в OuVee 🎉',
							2000,
							'OuVee에 오신 것을 환영합니다 🎉',
							2000,
							'Bem-vindo ao OuVee 🎉',
							2000,
						]}
						repeat={Infinity}
						wrapper='h1'
						className='text-3xl font-bold text-center mb-5'
					/>
					<p className='text-lg text-gray-600 mb-8 text-center'>
						Create amazing content from prompts quickly and easily.
					</p>
					<div className='flex flex-col md:flex-row gap-4 justify-center items-center'>
						{token ? (
							<h4 className='text-xl text-gray-400 mb-2 mb-md-0 border border-gray-300 px-4 py-2 rounded bg-gray-100'>
								Hi, {username} 🎯
							</h4>
						) : (
							<>
								<Button
									size='lg'
									className={`rounded-md bg-transparent py-2 px-4 md:w-auto ${
										isDark ? 'white' : 'text-graybg-gray-400'
									} border  !border-graybg-gray-400`}
									as={Link}
									to='/login'>
									Give It a Try
								</Button>
							</>
						)}
					</div>
				</div>
			</div>
		</motion.div>
	);
};

export default HomeScreen;
