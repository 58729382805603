import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../hooks/AuthContext';
import Layout from '../../Components/Layout/Layout';

const ProtectedRoute = ({ element, isAdmin, isFooter }) => {
	const token = localStorage.getItem('accessToken')
	const { role } = useContext(AuthContext);

	if (token) {
		if (isAdmin && role !== 'admin') return <Navigate to='/' />;

		return <Layout element={element} isFooter={isFooter} />;
	}

	return <Navigate to='/login' />;
};

export default ProtectedRoute;
