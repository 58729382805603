import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, Routes } from 'react-router-dom';
import Cookies from 'universal-cookie';

import UnauthenticatedRoutes from './UnauthenticatedRoutes/UnauthenticatedRoutes';
import AuthenticatedRoutes from './AuthenticatedRoutes/AuthenticatedRoutes';
import PublicRoutes from './PublicRoutes/PublicRoutes';
import LoadingSpinnerOverlay from '../Components/LoadingSpinnerOverlay/LoadingSpinnerOverlay';
import { AuthContext } from '../hooks/AuthContext';
import { setNavigateFunction } from '../Utils/navigation';

const useAuthData = (setToken, setUsername, setAvatar, setId, setRole, setCredits) => {
  const cookies = new Cookies();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAuthData = () => {
      let cookieResult = cookies.get('authData', { path: '/' });
      if (cookieResult) {
        const { auth, username, avatar, role, id, credits } = cookieResult;

        setCredits(credits);
        setToken(auth);
        setUsername(username);
        setId(id);
        setRole(role);
        if (avatar) {
          setAvatar(`${avatar}?t=${new Date().getTime()}`);
        }
      } else {
        setToken(null);
        setUsername(null);
        setId(null);
        setRole(null);
        setAvatar(null);
      }
      setLoading(false);
    };

    fetchAuthData();
  }, [setToken, setUsername, setAvatar, setId, setRole, setCredits]);

  useEffect(() => {
    setNavigateFunction(navigate, cookies);
  }, [navigate]);

  return loading;
};

const useScrollToTopOnLoad = (loading) => {
  useEffect(() => {
    if (!loading) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
    }
  }, [loading]);
};

const useUpdateTitle = (username) => {
  useEffect(() => {
    document.title = `Ouvee ${username ? `- Welcome back, ${username}!` : '- Create an engaging and impactful video'}`;
  }, [username]);
}

const useUpdateEffect = (loading, username) => {
  useScrollToTopOnLoad(loading);
  useUpdateTitle(username)
}

const AppRoutes = () => {
  const { setToken, setUsername, username, setAvatar, setId, setRole, token, role, setCredits } = useContext(AuthContext);
  const loading = useAuthData(setToken, setUsername, setAvatar, setId, setRole, setCredits);

  useUpdateEffect(loading, username);

  if (loading) return <LoadingSpinnerOverlay />;

  return (
    <Routes>
      {PublicRoutes()}
      {!token ? UnauthenticatedRoutes() : AuthenticatedRoutes(role)}
    </Routes>
  );
};

export default AppRoutes;
