import React, { lazy, Suspense } from 'react';
import { Route } from 'react-router-dom';

import Layout from '../../Components/Layout/Layout';
import {
  LoginScreen,
  RegisterScreen
} from './LazyImports';

const UnauthenticatedRoutes = () => {
  return (
    <>
      <Route path='/login' element={<Layout element={<LoginScreen />} />} />
      <Route path='/register' element={<Layout element={<RegisterScreen />} />} />
    </>
  );
};

export default UnauthenticatedRoutes;
