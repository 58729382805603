import React from 'react';
import { Link } from 'react-router-dom';
import { Image } from '@nextui-org/react';

import { Instagram, Tiktok, Twitter } from '../../Assets/Svg';
import { AdsIconColor } from '../../Assets/Image';

const Footer = () => {
  const [year] = React.useState(new Date().getFullYear());

  return (
    <footer className="bg-white dark:bg-neutral-800">
      <hr className='mb-10' />
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            {/* <a href="/" className="flex items-center">
              <img src={AdsIconColor} className="h-8 me-3" alt="Logo" />
              <span className="self-center text-2xl font-semibold whitespace-nowrap dark:text-white">OuVee</span>
            </a> */}
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3">
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Resources</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link to="/about" className="hover:underline">About Us</Link>
                </li>
                <li>
                  <Link to="/contact" className="hover:underline">Contact Us</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Cookies</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link to="/support" className="hover:underline">Support</Link>
                </li>
                <li>
                  <Link to="/cookies" className="hover:underline">Cookies</Link>
                </li>
              </ul>
            </div>
            <div>
              <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase dark:text-white">Legal</h2>
              <ul className="text-gray-500 dark:text-gray-400 font-medium">
                <li className="mb-4">
                  <Link to="/privacy" className="hover:underline">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms" className="hover:underline">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-200 sm:mx-auto dark:border-gray-700 lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            &copy; {year} <a href="/" className="hover:underline">OuVee</a>. All Rights Reserved.
          </span>
          <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a href="https://www.instagram.com/ourVideo" className="text-gray-500 hover:text-gray-900 dark:hover:text-white">
              <img src={Instagram} alt="Instagram" className="w-4 h-4" />
              <span className="sr-only">Instagram</span>
            </a>
            <a href="https://www.tiktok.com/@ourvideoai" className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
              <img src={Tiktok} alt="Tiktok" className="w-4 h-4" />
              <span className="sr-only">Tiktok</span>
            </a>
            <a href="https://www.twitter.com/ourVideo" className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
              <img src={Twitter} alt="Twitter" className="w-4 h-4" />
              <span className="sr-only">Twitter</span>
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
