import { useState } from "react"

const useTheme = () => {
  const [isDark, setIsDark] = useState(false);

  return {
    isDark, setIsDark
  }
}

export default useTheme