import { handleForbidden } from '../navigation';
import { createApi } from './AxiosConfig';

const fetchApi = async ({
	method,
	url,
	params,
	data,
	responseType,
	content_type
}) => {
	try {
		const config = {
			method: method,
			url,
			ContentType: content_type,
			headers: {
				'Access-Control-Allow-Origin': '*',
			},
		};

		if (params) config.params = params;
		if (data) config.data = data;
		if (responseType) config.responseType = responseType;

		const response = await createApi(config);

		return response;
	} catch (error) {
		if (error?.response?.data === 'Forbidden' || error?.response?.status === 403) handleForbidden();
		if (error?.response?.data) return error.response.data;
	}
};

export default fetchApi;
