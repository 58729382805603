import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@nextui-org/react';

const CookieConsent = () => {
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		const checkConsent = async () => {
			const consent = localStorage.getItem('cookieConsent');
			if (consent !== 'accepted') {
				setTimeout(() => {
					setIsVisible(true);
				}, 2000);
			}
		};

		checkConsent();
	}, []);

	const handleAcceptAll = async () => {
		localStorage.setItem('cookieConsent', 'accepted');
		setIsVisible(false);
	};

	const handleClose = () => {
		setIsVisible(false);
	};

	return (
		<div
			className={`fixed z-50 bottom-2 md:left-4 md:bottom-6 transform -translate-x-1/2'
			} transform ${
				isVisible ? 'translate-y-0' : 'translate-y-full'
			} px-4 transition-transform duration-500 ease-in-out max-w-sm w-full md:mx-0 ${
				!isVisible && 'opacity-0 pointer-events-none'
			}`}>
			<div className='border shadow-md border-gray-100/30 p-4 bg-white dark:bg-gray-800 text-black dark:text-white font-inter rounded-xl'>
				<div className='w-full flex justify-between items-center'>
					<p className='text-md md:text-lg font-semibold text-purple-600 dark:text-purple-400'>
						Your Cookie Preferences
					</p>
					<button
						onClick={handleClose}
						className='text-gray-400 hover:text-gray-600 dark:hover:text-gray-200 hover:bg-slate-400/60 p-2 rounded-full transition-colors duration-200'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							className='h-6 w-6'
							fill='none'
							viewBox='0 0 24 24'
							stroke='currentColor'>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								strokeWidth='2'
								d='M6 18L18 6M6 6l12 12'
							/>
						</svg>
					</button>
				</div>
				<div className='flex flex-col gap-2 items-stretch mt-2'>
					<div className='flex-1'>
						<p className='my-1 text-xs md:text-sm text-black dark:text-gray-300'>
							By clicking “Accept all,” you agree to the storing of cookies on your device for functional,
							analytics, and advertising purposes.
						</p>
						<div className='flex text-center text-small mt-2 gap-4'>
							<Link
								to='/privacy'
								className='text-purple-600/85 hover:opacity-70 dark:text-purple-300 border-b border-purple-600 dark:border-purple-300'>
								Privacy policy
							</Link>
							<Link
								to='/cookies'
								style={{ textDecoration: 'none' }}
								className='text-purple-600/85 hover:opacity-70 dark:text-purple-300 border-b border-purple-600 dark:border-purple-300'>
								Cookie policy
							</Link>
						</div>
					</div>
					<div className='flex justify-center mt-4 w-full'>
						<Button
							onClick={handleAcceptAll}
							className='flex justify-center items-center text-center cursor-pointer px-4 py-2 border border-transparent leading-4 font-black bg-purple-600 text-white dark:bg-purple-500 rounded-md w-full'>
							<span>Accept all</span>
						</Button>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CookieConsent;
