import React, { useContext } from 'react';
import { Switch } from '@nextui-org/react';
import { FiSun, FiMoon } from 'react-icons/fi';
import { ThemeContext } from '../../hooks/ThemeContext';

export const ThemeSwitcher = () => {
	const { isDark, setIsDark } = useContext(ThemeContext);

	const handleChangeTheme = () => {
		if (isDark) {
			document.documentElement.classList.remove('dark');
			localStorage.setItem('theme', JSON.stringify('light'));
		} else {
			document.documentElement.classList.add('dark');
			localStorage.setItem('theme', JSON.stringify('dark'));
		}

		setIsDark(!isDark);
	};

	return (
		<div className='flex flex-col gap-2 items-center'>
			<Switch
				defaultSelected={JSON.stringify(localStorage.getItem('theme')) === 'dark' ? true : false}
				isSelected={isDark}
				onValueChange={handleChangeTheme}
				size='md'
				color='secondary'
				startContent={<FiSun />}
				endContent={<FiMoon />}></Switch>
		</div>
	);
};

export default ThemeSwitcher;
