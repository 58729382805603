import React, { useMemo, useState, useContext, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Slider from 'react-slick';
import useMeasure from 'react-use-measure';
import {
	useDragControls,
	useAnimate,
	motion,
	useMotionValue,
	useSpring,
	useTransform
} from 'framer-motion';
import { twMerge } from 'tailwind-merge';
import { Image } from '@nextui-org/react';
import { FiArrowRight, FiMail, FiLogIn, FiCreditCard, FiUser, FiVideo } from 'react-icons/fi';
import { SiInstagram, SiTiktok, SiTwitter, SiYoutube } from 'react-icons/si';

import { AdsIconColor, AvatarDefault } from '../../Assets/Image';
import {
	ProductGif1,
	ProductGif2,
	ProductGif3,
	ProductGif4,
	ProductVid1,
	ReelsVideo1,
	ReelsVideo2,
	ReelsVideo3,
} from '../../Assets/Image';
import { AuthContext } from '../../hooks/AuthContext';

import HomeScreen from '../HomeScreen/HomeScreen';
import FAQ from '../Faq/FAQ';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-multi-carousel/lib/styles.css';

const LandingScreen = () => {
	const [openRegister, setOpenRegister] = useState(false);
	const [openAccount, setOpenAccount] = useState(false);
	const [openCredits, setOpenCredits] = useState(false);
	const [openVideo, setOpenVideo] = useState(false);
	const navigate = useNavigate();

	const HoverImageLinks = ({ headingTextArr, subHeadingTextArr, navigateTo }) => {
		return (
			<section className="p-4 md:p-8">
				<div className="mx-auto max-w-5xl">
					<Link
						heading={headingTextArr[0]}
						subheading={subHeadingTextArr[0]}
						href={navigateTo}
					/>
					<Link
						heading={headingTextArr[1]}
						subheading={subHeadingTextArr[1]}
						href={navigateTo}
					/>
					<Link
						heading={headingTextArr[2]}
						subheading={subHeadingTextArr[2]}
						href={navigateTo}
					/>
					<Link
						heading={headingTextArr[3]}
						subheading={subHeadingTextArr[3]}
						href={navigateTo}
					/>
					<Link
						heading={headingTextArr[4]}
						subheading={subHeadingTextArr[4]}
						href={navigateTo}
					/>
				</div>
			</section>
		);
	};

	const Link = ({ heading, imgSrc, subheading, href }) => {
		const ref = useRef(null);

		const x = useMotionValue(0);
		const y = useMotionValue(0);

		const mouseXSpring = useSpring(x);
		const mouseYSpring = useSpring(y);

		const top = useTransform(mouseYSpring, [0.5, -0.5], ["40%", "60%"]);
		const left = useTransform(mouseXSpring, [0.5, -0.5], ["60%", "70%"]);

		const handleMouseMove = (e) => {
			const rect = ref.current.getBoundingClientRect();

			const width = rect.width;
			const height = rect.height;

			const mouseX = e.clientX - rect.left;
			const mouseY = e.clientY - rect.top;

			const xPct = mouseX / width - 0.5;
			const yPct = mouseY / height - 0.5;

			x.set(xPct);
			y.set(yPct);
		};

		return (
			<motion.a
				href={href}
				ref={ref}
				onMouseMove={handleMouseMove}
				initial="initial"
				whileHover="whileHover"
				className="group relative flex items-center justify-between border-b-2 border-neutral-700 py-4 transition-colors duration-500 hover:border-neutral-50 md:py-8"
			>
				<div>
					<motion.span
						variants={{
							initial: { x: 0 },
							whileHover: { x: -16 },
						}}
						transition={{
							type: "spring",
							staggerChildren: 0.075,
							delayChildren: 0.25,
						}}
						className="relative z-10 block text-4xl font-bold text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50 md:text-6xl"
					>
						{heading.split("  ").map((l, i) => (
							<motion.span
								variants={{
									initial: { x: 0 },
									whileHover: { x: 16 },
								}}
								transition={{ type: "spring" }}
								className="inline-block"
								key={i}
							>
								{l}
							</motion.span>
						))}
					</motion.span>
					<span className="relative z-10 mt-2 block text-base text-neutral-500 transition-colors duration-500 group-hover:text-neutral-50">
						{subheading}
					</span>
				</div>
				<motion.div
					variants={{
						initial: {
							x: "25%",
							opacity: 0,
						},
						whileHover: {
							x: "0%",
							opacity: 1,
						},
					}}
					transition={{ type: "spring" }}
					className="relative z-10 p-4"
				>
					<FiArrowRight className="text-xl text-neutral-50" />
				</motion.div>
			</motion.a>
		);
	};

	const DragCloseDrawerExample = () => {
		return (
			<>
				{/* Register Modal */}
				<DragCloseDrawer open={openRegister} setOpen={setOpenRegister}>
					<div className='mx-auto max-w-2xl space-y-4 text-neutral-400'>
						<HoverImageLinks
							headingTextArr={[
								'Create Your Unique Username',
								'Set Up Your Email Address',
								'Choose a Strong Password',
								'Verify Your Information',
								'Complete Registration'
							]}
							subHeadingTextArr={[
								'Pick a username that represents you and is easy to remember.',
								'Enter a valid email address that you have access to.',
								'Create a secure password using letters, numbers, and symbols.',
								'Double-check all the information you’ve provided.',
								'You’re almost there! Let’s finish up.'
							]}
							navigateTo={token ? '/' : 'login'}
						/>
					</div>
				</DragCloseDrawer>

				{/* Account Modal */}
				<DragCloseDrawer open={openAccount} setOpen={setOpenAccount}>
					<div className='mx-auto max-w-2xl space-y-4 text-neutral-400'>
						<HoverImageLinks
							headingTextArr={[
								'What’s Your Full Name?',
								'When’s Your Birthday?',
								'Where Are You From?',
								'Tell Us Your Gender',
								'What’s Your Phone Number?'
							]}
							subHeadingTextArr={[
								'Let us know your full name to personalize your experience.',
								'When were you born? We’ll make sure your age fits our requirements.',
								'Tell us where you’re from to customize your journey with us.',
								'How do you identify? Let us know your gender.',
								'A phone number helps us stay in touch with you easily.'
							]}
							navigateTo={token ? '/my-profile' : 'login'}
						/>
					</div>
				</DragCloseDrawer>

				{/* Credits Modal */}
				<DragCloseDrawer open={openCredits} setOpen={setOpenCredits}>
					<div className='mx-auto max-w-2xl space-y-4 text-neutral-400'>
						<HoverImageLinks
							headingTextArr={[
								'Choose Your Credit Package',
								'Or Customize Your Own Credit Amount',
								'Review Your Purchase Before Proceeding',
								'Get Ready to Complete Your Payment',
								'Congratulations, You’ve Got Your Credits!'
							]}
							subHeadingTextArr={[
								'Select a package that suits your needs and budget.',
								'Want a custom amount? Create your own credit package.',
								'Double-check your selection to avoid any mistakes.',
								'Have your payment details ready to finalize the purchase.',
								'Your credits are ready! Start using them right away.'
							]}
							navigateTo={token ? 'pricing' : 'login'}
						/>
					</div>
				</DragCloseDrawer>

				{/* Video Modal */}
				<DragCloseDrawer open={openVideo} setOpen={setOpenVideo}>
					<div className='mx-auto max-w-2xl space-y-4 text-neutral-400'>
						<HoverImageLinks
							headingTextArr={[
								'Ready to Join the Next Generation of Content Creators?',
								'Create Eye-Catching Ad Videos',
								'Craft Engaging Storytelling Videos for Reels',
								'Want AI-Generated Interactive Images?',
								'Congrats! Your Content is Ready to Go Live'
							]}
							subHeadingTextArr={[
								'Choose the perfect package to match your creative goals.',
								'Unleash your creativity and build a unique ad video with ease.',
								'Make your reels stand out with captivating stories and visuals.',
								'Let AI bring your images to life with just a few clicks.',
								'You’re all set! Share your video and make an impact.'
							]}
							navigateTo={token ? 'my-galleries' : 'login'}
						/>
					</div>
				</DragCloseDrawer>
			</>
		);
	};

	const DragCloseDrawer = ({ open, setOpen, children }) => {
		const [scope, animate] = useAnimate();
		const [drawerRef, { height }] = useMeasure();

		const y = useMotionValue(0);
		const controls = useDragControls();

		const handleClose = async () => {
			animate(scope.current, {
				opacity: [1, 0],
			});

			const yStart = typeof y.get() === 'number' ? y.get() : 0;

			await animate('#drawer', {
				y: [yStart, height],
			});

			setOpen(false);
		};

		return (
			<>
				{open && (
					<motion.div
						ref={scope}
						initial={{ opacity: 0 }}
						animate={{ opacity: 1 }}
						onClick={handleClose}
						className='fixed inset-0 z-50 bg-neutral-950/70'
					>
						<motion.div
							id='drawer'
							ref={drawerRef}
							onClick={(e) => e.stopPropagation()}
							initial={{ y: '100%' }}
							animate={{ y: '0%' }}
							transition={{
								ease: 'easeInOut',
							}}
							className='absolute bottom-0 h-[75vh] w-full overflow-hidden rounded-t-3xl bg-neutral-900'
							style={{ y }}
							drag='y'
							dragControls={controls}
							onDragEnd={() => {
								if (y.get() >= 100) {
									handleClose();
								}
							}}
							dragListener={false}
							dragConstraints={{
								top: 0,
								bottom: 0,
							}}
							dragElastic={{
								top: 0,
								bottom: 0.5,
							}}
						>
							<div className='absolute left-0 right-0 top-0 z-10 flex justify-center bg-neutral-900 p-4'>
								<button
									onPointerDown={(e) => {
										controls.start(e);
									}}
									className='h-2 w-14 cursor-grab touch-none rounded-full bg-neutral-700 active:cursor-grabbing'
								></button>
							</div>
							<div className='relative z-0 h-full overflow-y-scroll p-4 pt-12'>
								{children}
							</div>
						</motion.div>
					</motion.div>
				)}
			</>
		);
	};
	const HoverDevCards = () => {
		return (
			<div className="p-1 mb-10 ml-2 mr-2">
				<div className="grid gap-4 grid-cols-2 lg:grid-cols-4">
					<Card
						title='Register'
						subtitle='Create and login'
						Icon={FiMail}
						onClick={() => setOpenRegister(true)}
					/>
					<Card
						title='Account'
						subtitle='Manage profile'
						Icon={FiUser}
						onClick={() => setOpenAccount(true)}
					/>
					<Card
						title='Billing'
						subtitle='Manage credits'
						Icon={FiCreditCard}
						onClick={() => setOpenCredits(true)}
					/>
					<Card
						title='Video'
						subtitle='Create Video'
						Icon={FiVideo}
						onClick={() => setOpenVideo(true)}
					/>
				</div>
			</div>
		);
	};

	const Card = ({ title, subtitle, Icon, onClick }) => {
		return (
			<button
				onClick={onClick}
				className="w-full p-4 rounded border-[1px] border-slate-300 relative overflow-hidden group dark:border-gray-700"
			>
				<div className="absolute inset-0 bg-gradient-to-r from-violet-600 to-indigo-600 translate-y-[100%] group-hover:translate-y-[0%] transition-transform duration-300" />

				<Icon className="absolute z-10 -top-12 -right-12 text-9xl text-slate-100 dark:text-gray-600 group-hover:text-violet-400 group-hover:rotate-12 transition-transform duration-300" />
				<Icon className="mb-2 text-2xl text-violet-600 group-hover:text-white transition-colors relative z-10 duration-300" />
				<h3 className="font-medium text-lg text-slate-950 dark:text-gray-300 group-hover:text-white relative z-10 duration-300">
					{title}
				</h3>
				<p className="text-slate-400 group-hover:text-violet-200 relative z-10 duration-300">
					{subtitle}
				</p>
			</button>
		);
	};

	const RevealBento = () => {
		return (
			<div className="min-h-screen py-12">
				{/* <Logo /> */}
				<motion.div
					initial="initial"
					animate="animate"
					transition={{
						staggerChildren: 0.05,
					}}
					className="mx-auto grid max-w-4xl grid-flow-dense grid-cols-12 gap-4"
				>
					<HeaderBlock />
					<SocialsBlock />
					<AboutBlock />
					<LocationBlock />
					<EmailListBlock />
				</motion.div>
				{/* <Footer /> */}
			</div>
		);
	};

	const Block = ({ className, ...rest }) => {
		return (
			<motion.div
				variants={{
					initial: {
						scale: 0.5,
						y: 50,
						opacity: 0,
					},
					animate: {
						scale: 1,
						y: 0,
						opacity: 1,
					},
				}}
				transition={{
					type: "spring",
					mass: 3,
					stiffness: 400,
					damping: 50,
				}}
				className={twMerge(
					"col-span-4 rounded-lg border border-gray-200 dark:border-gray-700 p-6",
					className
				)}
				{...rest}
			/>
		);
	};

	const HeaderBlock = () => (
		<Block className="col-span-12 row-span-2 md:col-span-6">
			<Image
				src={AdsIconColor}
				alt="ouvee logo"
				className="mb-4 rounded-none object-contain w-10 md:w-20"
			/>
			<h1 className="mb-12 text-medium font-medium leading-loose">
				Hi, I'm Ouvee.{" "}
				<span className="text-zinc-400">
					I build content easily for you
				</span>
			</h1>
			<a
				href="contact"
				className="flex items-center gap-1 text-red-300 hover:underline"
			>
				Contact me <FiArrowRight />
			</a>
		</Block>
	);

	const SocialsBlock = () => (
		<>
			<Block
				whileHover={{
					rotate: "2.5deg",
					scale: 1.1,
				}}
				className="col-span-6 bg-red-500 md:col-span-3"
			>
				<a
					href="#"
					className="grid h-full place-content-center text-3xl text-white"
				>
					<SiYoutube />
				</a>
			</Block>
			<Block
				whileHover={{
					rotate: "-2.5deg",
					scale: 1.1,
				}}
				className="col-span-6 bg-green-600 md:col-span-3"
			>
				<a
					href="#"
					className="grid h-full place-content-center text-3xl text-white"
				>
					<SiInstagram />
				</a>
			</Block>
			<Block
				whileHover={{
					rotate: "-2.5deg",
					scale: 1.1,
				}}
				className="col-span-6 bg-zinc-50 md:col-span-3"
			>
				<a
					href="#"
					className="grid h-full place-content-center text-3xl text-black"
				>
					<SiTiktok />
				</a>
			</Block>
			<Block
				whileHover={{
					rotate: "2.5deg",
					scale: 1.1,
				}}
				className="col-span-6 bg-blue-500 md:col-span-3"
			>
				<a
					href="#"
					className="grid h-full place-content-center text-3xl text-white"
				>
					<SiTwitter />
				</a>
			</Block>
		</>
	);

	const AboutBlock = () => (
		<Block className="col-span-12 leading-snug">
			<h2 className='text-md font-bold tracking-tight dark:text-white text-gray-900 sm:text-xl mt-4'>
				Powered Features
			</h2>
			<p className='mt-3 text-gray-500 text-small'>
				Our AI tools make it super easy to create amazing content. Whether you're making
				eye-catching videos or stunning images, our technology does the heavy lifting so you can
				focus on what matters most.
			</p>
			<dl className='mt-8 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 sm:gap-y-16 lg:gap-x-8'>
				<div className='border-t border-gray-200 pt-4'>
					<dt className='font-medium text-gray-900 dark:text-gray-300'>Service</dt>
					<dd className='mt-2 text-sm text-gray-500'>
						Video Ads, Reels Story Video, and Special Image Generated
					</dd>
				</div>
				<div className='border-t border-gray-200 pt-4'>
					<dt className='font-medium text-gray-900 dark:text-gray-300'>Platform Audience</dt>
					<dd className='mt-2 text-sm text-gray-500'>
						TikTok, Instagram, Facebook Reels, Youtube Reels
					</dd>
				</div>
				<div className='border-t border-gray-200 pt-4'>
					<dt className='font-medium text-gray-900 dark:text-gray-300'>Output Formats</dt>
					<dd className='mt-2 text-sm text-gray-500'>
						Various formats suitable for different social media platforms
					</dd>
				</div>
				<div className='border-t border-gray-200 pt-4'>
					<dt className='font-medium text-gray-900 dark:text-gray-300'>
						Additional Services
					</dt>
					<dd className='mt-2 text-sm text-gray-500'>
						Image generation, Subtitle addition, Multilingual voice dubbing
					</dd>
				</div>
			</dl>
		</Block>
	);

	const LocationBlock = () => (
		<Block className="col-span-12 flex flex-col items-center gap-4 md:col-span-3">
			<FiLogIn className="animate-bounce text-3xl hover:cursor-pointer" onClick={() => navigate(token ? 'pricing' : 'login')}/>
			<p className="text-center text-small text-zinc-400">Join Us</p>
		</Block>
	);

	const EmailListBlock = () => (
		<Block className="col-span-12 md:col-span-9">
			<p className="mb-3 text-small">Subscribe for more information</p>
			<form
				onSubmit={(e) => e.preventDefault()}
				className="flex items-center gap-2"
			>
				<input
					type="email"
					disabled={true}
					placeholder={email ? email : 'Please Login First'}
					className="w-full rounded border border-gray-200 dark:border-gray-700 px-3 py-1.5 transition-colors focus:border-red-300 focus:outline-0"
				/>
				<button
					type="submit"
					onClick={() =>
						setTimeout(() => {
							if (token) toast.success('You Have Subscribed')
							else toast.warn('Please Login First')
						}, 500)}
					className="flex items-center gap-2 whitespace-nowrap rounded bg-zinc-50 px-3 py-2 text-sm font-medium text-zinc-900 transition-colors hover:bg-zinc-300"
				>
					<FiMail /> Join the list
				</button>
			</form>
		</Block>
	);

	const Logo = () => {
		// Temp logo from https://logoipsum.com/
		return (
			<svg
				width="40"
				height="auto"
				viewBox="0 0 50 39"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
				className="mx-auto mb-12 fill-zinc-50"
			>
				<path
					d="M16.4992 2H37.5808L22.0816 24.9729H1L16.4992 2Z"
					stopColor="#000000"
				></path>
				<path
					d="M17.4224 27.102L11.4192 36H33.5008L49 13.0271H32.7024L23.2064 27.102H17.4224Z"
					stopColor="#000000"
				></path>
			</svg>
		);
	};

	const Footer = () => {
		return (
			<footer className="mt-12">
				<p className="text-center text-zinc-400">
					Made with ❤️ by{" "}
					<a href="#" className="text-red-300 hover:underline">
						@tomisloading
					</a>
				</p>
			</footer>
		);
	};

	const { token, email } = useContext(AuthContext);

	const settings = {
		dots: false,
		infinite: true,
		speed: 1000,
		slidesToShow: 4,
		slidesToScroll: 1,
		autoplay: true,
		autoplaySpeed: 3000,
		cssEase: 'linear',
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 4,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 3,
				},
			},
		],
	};

	return (
		<div className='h-full'>
			<ToastContainer />
			<HomeScreen />
			{/* <div className='w-full max-w-[1200px] mx-auto'>
				<hr className='mr-4 ml-4' />
			</div> */}
			<DragCloseDrawerExample />
			<div className='mt-[12px] flex flex-col items-center justify-center'>
				<div className='w-full max-w-[1200px] mx-auto'>
					<div className='mx-auto max-w-2xl items-center gap-x-8 px-4 sm:px-6 sm:py-12 lg:max-w-7xl lg:px-8 flex lg:flex-row flex-col'>
						<div className='flex-[3]'>
							{/* <div className='mt-5'>
								<h1 className='mb-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 sm:text-3xl md:text-4xl dark:text-white'>
									Explore{' '}
									<mark className='px-2 text-white bg-purple-600 rounded dark:bg-purple-500'>
										The Products
									</mark>{' '}
									Now!
								</h1>
								<p className='text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400'>
									Create content easily and quickly with just a few clicks.
								</p>
							</div> */}
							<RevealBento />
						</div>
						<div className='flex-[2] grid grid-cols-2 gap-1 sm:gap-6'>
							<img
								src={ProductGif1}
								alt='Walnut card tray with white powder coated steel divider and 3 punch out holes.'
								className='rounded-lg bg-gray-100 w-full aspect-hp'
							/>
							<img
								src={ProductGif2}
								alt='Top down view of walnut card tray with embedded magnets and card groove.'
								className='rounded-lg bg-gray-100 w-full aspect-hp'
							/>
							<img
								src={ProductGif3}
								alt='Side of walnut card tray with card groove and recessed card area.'
								className='rounded-lg bg-gray-100 w-full aspect-hp'
							/>
							<img
								src={ProductGif4}
								alt='Walnut card tray filled with cards and card angled in dedicated groove.'
								className='rounded-lg bg-gray-100 w-full aspect-hp'
							/>
						</div>
					</div>

					<div className='w-full max-w-[1200px] mx-auto mt-12'>
						<hr className='mr-4 ml-4' />
					</div>

					{/* Content Galleries */}
					<section>
						<div className='mb-7 mr-4 ml-4'>
							<h1 className='mb-4 mt-5 flex items-center text-2xl font-extrabold dark:text-white sm:text-3xl md:text-4xl'>
								Content
								<mark className='px-2 text-white bg-purple-600 rounded dark:bg-purple-500 ml-2'>
									Gallery
								</mark>
							</h1>
							<p className='text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400'>
								Check out these amazing content samples we've created for you.
							</p>
						</div>
						<div className='container mx-auto mt-4 mb-4 pb-10'>
							<Slider {...settings} arrows={false}>
								<div className='px-2'>
									<img
										src={ProductVid1}
										className='w-full h-auto object-cover rounded-md shadow-md'
									/>
								</div>
								<div className='px-2'>
									<img
										src={ReelsVideo1}
										className='w-full h-auto object-cover rounded-md shadow-md'
									/>
								</div>
								<div className='px-2'>
									<img
										src={ReelsVideo2}
										className='w-full h-auto object-cover rounded-md shadow-md'
									/>
								</div>
								<div className='px-2'>
									<img
										src={ReelsVideo3}
										className='w-full h-auto object-cover rounded-md shadow-md'
									/>
								</div>
								{/* ))} */}
							</Slider>
						</div>
					</section>
				</div>

			</div>

			<section className='w-full max-w-[1200px] mx-auto'>
				<hr className='mr-4 ml-4' />
				<div className='mt-20 mr-4 ml-4'>
					<h1 className='mb-4 text-2xl sm:text-3xl md:text-4xl font-extrabold leading-none tracking-tight text-gray-900 dark:text-white'>
						Find <mark className='px-2 text-white bg-purple-600 rounded dark:bg-purple-500'>Answers</mark>{' '}
						You Need
					</h1>
					<p className='text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400'>
						Find answers to your most common questions and learn more about our services.
					</p>
				</div>
				<FAQ />
			</section>
			<section className='w-full max-w-[1200px] mx-auto'>
				<div>
					<HoverDevCards />
				</div>
				<section className='mb-20'>
					<hr className='mr-4 ml-4' />
					<div className='md:gap-16 mr-4 ml-4 pt-6 pb-10'>
						<h1 className='mb-4 mt-10 flex items-center text-2xl font-extrabold dark:text-white sm:text-3xl md:text-4xl'>
							Need More
							<mark className='px-2 text-white bg-purple-600 rounded dark:bg-purple-500 ml-2'>
								Information?
							</mark>
						</h1>
						<div className='grid pt-8 text-left md:grid-cols-2 gap-6'>
							<div className='mb-1'>
								<h3 className='flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white'>
									<svg
										className='flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400'
										fill='currentColor'
										viewBox='0 0 20 20'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
											clipRule='evenodd'></path>
									</svg>
									What is "Ads Product Video"?
								</h3>
								<p className='text-gray-500 dark:text-gray-400'>
									Create AI-powered ad videos with customizable language, engaging subtitles, and
									captivating voiceovers.
								</p>
							</div>
							<div className='mb-1'>
								<h3 className='flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white'>
									<svg
										className='flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400'
										fill='currentColor'
										viewBox='0 0 20 20'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
											clipRule='evenodd'></path>
									</svg>
									How does "Reels Story Teller Video" work?
								</h3>
								<p className='text-gray-500 dark:text-gray-400'>
									Create story videos for Reels or TikTok with custom language, visuals,
									subtitles, and voiceovers.
								</p>
							</div>
							<div className='mb-1'>
								<h3 className='flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white'>
									<svg
										className='flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400'
										fill='currentColor'
										viewBox='0 0 20 20'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
											clipRule='evenodd'></path>
									</svg>
									What can I do with "Generate Image"?
								</h3>
								<p className='text-gray-500 dark:text-gray-400'>
									Create high-quality images with AI, choosing any theme you want.
								</p>
							</div>
							<div className='mb-1'>
								<h3 className='flex items-center mb-4 text-lg font-medium text-gray-900 dark:text-white'>
									<svg
										className='flex-shrink-0 mr-2 w-5 h-5 text-gray-500 dark:text-gray-400'
										fill='currentColor'
										viewBox='0 0 20 20'
										xmlns='http://www.w3.org/2000/svg'>
										<path
											fillRule='evenodd'
											d='M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z'
											clipRule='evenodd'></path>
									</svg>
									What packages do you offer?
								</h3>
								<p className='text-gray-500 dark:text-gray-400'>
									We offer Basic, Pro, and Enterprise packages tailored to different needs, each
									providing access to a set number of ad videos, story videos, images, subtitles,
									and dubbing per month.
								</p>
							</div>
						</div>
					</div>
				</section>
			</section>
		</div>
	);
}

export default LandingScreen;
