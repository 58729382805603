import React, { lazy } from 'react';
import { Route } from 'react-router-dom';

import ProtectedRoute from '../ProtectedRoute/ProtectedRoute';
const AdminScreen = lazy(() => import('../../Screens/AdminScreen/AdminScreen'));
const AdminTabProduct = lazy(() =>
  import('../../Screens/AdminProduct/AdminTabProduct')
);

const AdminRoutes = () => {
  return (
    <>
      <Route
        path='/admin-edit-user'
        element={<ProtectedRoute element={<AdminScreen />} isAdmin={true} />}
      />
      <Route
        path='/admin-edit-product'
        element={
          <ProtectedRoute element={<AdminTabProduct />} isAdmin={true} />
        }
      />
    </>
  );
};

export default AdminRoutes;
