const CategoryProduct = [
	{
		type: '🚗 Car',
		categoryDetail: [
			{
				value: 'Sales Year',
				label: 'Sales Year',
				placeHolder: '🚗 Year of production (e.g., 2016)',
				isRequired: false,
			},
			{ value: 'Car Brand', label: 'Car Brand', placeHolder: '🚗 Car Brand (e.g., Toyota)', isRequired: false },
			{ value: 'Car Model', label: 'Car Model', placeHolder: '🚗 Car Model (e.g., Camry)', isRequired: false },
			{ value: 'Car Type', label: 'Car Type', placeHolder: '🚗 Car Type (e.g., Sedan)', isRequired: false },
			{ value: 'Car Price', label: 'Car Price', placeHolder: '💵 Car Price (e.g., $20,000)', isRequired: false },
			{ value: 'Car Color', label: 'Car Color', placeHolder: '🎨 Car Color (e.g., Red)', isRequired: false },
			{ value: 'Mileage', label: 'Mileage', placeHolder: '🔢 Mileage (e.g., 50,000 miles)', isRequired: false },
			{
				value: 'Transmission',
				label: 'Transmission',
				placeHolder: '⚙️ Transmission (e.g., Automatic)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Petrol)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., New York)',
				isRequired: false,
			},
			{
				value: 'Car Condition',
				label: 'Car Condition',
				placeHolder: '👌 Car Condition (e.g., New)',
				isRequired: false,
			},
			{
				value: 'Previous Owners',
				label: 'Previous Owners',
				placeHolder: '👥 Previous Owners (e.g., 1)',
				isRequired: false,
			},
			{ value: 'Engine Type', label: 'Engine Type', placeHolder: '🔧 Engine Type (e.g., V6)', isRequired: false },
			{
				value: 'Additional Features',
				label: 'Additional Features',
				placeHolder: '✨ Additional Features (e.g., Sunroof)',
				isRequired: false,
			},
			{
				value: 'Insurance',
				label: 'Insurance',
				placeHolder: '🛡️ Insurance (e.g., Full coverage)',
				isRequired: false,
			},
			{
				value: 'Service History',
				label: 'Service History',
				placeHolder: '📚 Service History (e.g., Regular)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 3 years)', isRequired: false },
			{
				value: 'Engine Size',
				label: 'Engine Size',
				placeHolder: '🔩 Engine Size (e.g., 2.5L)',
				isRequired: false,
			},
			{ value: 'Emissions', label: 'Emissions', placeHolder: '🌿 Emissions (e.g., Low)', isRequired: false },
		],
	},
	{
		type: '🏍️ Motorbike',
		categoryDetail: [
			{
				value: 'Sales Year',
				label: 'Sales Year',
				placeHolder: '🏍️ Year of production (e.g., 2016)',
				isRequired: false,
			},
			{
				value: 'Motor Brand',
				label: 'Motor Brand',
				placeHolder: '🏍️ Motor Brand (e.g., Yamaha)',
				isRequired: false,
			},
			{
				value: 'Motor Model',
				label: 'Motor Model',
				placeHolder: '🏍️ Motor Model (e.g., YZF-R1)',
				isRequired: false,
			},
			{ value: 'Motor Type', label: 'Motor Type', placeHolder: '🏍️ Motor Type (e.g., Sport)', isRequired: false },
			{
				value: 'Motor Price',
				label: 'Motor Price',
				placeHolder: '💵 Motor Price (e.g., $10,000)',
				isRequired: false,
			},
			{
				value: 'Motor Color',
				label: 'Motor Color',
				placeHolder: '🎨 Motor Color (e.g., Black)',
				isRequired: false,
			},
			{ value: 'Mileage', label: 'Mileage', placeHolder: '🔢 Mileage (e.g., 20,000 miles)', isRequired: false },
			{
				value: 'Transmission',
				label: 'Transmission',
				placeHolder: '⚙️ Transmission (e.g., Manual)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Petrol)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Los Angeles)',
				isRequired: false,
			},
			{
				value: 'Motor Condition',
				label: 'Motor Condition',
				placeHolder: '👌 Motor Condition (e.g., New)',
				isRequired: false,
			},
			{
				value: 'Previous Owners',
				label: 'Previous Owners',
				placeHolder: '👥 Previous Owners (e.g., 1)',
				isRequired: false,
			},
			{
				value: 'Engine Type',
				label: 'Engine Type',
				placeHolder: '🔧 Engine Type (e.g., Inline-4)',
				isRequired: false,
			},
			{
				value: 'Additional Features',
				label: 'Additional Features',
				placeHolder: '✨ Additional Features (e.g., ABS)',
				isRequired: false,
			},
			{
				value: 'Insurance',
				label: 'Insurance',
				placeHolder: '🛡️ Insurance (e.g., Full coverage)',
				isRequired: false,
			},
			{
				value: 'Service History',
				label: 'Service History',
				placeHolder: '📚 Service History (e.g., Regular)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{
				value: 'Engine Size',
				label: 'Engine Size',
				placeHolder: '🔩 Engine Size (e.g., 1,000cc)',
				isRequired: false,
			},
			{ value: 'Emissions', label: 'Emissions', placeHolder: '🌿 Emissions (e.g., Low)', isRequired: false },
		],
	},
	{
		type: '🏠 House',
		categoryDetail: [
			{ value: 'Location', label: 'Location', placeHolder: '📍 Location (e.g., Miami)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $500,000)', isRequired: false },
			{
				value: 'Land Area',
				label: 'Land Area',
				placeHolder: '📐 Land Area (e.g., 5000 sqft)',
				isRequired: false,
			},
			{
				value: 'Building Area',
				label: 'Building Area',
				placeHolder: '🏠 Building Area (e.g., 3000 sqft)',
				isRequired: false,
			},
			{
				value: 'Number of Bedrooms',
				label: 'Number of Bedrooms',
				placeHolder: '🛏️ Number of Bedrooms (e.g., 4)',
				isRequired: false,
			},
			{
				value: 'Number of Bathrooms',
				label: 'Number of Bathrooms',
				placeHolder: '🛁 Number of Bathrooms (e.g., 3)',
				isRequired: false,
			},
			{ value: 'Year Built', label: 'Year Built', placeHolder: '🏗️ Year Built (e.g., 2010)', isRequired: false },
			{
				value: 'Certificate',
				label: 'Certificate',
				placeHolder: '📜 Certificate (e.g., Freehold)',
				isRequired: false,
			},
			{
				value: 'Facilities',
				label: 'Facilities',
				placeHolder: '🏊 Facilities (e.g., Pool, Gym)',
				isRequired: false,
			},
			{
				value: 'Building Levels',
				label: 'Building Levels',
				placeHolder: '🏢 Building Levels (e.g., 2)',
				isRequired: false,
			},
			{
				value: 'Property Type',
				label: 'Property Type',
				placeHolder: '🏠 Property Type (e.g., Detached)',
				isRequired: false,
			},
			{
				value: 'Property Condition',
				label: 'Property Condition',
				placeHolder: '👌 Property Condition (e.g., Excellent)',
				isRequired: false,
			},
			{
				value: 'Neighborhood',
				label: 'Neighborhood',
				placeHolder: '🏡 Neighborhood (e.g., Quiet)',
				isRequired: false,
			},
			{
				value: 'Transportation Access',
				label: 'Transportation Access',
				placeHolder: '🚌 Transportation Access (e.g., Near Bus Stop)',
				isRequired: false,
			},
			{ value: 'Security', label: 'Security', placeHolder: '🔒 Security (e.g., Gated)', isRequired: false },
		],
	},
	{
		type: '🏢 Apartment',
		categoryDetail: [
			{ value: 'Location', label: 'Location', placeHolder: '📍 Location (e.g., New York)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $300,000)', isRequired: false },
			{ value: 'Area', label: 'Area', placeHolder: '📐 Area (e.g., 1200 sqft)', isRequired: false },
			{
				value: 'Number of Bedrooms',
				label: 'Number of Bedrooms',
				placeHolder: '🛏️ Number of Bedrooms (e.g., 2)',
				isRequired: false,
			},
			{
				value: 'Number of Bathrooms',
				label: 'Number of Bathrooms',
				placeHolder: '🛁 Number of Bathrooms (e.g., 2)',
				isRequired: false,
			},
			{ value: 'Floor', label: 'Floor', placeHolder: '🏢 Floor (e.g., 10th floor)', isRequired: false },
			{
				value: 'Facilities',
				label: 'Facilities',
				placeHolder: '🏊 Facilities (e.g., Gym, Pool)',
				isRequired: false,
			},
			{ value: 'Year Built', label: 'Year Built', placeHolder: '🏗️ Year Built (e.g., 2015)', isRequired: false },
			{
				value: 'Certificate',
				label: 'Certificate',
				placeHolder: '📜 Certificate (e.g., Leasehold)',
				isRequired: false,
			},
			{
				value: 'Apartment Condition',
				label: 'Apartment Condition',
				placeHolder: '👌 Apartment Condition (e.g., New)',
				isRequired: false,
			},
			{
				value: 'Property Type',
				label: 'Property Type',
				placeHolder: '🏢 Property Type (e.g., Condo)',
				isRequired: false,
			},
			{
				value: 'Neighborhood',
				label: 'Neighborhood',
				placeHolder: '🏡 Neighborhood (e.g., Downtown)',
				isRequired: false,
			},
			{
				value: 'Transportation Access',
				label: 'Transportation Access',
				placeHolder: '🚇 Transportation Access (e.g., Near Subway)',
				isRequired: false,
			},
			{ value: 'Security', label: 'Security', placeHolder: '🔒 Security (e.g., 24/7)', isRequired: false },
		],
	},
	{
		type: '🛡️ Assurance',
		categoryDetail: [
			{
				value: 'Insurance Type',
				label: 'Insurance Type',
				placeHolder: '🛡️ Insurance Type (e.g., Health)',
				isRequired: false,
			},
			{
				value: 'Company Name',
				label: 'Company Name',
				placeHolder: '🏢 Company Name (e.g., Aetna)',
				isRequired: false,
			},
			{
				value: 'Premium Price',
				label: 'Premium Price',
				placeHolder: '💵 Premium Price (e.g., $200/month)',
				isRequired: false,
			},
			{
				value: 'Coverage Period',
				label: 'Coverage Period',
				placeHolder: '📅 Coverage Period (e.g., 1 year)',
				isRequired: false,
			},
			{
				value: 'Benefits',
				label: 'Benefits',
				placeHolder: '✨ Benefits (e.g., Dental, Vision)',
				isRequired: false,
			},
			{
				value: 'Terms and Conditions',
				label: 'Terms and Conditions',
				placeHolder: '📜 Terms and Conditions',
				isRequired: false,
			},
			{ value: 'Coverage', label: 'Coverage', placeHolder: '🌍 Coverage (e.g., Worldwide)', isRequired: false },
			{
				value: 'Company Contact',
				label: 'Company Contact',
				placeHolder: '📞 Company Contact (e.g., 123-456-7890)',
				isRequired: false,
			},
		],
	},
	{
		type: '📱 Electronics',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '📱 Brand (e.g., Apple)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '📱 Model (e.g., iPhone 12)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $799)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '🔧 Specifications (e.g., 128GB, 4GB RAM)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Face ID)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{
				value: 'Release Year',
				label: 'Release Year',
				placeHolder: '📅 Release Year (e.g., 2020)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
		],
	},
	{
		type: '👗 Clothing',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '👗 Brand (e.g., Zara)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '👗 Type (e.g., Dress)', isRequired: false },
			{ value: 'Size', label: 'Size', placeHolder: '📏 Size (e.g., M)', isRequired: false },
			{ value: 'Color', label: 'Color', placeHolder: '🎨 Color (e.g., Blue)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🧵 Material (e.g., Cotton)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $50)', isRequired: false },
			{ value: 'Gender', label: 'Gender', placeHolder: '🚹 Gender (e.g., Female)', isRequired: false },
			{ value: 'Style', label: 'Style', placeHolder: '✨ Style (e.g., Casual)', isRequired: false },
			{ value: 'Occasion', label: 'Occasion', placeHolder: '🎉 Occasion (e.g., Party)', isRequired: false },
			{ value: 'Season', label: 'Season', placeHolder: '🌸 Season (e.g., Summer)', isRequired: false },
			{ value: 'Pattern', label: 'Pattern', placeHolder: '🎨 Pattern (e.g., Floral)', isRequired: false },
			{
				value: 'Care Instructions',
				label: 'Care Instructions',
				placeHolder: '🧼 Care Instructions (e.g., Machine wash)',
				isRequired: false,
			},
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4 stars)', isRequired: false },
		],
	},
	{
		type: '👟 Shoes',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '👟 Brand (e.g., Nike)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '👟 Type (e.g., Sneakers)', isRequired: false },
			{ value: 'Size', label: 'Size', placeHolder: '📏 Size (e.g., 10)', isRequired: false },
			{ value: 'Color', label: 'Color', placeHolder: '🎨 Color (e.g., White)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🧵 Material (e.g., Leather)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $100)', isRequired: false },
			{ value: 'Gender', label: 'Gender', placeHolder: '🚹 Gender (e.g., Male)', isRequired: false },
			{ value: 'Style', label: 'Style', placeHolder: '✨ Style (e.g., Sporty)', isRequired: false },
			{ value: 'Occasion', label: 'Occasion', placeHolder: '🎉 Occasion (e.g., Running)', isRequired: false },
			{ value: 'Season', label: 'Season', placeHolder: '🌸 Season (e.g., All seasons)', isRequired: false },
			{ value: 'Pattern', label: 'Pattern', placeHolder: '🎨 Pattern (e.g., Solid)', isRequired: false },
			{
				value: 'Care Instructions',
				label: 'Care Instructions',
				placeHolder: '🧼 Care Instructions (e.g., Wipe clean)',
				isRequired: false,
			},
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
		],
	},
	{
		type: '📚 Books',
		categoryDetail: [
			{ value: 'Title', label: 'Title', placeHolder: '📚 Title (e.g., The Great Gatsby)', isRequired: false },
			{
				value: 'Author',
				label: 'Author',
				placeHolder: '🖋️ Author (e.g., F. Scott Fitzgerald)',
				isRequired: false,
			},
			{ value: 'Publisher', label: 'Publisher', placeHolder: '🏢 Publisher (e.g., Scribner)', isRequired: false },
			{ value: 'ISBN', label: 'ISBN', placeHolder: '🔢 ISBN (e.g., 978-0743273565)', isRequired: false },
			{ value: 'Genre', label: 'Genre', placeHolder: '📚 Genre (e.g., Fiction)', isRequired: false },
			{ value: 'Language', label: 'Language', placeHolder: '🗣️ Language (e.g., English)', isRequired: false },
			{ value: 'Pages', label: 'Pages', placeHolder: '📄 Pages (e.g., 180)', isRequired: false },
			{ value: 'Format', label: 'Format', placeHolder: '📦 Format (e.g., Hardcover)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $15)', isRequired: false },
			{
				value: 'Publication Date',
				label: 'Publication Date',
				placeHolder: '📅 Publication Date (e.g., 1925)',
				isRequired: false,
			},
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🎥 Movies',
		categoryDetail: [
			{ value: 'Title', label: 'Title', placeHolder: '🎥 Title (e.g., Inception)', isRequired: false },
			{
				value: 'Director',
				label: 'Director',
				placeHolder: '🎬 Director (e.g., Christopher Nolan)',
				isRequired: false,
			},
			{ value: 'Producer', label: 'Producer', placeHolder: '🎬 Producer (e.g., Emma Thomas)', isRequired: false },
			{ value: 'Cast', label: 'Cast', placeHolder: '🎭 Cast (e.g., Leonardo DiCaprio)', isRequired: false },
			{ value: 'Genre', label: 'Genre', placeHolder: '📚 Genre (e.g., Sci-Fi)', isRequired: false },
			{ value: 'Language', label: 'Language', placeHolder: '🗣️ Language (e.g., English)', isRequired: false },
			{
				value: 'Running Time',
				label: 'Running Time',
				placeHolder: '🕒 Running Time (e.g., 148 mins)',
				isRequired: false,
			},
			{
				value: 'Release Date',
				label: 'Release Date',
				placeHolder: '📅 Release Date (e.g., 2010)',
				isRequired: false,
			},
			{ value: 'Format', label: 'Format', placeHolder: '📦 Format (e.g., Blu-ray)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $20)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🎶 Music',
		categoryDetail: [
			{ value: 'Title', label: 'Title', placeHolder: '🎶 Title (e.g., Thriller)', isRequired: false },
			{ value: 'Artist', label: 'Artist', placeHolder: '🎤 Artist (e.g., Michael Jackson)', isRequired: false },
			{ value: 'Genre', label: 'Genre', placeHolder: '📚 Genre (e.g., Pop)', isRequired: false },
			{ value: 'Label', label: 'Label', placeHolder: '🏢 Label (e.g., Epic)', isRequired: false },
			{
				value: 'Release Date',
				label: 'Release Date',
				placeHolder: '📅 Release Date (e.g., 1982)',
				isRequired: false,
			},
			{ value: 'Format', label: 'Format', placeHolder: '📦 Format (e.g., Vinyl)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $25)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.9 stars)', isRequired: false },
		],
	},
	{
		type: '🍽️ Kitchen Appliances',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🍽️ Brand (e.g., KitchenAid)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🍽️ Model (e.g., KSM150PS)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $300)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '🔧 Specifications (e.g., 325 watts)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., 10 speeds)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🛏️ Furniture',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🛏️ Type (e.g., Sofa)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🧵 Material (e.g., Leather)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🛋️ Brand (e.g., IKEA)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $500)', isRequired: false },
			{
				value: 'Dimensions',
				label: 'Dimensions',
				placeHolder: '📏 Dimensions (e.g., 80 x 35 x 30 inches)',
				isRequired: false,
			},
			{ value: 'Color', label: 'Color', placeHolder: '🎨 Color (e.g., Grey)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 5 years)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
		],
	},
	{
		type: '📺 Television',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '📺 Brand (e.g., Samsung)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '📺 Model (e.g., QN55Q60RAFXZA)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $600)', isRequired: false },
			{
				value: 'Screen Size',
				label: 'Screen Size',
				placeHolder: '📏 Screen Size (e.g., 55 inches)',
				isRequired: false,
			},
			{
				value: 'Resolution',
				label: 'Resolution',
				placeHolder: '🔧 Resolution (e.g., 4K UHD)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Smart TV)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '⌚ Watches',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '⌚ Brand (e.g., Rolex)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '⌚ Model (e.g., Submariner)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $8,000)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Water-resistant)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🛠️ Tools',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🛠️ Type (e.g., Drill)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🔧 Brand (e.g., DeWalt)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🔧 Model (e.g., DCD771C2)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $100)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '🔧 Specifications (e.g., 20V MAX)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 3 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🚲 Bicycles',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🚲 Brand (e.g., Trek)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚲 Model (e.g., FX 3 Disc)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $800)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '🚲 Type (e.g., Hybrid)', isRequired: false },
			{
				value: 'Frame Material',
				label: 'Frame Material',
				placeHolder: '🔧 Frame Material (e.g., Aluminum)',
				isRequired: false,
			},
			{ value: 'Wheel Size', label: 'Wheel Size', placeHolder: '🔧 Wheel Size (e.g., 700c)', isRequired: false },
			{ value: 'Color', label: 'Color', placeHolder: '🎨 Color (e.g., Black)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🚚 Trucks',
		categoryDetail: [
			{
				value: 'Sales Year',
				label: 'Sales Year',
				placeHolder: '🚚 Year of production (e.g., 2016)',
				isRequired: false,
			},
			{
				value: 'Truck Brand',
				label: 'Truck Brand',
				placeHolder: '🚚 Truck Brand (e.g., Ford)',
				isRequired: false,
			},
			{
				value: 'Truck Model',
				label: 'Truck Model',
				placeHolder: '🚚 Truck Model (e.g., F-150)',
				isRequired: false,
			},
			{
				value: 'Truck Type',
				label: 'Truck Type',
				placeHolder: '🚚 Truck Type (e.g., Pickup)',
				isRequired: false,
			},
			{
				value: 'Truck Price',
				label: 'Truck Price',
				placeHolder: '💵 Truck Price (e.g., $30,000)',
				isRequired: false,
			},
			{
				value: 'Truck Color',
				label: 'Truck Color',
				placeHolder: '🎨 Truck Color (e.g., Blue)',
				isRequired: false,
			},
			{ value: 'Mileage', label: 'Mileage', placeHolder: '🔢 Mileage (e.g., 50,000 miles)', isRequired: false },
			{
				value: 'Transmission',
				label: 'Transmission',
				placeHolder: '⚙️ Transmission (e.g., Automatic)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Diesel)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Houston)',
				isRequired: false,
			},
			{
				value: 'Truck Condition',
				label: 'Truck Condition',
				placeHolder: '👌 Truck Condition (e.g., New)',
				isRequired: false,
			},
			{
				value: 'Previous Owners',
				label: 'Previous Owners',
				placeHolder: '👥 Previous Owners (e.g., 1)',
				isRequired: false,
			},
			{ value: 'Engine Type', label: 'Engine Type', placeHolder: '🔧 Engine Type (e.g., V8)', isRequired: false },
			{
				value: 'Additional Features',
				label: 'Additional Features',
				placeHolder: '✨ Additional Features (e.g., Towing Package)',
				isRequired: false,
			},
			{
				value: 'Insurance',
				label: 'Insurance',
				placeHolder: '🛡️ Insurance (e.g., Full coverage)',
				isRequired: false,
			},
			{
				value: 'Service History',
				label: 'Service History',
				placeHolder: '📚 Service History (e.g., Regular)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 3 years)', isRequired: false },
			{
				value: 'Engine Size',
				label: 'Engine Size',
				placeHolder: '🔩 Engine Size (e.g., 5.0L)',
				isRequired: false,
			},
			{ value: 'Emissions', label: 'Emissions', placeHolder: '🌿 Emissions (e.g., Low)', isRequired: false },
		],
	},
	{
		type: '🖥️ Computers',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🖥️ Brand (e.g., Dell)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🖥️ Model (e.g., XPS 13)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $1,000)', isRequired: false },
			{ value: 'Processor', label: 'Processor', placeHolder: '🔧 Processor (e.g., Intel i7)', isRequired: false },
			{ value: 'RAM', label: 'RAM', placeHolder: '🔧 RAM (e.g., 16GB)', isRequired: false },
			{ value: 'Storage', label: 'Storage', placeHolder: '🔧 Storage (e.g., 512GB SSD)', isRequired: false },
			{
				value: 'Graphics Card',
				label: 'Graphics Card',
				placeHolder: '🔧 Graphics Card (e.g., NVIDIA GTX 1650)',
				isRequired: false,
			},
			{
				value: 'Operating System',
				label: 'Operating System',
				placeHolder: '🖥️ Operating System (e.g., Windows 10)',
				isRequired: false,
			},
			{
				value: 'Screen Size',
				label: 'Screen Size',
				placeHolder: '📏 Screen Size (e.g., 13 inches)',
				isRequired: false,
			},
			{
				value: 'Resolution',
				label: 'Resolution',
				placeHolder: '📏 Resolution (e.g., 4K UHD)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '📷 Cameras',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '📷 Brand (e.g., Canon)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '📷 Model (e.g., EOS R5)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $3,500)', isRequired: false },
			{ value: 'Resolution', label: 'Resolution', placeHolder: '🔧 Resolution (e.g., 45MP)', isRequired: false },
			{ value: 'Lens Type', label: 'Lens Type', placeHolder: '🔧 Lens Type (e.g., 24-70mm)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., 8K Video)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🎮 Video Games',
		categoryDetail: [
			{
				value: 'Title',
				label: 'Title',
				placeHolder: '🎮 Title (e.g., The Last of Us Part II)',
				isRequired: false,
			},
			{
				value: 'Platform',
				label: 'Platform',
				placeHolder: '🕹️ Platform (e.g., PlayStation 5)',
				isRequired: false,
			},
			{ value: 'Genre', label: 'Genre', placeHolder: '📚 Genre (e.g., Action-Adventure)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $60)', isRequired: false },
			{
				value: 'Release Date',
				label: 'Release Date',
				placeHolder: '📅 Release Date (e.g., 2020)',
				isRequired: false,
			},
			{ value: 'Publisher', label: 'Publisher', placeHolder: '🏢 Publisher (e.g., Sony)', isRequired: false },
			{
				value: 'Developer',
				label: 'Developer',
				placeHolder: '🔧 Developer (e.g., Naughty Dog)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Multiplayer)', isRequired: false },
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.9 stars)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
		],
	},
	{
		type: '🏋️‍♀️ Fitness Equipment',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🏋️‍♀️ Type (e.g., Treadmill)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🏋️‍♀️ Brand (e.g., NordicTrack)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🏋️‍♀️ Model (e.g., Commercial 1750)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $1,500)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '🔧 Specifications (e.g., 3.75 CHP Motor)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., 10" HD Screen)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 10 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🚜 Agricultural Machinery',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🚜 Type (e.g., Tractor)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🚜 Brand (e.g., John Deere)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚜 Model (e.g., 5075E)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $50,000)', isRequired: false },
			{
				value: 'Engine Power',
				label: 'Engine Power',
				placeHolder: '🔧 Engine Power (e.g., 75 HP)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Diesel)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
		],
	},
	{
		type: '🛴 Electric Scooters',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🛴 Brand (e.g., Segway)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🛴 Model (e.g., Ninebot Max)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $700)', isRequired: false },
			{
				value: 'Battery Life',
				label: 'Battery Life',
				placeHolder: '🔋 Battery Life (e.g., 40 miles)',
				isRequired: false,
			},
			{ value: 'Range', label: 'Range', placeHolder: '🔋 Range (e.g., 25 miles)', isRequired: false },
			{ value: 'Top Speed', label: 'Top Speed', placeHolder: '⚡ Top Speed (e.g., 18 mph)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., LED Display)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🛹 Skateboards',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🛹 Brand (e.g., Santa Cruz)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🛹 Model (e.g., Screaming Hand)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $150)', isRequired: false },
			{
				value: 'Deck Material',
				label: 'Deck Material',
				placeHolder: '🛹 Deck Material (e.g., Maple)',
				isRequired: false,
			},
			{ value: 'Wheel Size', label: 'Wheel Size', placeHolder: '🛹 Wheel Size (e.g., 54mm)', isRequired: false },
			{
				value: 'Truck Type',
				label: 'Truck Type',
				placeHolder: '🛹 Truck Type (e.g., Independent)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., ABEC 7 Bearings)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🏄 Surfboards',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🏄 Brand (e.g., Firewire)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🏄 Model (e.g., Slater Designs)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $700)', isRequired: false },
			{ value: 'Length', label: 'Length', placeHolder: '📏 Length (e.g., 6\'2")', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🔧 Material (e.g., Epoxy)', isRequired: false },
			{ value: 'Fin Type', label: 'Fin Type', placeHolder: '🔧 Fin Type (e.g., FCS II)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Carbon Fiber)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🏋️‍♂️ Gym Memberships',
		categoryDetail: [
			{ value: 'Gym Name', label: 'Gym Name', placeHolder: "🏋️‍♂️ Gym Name (e.g., Gold's Gym)", isRequired: false },
			{ value: 'Location', label: 'Location', placeHolder: '📍 Location (e.g., Los Angeles)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $50/month)', isRequired: false },
			{
				value: 'Membership Type',
				label: 'Membership Type',
				placeHolder: '🏋️‍♂️ Membership Type (e.g., Annual)',
				isRequired: false,
			},
			{
				value: 'Facilities',
				label: 'Facilities',
				placeHolder: '🏊 Facilities (e.g., Pool, Sauna)',
				isRequired: false,
			},
			{
				value: 'Trainer Availability',
				label: 'Trainer Availability',
				placeHolder: '🏋️‍♂️ Trainer Availability (e.g., Yes)',
				isRequired: false,
			},
			{
				value: 'Operating Hours',
				label: 'Operating Hours',
				placeHolder: '⏰ Operating Hours (e.g., 24/7)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
			{
				value: 'Contact Information',
				label: 'Contact Information',
				placeHolder: '📞 Contact Information (e.g., 123-456-7890)',
				isRequired: false,
			},
		],
	},
	{
		type: '📱 Smartphones',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '📱 Brand (e.g., Apple)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '📱 Model (e.g., iPhone 12)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $799)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '🔧 Specifications (e.g., 128GB, 4GB RAM)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Face ID)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{
				value: 'Release Year',
				label: 'Release Year',
				placeHolder: '📅 Release Year (e.g., 2020)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🚁 Drones',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🚁 Brand (e.g., DJI)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚁 Model (e.g., Mavic Air 2)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $999)', isRequired: false },
			{
				value: 'Camera Resolution',
				label: 'Camera Resolution',
				placeHolder: '📷 Camera Resolution (e.g., 48MP)',
				isRequired: false,
			},
			{
				value: 'Battery Life',
				label: 'Battery Life',
				placeHolder: '🔋 Battery Life (e.g., 34 minutes)',
				isRequired: false,
			},
			{ value: 'Range', label: 'Range', placeHolder: '📏 Range (e.g., 10km)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Obstacle Sensing)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🕶️ Sunglasses',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🕶️ Brand (e.g., Ray-Ban)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🕶️ Model (e.g., Wayfarer)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $150)', isRequired: false },
			{
				value: 'Lens Type',
				label: 'Lens Type',
				placeHolder: '🕶️ Lens Type (e.g., Polarized)',
				isRequired: false,
			},
			{
				value: 'Frame Material',
				label: 'Frame Material',
				placeHolder: '🕶️ Frame Material (e.g., Acetate)',
				isRequired: false,
			},
			{
				value: 'UV Protection',
				label: 'UV Protection',
				placeHolder: '🕶️ UV Protection (e.g., 100%)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Scratch-resistant)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '👜 Handbags',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '👜 Brand (e.g., Louis Vuitton)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '👜 Model (e.g., Neverfull)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $1,200)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '👜 Material (e.g., Leather)', isRequired: false },
			{ value: 'Color', label: 'Color', placeHolder: '👜 Color (e.g., Brown)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Water-resistant)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.9 stars)', isRequired: false },
		],
	},
	{
		type: '🏖️ Vacation Packages',
		categoryDetail: [
			{
				value: 'Destination',
				label: 'Destination',
				placeHolder: '🏖️ Destination (e.g., Hawaii)',
				isRequired: false,
			},
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $2,000)', isRequired: false },
			{ value: 'Duration', label: 'Duration', placeHolder: '📅 Duration (e.g., 7 days)', isRequired: false },
			{
				value: 'Accommodation',
				label: 'Accommodation',
				placeHolder: '🏨 Accommodation (e.g., 5-star hotel)',
				isRequired: false,
			},
			{
				value: 'Transportation',
				label: 'Transportation',
				placeHolder: '✈️ Transportation (e.g., Flight included)',
				isRequired: false,
			},
			{
				value: 'Activities',
				label: 'Activities',
				placeHolder: '🏄 Activities (e.g., Surfing, Snorkeling)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., All-inclusive)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
			{
				value: 'Contact Information',
				label: 'Contact Information',
				placeHolder: '📞 Contact Information (e.g., 123-456-7890)',
				isRequired: false,
			},
		],
	},
	{
		type: '🍷 Wine',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🍷 Brand (e.g., Chateau Margaux)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '🍷 Type (e.g., Red)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $200)', isRequired: false },
			{ value: 'Vintage', label: 'Vintage', placeHolder: '🍷 Vintage (e.g., 2015)', isRequired: false },
			{ value: 'Region', label: 'Region', placeHolder: '🍇 Region (e.g., Bordeaux)', isRequired: false },
			{
				value: 'Grape Variety',
				label: 'Grape Variety',
				placeHolder: '🍇 Grape Variety (e.g., Cabernet Sauvignon)',
				isRequired: false,
			},
			{
				value: 'Alcohol Content',
				label: 'Alcohol Content',
				placeHolder: '🍷 Alcohol Content (e.g., 13.5%)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Aged in oak barrels)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.9 stars)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
		],
	},
	{
		type: '💍 Jewelry',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '💍 Type (e.g., Necklace)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '💍 Brand (e.g., Tiffany & Co.)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '💍 Material (e.g., Platinum)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $3,000)', isRequired: false },
			{ value: 'Gemstone', label: 'Gemstone', placeHolder: '💎 Gemstone (e.g., Diamond)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Handmade)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., Lifetime)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🍿 Snacks',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: "🍿 Brand (e.g., Lay's)", isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '🍿 Type (e.g., Potato Chips)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $2)', isRequired: false },
			{
				value: 'Ingredients',
				label: 'Ingredients',
				placeHolder: '🍿 Ingredients (e.g., Potatoes, Salt)',
				isRequired: false,
			},
			{
				value: 'Nutritional Information',
				label: 'Nutritional Information',
				placeHolder: '🍿 Nutritional Information (e.g., 150 calories per serving)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Gluten-free)', isRequired: false },
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
		],
	},
	{
		type: '🛏️ Mattresses',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🛏️ Brand (e.g., Tempur-Pedic)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '🛏️ Type (e.g., Memory Foam)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $1,200)', isRequired: false },
			{ value: 'Size', label: 'Size', placeHolder: '📏 Size (e.g., Queen)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🧵 Material (e.g., Memory Foam)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Cooling Technology)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 10 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🚜 Lawn Mowers',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🚜 Brand (e.g., Honda)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚜 Model (e.g., HRX217VKA)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $600)', isRequired: false },
			{
				value: 'Engine Power',
				label: 'Engine Power',
				placeHolder: '🔧 Engine Power (e.g., 200cc)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Gasoline)', isRequired: false },
			{
				value: 'Cutting Width',
				label: 'Cutting Width',
				placeHolder: '📏 Cutting Width (e.g., 21 inches)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Self-propelled)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 5 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🚤 Boats',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🚤 Brand (e.g., Bayliner)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚤 Model (e.g., Element E18)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $20,000)', isRequired: false },
			{ value: 'Type', label: 'Type', placeHolder: '🚤 Type (e.g., Bowrider)', isRequired: false },
			{ value: 'Length', label: 'Length', placeHolder: '📏 Length (e.g., 18 feet)', isRequired: false },
			{
				value: 'Engine Type',
				label: 'Engine Type',
				placeHolder: '🔧 Engine Type (e.g., Outboard)',
				isRequired: false,
			},
			{ value: 'Fuel Type', label: 'Fuel Type', placeHolder: '⛽ Fuel Type (e.g., Gasoline)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Bimini Top)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 2 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🏌️‍♂️ Golf Equipment',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🏌️‍♂️ Type (e.g., Golf Clubs)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🏌️‍♂️ Brand (e.g., Callaway)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🏌️‍♂️ Model (e.g., Rogue)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $500)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🔧 Material (e.g., Graphite)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Adjustable Weights)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🏄‍♂️ Water Sports Equipment',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🏄‍♂️ Type (e.g., Kayak)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🏄‍♂️ Brand (e.g., Perception)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🏄‍♂️ Model (e.g., Pescador 12)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $600)', isRequired: false },
			{
				value: 'Material',
				label: 'Material',
				placeHolder: '🔧 Material (e.g., Polyethylene)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Adjustable Seat)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 5 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🎹 Musical Instruments',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🎹 Type (e.g., Piano)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🎹 Brand (e.g., Yamaha)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🎹 Model (e.g., U1)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $5,000)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🔧 Material (e.g., Wood)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Weighted Keys)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 10 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🏠 Home Decor',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🏠 Type (e.g., Wall Art)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🏠 Brand (e.g., West Elm)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $100)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🔧 Material (e.g., Canvas)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Hand-painted)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🖼️ Art',
		categoryDetail: [
			{ value: 'Artist', label: 'Artist', placeHolder: '🎨 Artist (e.g., Van Gogh)', isRequired: false },
			{ value: 'Title', label: 'Title', placeHolder: '🖼️ Title (e.g., Starry Night)', isRequired: false },
			{ value: 'Medium', label: 'Medium', placeHolder: '🖌️ Medium (e.g., Oil on canvas)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $1,000,000)', isRequired: false },
			{
				value: 'Dimensions',
				label: 'Dimensions',
				placeHolder: '📏 Dimensions (e.g., 24 x 36 inches)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Signed)', isRequired: false },
			{
				value: 'Condition',
				label: 'Condition',
				placeHolder: '👌 Condition (e.g., Excellent)',
				isRequired: false,
			},
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Gallery)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.9 stars)', isRequired: false },
		],
	},
	{
		type: '🎨 Craft Supplies',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🎨 Type (e.g., Paint)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🎨 Brand (e.g., Crayola)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $10)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🎨 Material (e.g., Acrylic)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Non-toxic)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🚨 Security Systems',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '🚨 Brand (e.g., Ring)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚨 Model (e.g., Video Doorbell 3)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $200)', isRequired: false },
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Motion Detection)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🖋️ Stationery',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🖋️ Type (e.g., Pen)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🖋️ Brand (e.g., Pilot)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🖋️ Model (e.g., G2)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $2)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🖋️ Material (e.g., Plastic)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Gel Ink)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🍰 Baking Supplies',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🍰 Type (e.g., Flour)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🍰 Brand (e.g., King Arthur)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $5)', isRequired: false },
			{
				value: 'Ingredients',
				label: 'Ingredients',
				placeHolder: '🍰 Ingredients (e.g., Wheat)',
				isRequired: false,
			},
			{
				value: 'Nutritional Information',
				label: 'Nutritional Information',
				placeHolder: '🍰 Nutritional Information (e.g., 100 calories per serving)',
				isRequired: false,
			},
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Organic)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '🌿 Gardening Supplies',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🌿 Type (e.g., Seeds)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🌿 Brand (e.g., Burpee)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $3)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🌿 Material (e.g., Organic)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Non-GMO)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🐾 Pet Supplies',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🐾 Type (e.g., Dog Food)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🐾 Brand (e.g., Purina)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $20)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🐾 Material (e.g., Organic)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Grain-free)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🚨 Home Security',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🚨 Type (e.g., Alarm System)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🚨 Brand (e.g., SimpliSafe)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '🚨 Model (e.g., SS3)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $200)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Wireless)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.6 stars)', isRequired: false },
		],
	},
	{
		type: '🏞️ Outdoor Gear',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🏞️ Type (e.g., Tent)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🏞️ Brand (e.g., REI)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $300)', isRequired: false },
			{ value: 'Material', label: 'Material', placeHolder: '🏞️ Material (e.g., Nylon)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Waterproof)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.7 stars)', isRequired: false },
		],
	},
	{
		type: '🧳 Travel Gear',
		categoryDetail: [
			{ value: 'Type', label: 'Type', placeHolder: '🧳 Type (e.g., Suitcase)', isRequired: false },
			{ value: 'Brand', label: 'Brand', placeHolder: '🧳 Brand (e.g., Samsonite)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $150)', isRequired: false },
			{
				value: 'Material',
				label: 'Material',
				placeHolder: '🧳 Material (e.g., Polycarbonate)',
				isRequired: false,
			},
			{
				value: 'Features',
				label: 'Features',
				placeHolder: '✨ Features (e.g., Spinner Wheels)',
				isRequired: false,
			},
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 10 years)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.8 stars)', isRequired: false },
		],
	},
	{
		type: '📦 Other',
		categoryDetail: [
			{ value: 'Brand', label: 'Brand', placeHolder: '📦 Brand (e.g., Generic)', isRequired: false },
			{ value: 'Model', label: 'Model', placeHolder: '📦 Model (e.g., Model XYZ)', isRequired: false },
			{ value: 'Price', label: 'Price', placeHolder: '💵 Price (e.g., $50)', isRequired: false },
			{ value: 'Features', label: 'Features', placeHolder: '✨ Features (e.g., Feature 1)', isRequired: false },
			{ value: 'Warranty', label: 'Warranty', placeHolder: '🛠️ Warranty (e.g., 1 year)', isRequired: false },
			{ value: 'Condition', label: 'Condition', placeHolder: '👌 Condition (e.g., New)', isRequired: false },
			{
				value: 'Sales Location',
				label: 'Sales Location',
				placeHolder: '📍 Sales Location (e.g., Online)',
				isRequired: false,
			},
			{ value: 'Reviews', label: 'Reviews', placeHolder: '⭐ Reviews (e.g., 4.5 stars)', isRequired: false },
			{
				value: 'Specifications',
				label: 'Specifications',
				placeHolder: '📦 Specifications (e.g., Spec 1, Spec 2)',
				isRequired: false,
			},
			{
				value: 'Availability',
				label: 'Availability',
				placeHolder: '📦 Availability (e.g., In Stock)',
				isRequired: false,
			},
		],
	},
];

export default CategoryProduct;
