import React, { useCallback, useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import { AiOutlineVideoCamera, AiOutlineBarChart } from 'react-icons/ai';
import Cookies from 'universal-cookie';
import { toast } from 'react-toastify';
import {
	Navbar,
	NavbarBrand,
	NavbarContent,
	NavbarItem,
	Dropdown,
	DropdownTrigger,
	DropdownMenu,
	DropdownSection,
	DropdownItem,
	NavbarMenu,
	User,
	NavbarMenuToggle,
	NavbarMenuItem,
	Avatar,
	Image,
	Button,
} from '@nextui-org/react';

import { AdsIconColor, AdsImage, AvatarDefault, FilmReel, MagicWand } from '../../Assets/Image';
import { coinIc } from '../../Assets/Icons';
import { AuthContext } from '../../hooks/AuthContext';
import fetchApi from '../../Utils/Api/fetchApi';
import { Constants } from '../../Constants';
import { ThemeSwitcher } from '../UiKit';
import { ThemeContext } from '../../hooks/ThemeContext';

const NavbarBrandComponent = () => (
	<NavbarBrand>
		<Link color='foreground' to='/' className='flex items-center'>
			<Image className='rounded-none' src={AdsIconColor} width='30' height='30' alt='Our Video logo' />
			<h3 className='font-semibold text-sm text-inherit mb-0 ml-2'>Ouvee</h3>
		</Link>
	</NavbarBrand>
);

const UserDropdownMobile = ({ username, avatar, role, closeMenu }) => (
	<div className='flex items-center gap-4'>
		<Dropdown placement='bottom-center'>
			<DropdownTrigger>
				<Avatar
					isBordered
					as='button'
					className='cursor-pointer transition-transform w-24 h-24 text-tiny'
					src={avatar ? avatar : AvatarDefault}
				/>
			</DropdownTrigger>
			<DropdownMenu aria-label='Profile Actions' variant='flat'>
				<DropdownItem key='profile' className='text-sm'>
					<Link to='/my-profile' onClick={closeMenu}>
						<p className='font-semibold'>{username}</p>
					</Link>
				</DropdownItem>
				<DropdownItem key='my-galleries' className='text-sm'>
					<Link to='/my-galleries' onClick={closeMenu}>
						<p className='font-semibold'>My Galleries</p>
					</Link>
				</DropdownItem>
				{role === 'admin' && (
					<DropdownItem key='admin-user' className='text-sm w-full'>
						<Link to='/admin-edit-user' onClick={closeMenu} className='w-full'>
							<p className='font-semibold'>Users</p>
						</Link>
					</DropdownItem>
				)}
				{role === 'admin' && (
					<DropdownItem key='admin-product' className='text-sm w-full'>
						<Link to='/admin-edit-product' onClick={closeMenu} className=''>
							<p className='font-semibold'>Products</p>
						</Link>
					</DropdownItem>
				)}
				{/* <DropdownItem key='admin' className='text-sm w-full'>
          {role === 'admin' && (
          )}
        </DropdownItem>
        <DropdownItem key='admin-product' className='text-sm'>
          {role === 'admin' && (
          )}
        </DropdownItem> */}
				<DropdownItem key='transactions' className='text-sm'>
					<Link to='/transaction-history' onClick={closeMenu}>
						<p className='font-semibold'>Transaction</p>
					</Link>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	</div>
);

const ProductDropdownMobile = ({ handleNavigate, token }) => (
	<Dropdown placement='bottom-end' className='py-3 px-2 w-120 cursor-pointer' backdrop='blur'>
		<NavbarItem>
			<DropdownTrigger>
				<Link disableRipple className='p-0 text-medium' variant='light' color='foreground'>
					Features
				</Link>
			</DropdownTrigger>
		</NavbarItem>
		<DropdownMenu aria-label='Product features' className='text-sm'>
			<DropdownItem
				key='Features'
				isReadOnly
				className='hover:bg-transparent hover:cursor-default mb-5'
				showDivider>
				<p className='font-semibold'>Features</p>
			</DropdownItem>
			<DropdownItem
				key='ads_product_video'
				description={<p className='text-gray-400 dark:text-gray-400 mt-1'>Ad content creation.</p>}
				className='mb-3'
				// startContent={<Image src={AdsImage} width={34} />}
				onClick={() => {
					if (!token) {
						toast.warn('Please log in first to access this feature.');
						return;
					}
					handleNavigate('feature/ads-product');
				}}>
				<span className='text-small font-semibold tracking-tight'>Ads Product Video</span>
			</DropdownItem>
			<DropdownItem
				key='reels_story1'
				description={
					<p className='text-gray-400 dark:text-gray-400 mt-1'>Creative visuals for Reels and TikTok.</p>
				}
				className='mb-3'
				// startContent={<Image src={FilmReel} width={34} />}
				onClick={() => {
					if (!token) {
						toast.warn('Please log in first to access this feature.');
						return;
					}
					handleNavigate('feature/story-teller');
				}}>
				<Link to='/feature/story-teller'>
					<span className='text-small font-semibold tracking-tight'>Story Teller Video</span>
				</Link>
			</DropdownItem>
			<DropdownItem
				key='generate_image'
				description={<p className='text-gray-400 dark:text-gray-400 mt-1'>Image what you want.</p>}
				className='mb-3'
				// startContent={<Image src={MagicWand} width={34} />}
				onClick={() => {
					if (!token) {
						toast.warn('Please log in first to access this feature.');
						return;
					}
					handleNavigate('feature/image-creation');
				}}>
				<Link to='/feature/image-creation'>
					<span className='text-small font-semibold tracking-tight'>Generate Image</span>
				</Link>
			</DropdownItem>
		</DropdownMenu>
	</Dropdown>
);

const ProductDropdownDesktop = ({ handleNavigate }) => (
	<Dropdown>
		<NavbarItem>
			<DropdownTrigger>
				<Link className='p-0' radius='sm' variant='light' color='foreground'>
					Product
				</Link>
			</DropdownTrigger>
		</NavbarItem>
		<DropdownMenu aria-label='Product features' className='w-[340px] p-3'>
			<DropdownItem
				key='pricing'
				startContent={<AiOutlineBarChart size={35} />}
				onClick={() => handleNavigate('services')}>
				Pricing
			</DropdownItem>
			<DropdownItem
				key='features'
				startContent={<AiOutlineVideoCamera size={35} />}
				onClick={() => handleNavigate('features')}>
				Features
			</DropdownItem>
		</DropdownMenu>
	</Dropdown>
);

const MobileNavbarContent = ({
	isMenuOpen,
	setIsMenuOpen,
	username,
	avatar,
	role,
	token,
	handleNavigate,
	handleLogout,
	credits,
}) => {
	const { isDark } = useContext(ThemeContext);

	return (
		<div className='md:hidden flex w-full items-center'>
			<NavbarContent className='flex pr-3 -sm mt-1' justify='start'>
				<NavbarBrandComponent />
			</NavbarContent>
			<NavbarMenuToggle
				className={`h-10 font-semibold bg-transparent mt-3 ${isDark ? 'text-white' : 'text-black'}`}
				aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
			/>
			<NavbarMenu backdrop='blur'>
				{token ? (
					<>
						<NavbarMenuItem className='text-sm mt-12 items-center flex justify-center'>
							<UserDropdownMobile
								username={username}
								avatar={avatar}
								role={role}
								closeMenu={() => setIsMenuOpen(false)}
							/>
						</NavbarMenuItem>
						<NavbarMenuItem className='mt-4' key={`0`}>
							<div className='w-full flex items-center justify-center'>
								<div className='flex gap-1 items-center px-2.5'>
									<span className='bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400'>
										<div className='flex flex-row gap-2'>
											<Image src={coinIc} width={16} height={16} />
											{credits || 0} Credits Left
										</div>
									</span>
								</div>
							</div>
						</NavbarMenuItem>
						<NavbarMenuItem className='mt-4' key='switch theme'>
							<div className='w-full flex items-center justify-center'>
								<ThemeSwitcherItem />
							</div>
						</NavbarMenuItem>
						<NavbarMenuItem className='text-medium mt-0'>
							<ProductDropdownMobile
								handleNavigate={(route) => {
									setIsMenuOpen(false);
									handleNavigate(route);
								}}
								token={token}
							/>
						</NavbarMenuItem>
						<NavbarMenuItem className='text-medium mt-8'>
							<Link
								className='w-full text-medium'
								color='foreground'
								to='/pricing'
								onClick={() => setIsMenuOpen(false)}>
								Pricing
							</Link>
						</NavbarMenuItem>
						{/* <NavbarMenuItem className='text-medium mt-8'>
							<Link
								className='w-full text-medium'
								color='foreground'
								to='/galleries'
								size='lg'
								onClick={() => setIsMenuOpen(false)}>
								Gallery
							</Link>
						</NavbarMenuItem> */}
						<NavbarMenuItem className='text-medium mt-8'>
							<Link
								className='w-full text-medium text-purple-400'
								to='#'
								size='lg'
								onClick={() => {
									setIsMenuOpen(false);
									handleLogout();
								}}>
								Log Out
							</Link>
						</NavbarMenuItem>
					</>
				) : (
					<>
						{/* <NavbarMenuItem className='text-medium mt-24'>
							<>
								<Link
									className='w-full text-medium'
									color='foreground'
									to='/galleries'
									size='lg'
									onClick={() => setIsMenuOpen(false)}>
									Gallery
								</Link>
								<hr className='mt-2' />
							</>
						</NavbarMenuItem> */}
						<NavbarMenuItem className='text-medium mt-14'>
							<>
								<div className='flex flex-row'>
									<Link
										className='w-full text-medium font-bold'
										color='foreground'
										to='/login'
										size='lg'
										onClick={() => setIsMenuOpen(false)}>
										<div className='mb-4'>
											<span className='bg-purple-100 text-purple-800 text-medium font-medium me-2 px-2.5 py-0.5 rounded dark:bg-purple-900 dark:text-purple-300'>
												Login
											</span>
										</div>
									</Link>
									<ThemeSwitcherItem />
								</div>
								<hr className='mt-2' />
							</>
						</NavbarMenuItem>
					</>
				)}
			</NavbarMenu>
		</div>
	);
};

const AuthLinks = ({ location }) => (
	<>
		<NavbarItem isActive={location.pathname === '/login'}>
			<Link
				color='foreground'
				to='/login'
				className={`${location.pathname === '/login' && 'text-violet-300'} ${
					location.pathname !== '/login' && 'text-gray-400'
				} hover:text-violet-300 dark:hover:text-violet-300 active:text-violet-300 font-bold`}>
				Login
			</Link>
		</NavbarItem>
		{/* <NavbarItem isActive={location.pathname === '/register'}>
			<Link
				color='foreground'
				to='/register'
				className={`${
					location.pathname === '/register' && 'text-violet-300'
				} ${location.pathname !== '/register' && 'text-gray-400'} hover:text-violet-300 dark:hover:text-violet-300 active:text-violet-300 font-bold`}>
				Register
			</Link>
		</NavbarItem> */}
	</>
);

const ThemeSwitcherItem = () => (
	<NavbarItem>
		<ThemeSwitcher />
	</NavbarItem>
);

const UserDropdown = ({ username, avatar, role, handleLogout, credits }) => (
	<Dropdown
		showArrow
		radius='sm'
		backdrop='blur'
		classNames={{
			base: 'before:bg-default-200',
			content: 'p-2 border-small border-divider bg-background',
		}}>
		<DropdownTrigger>
			<Avatar className='cursor-pointer w-10 h-10 text-tiny' isBordered src={avatar || AvatarDefault} />
		</DropdownTrigger>
		<DropdownMenu
			aria-label='Custom item styles'
			className='p-3'
			itemClasses={{
				base: [
					'rounded-md',
					'text-default-500',
					'transition-opacity',
					'data-[hover=true]:text-foreground',
					'data-[hover=true]:bg-default-100',
					'dark:data-[hover=true]:bg-default-50',
					'data-[selectable=true]:focus:bg-default-50',
					'data-[pressed=true]:opacity-70',
					'data-[focus-visible=true]:ring-default-500',
				],
			}}>
			<DropdownSection aria-label='Profile & Actions' showDivider>
				<DropdownItem href='/my-profile' key='profile' className='h-14 mb-1 gap-2 font-bold dark:text-white'>
					<User
						name={<p className='font-bold'>{username}</p>}
						description={
							<div className='flex gap-1 items-center px-2.5'>
								<span className='mt-1 bg-indigo-100 text-indigo-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-indigo-400 border border-indigo-400'>
									<div className='flex flex-row gap-2'>
										<Image src={coinIc} width={16} height={16} />
										{credits || 0} Credits Left
									</div>
								</span>
							</div>
						}
						classNames={{
							name: 'text-default-600 ml-2',
							description: 'text-default-500',
						}}
						avatarProps={{
							size: 'lg',
							src: avatar || AvatarDefault,
						}}
					/>
				</DropdownItem>
				<DropdownItem key='my-galleries' href='/my-galleries?tab=my-images'>
					My Galleries
				</DropdownItem>
				<DropdownItem key='transaction' href='/transaction-history'>
					Transaction
				</DropdownItem>
			</DropdownSection>

			{role === 'admin' && (
				<DropdownSection aria-label='Preferences' showDivider>
					<DropdownItem key='users' href='/admin-edit-user'>
						Users
					</DropdownItem>
					<DropdownItem key='products' href='/admin-edit-product'>
						Products
					</DropdownItem>
				</DropdownSection>
			)}

			<DropdownSection aria-label='Log Out'>
				<DropdownItem key='logout' className='text-red-600 font-bold hover:text-red-600' onClick={handleLogout}>
					Log Out
				</DropdownItem>
			</DropdownSection>
		</DropdownMenu>
	</Dropdown>
);

const FeatureDropdown = ({ handleNavigate, token }) => {
	return (
		<Dropdown
			backdrop='blur'
			classNames={{
				base: 'before:bg-default-200',
				content: 'p-2 border-small border-divider bg-background',
			}}>
			<NavbarItem>
				<DropdownTrigger>
					<Button
						disableRipple
						className={`p-0 text-md bg-transparent data-[hover=true]:bg-transparent
						 hover:text-violet-300 dark:hover:text-violet-300 active:text-violet-300 font-bold`}
						radius='sm'
						variant='light'>
						Features
					</Button>
				</DropdownTrigger>
			</NavbarItem>
			<DropdownMenu
				aria-label='ACME features'
				className='w-[340px] px-3 py-3 opacity-80'
				itemClasses={{
					base: [
						'rounded-md',
						// 'text-default-500',
						'transition-opacity',
						'data-[hover=true]:text-foreground',
						'data-[hover=true]:bg-default-100',
						'dark:data-[hover=true]:bg-default-50',
						'data-[selectable=true]:focus:bg-default-50',
						'data-[pressed=true]:opacity-70',
						'data-[focus-visible=true]:ring-default-500',
					],
				}}>
				<DropdownItem
					key='features'
					isReadOnly
					// showDivider
					className='hover:bg-transparent hover:cursor-default mb-5'
					// description={
					//   <p className='text-gray-400 dark:text-gray-400 mt-1'>Creative content creation features</p>
					// }
				>
					<div className='flex items-center gap-2'>
						<Image
							className='rounded-none'
							src={AdsIconColor}
							width='40'
							height='40'
							alt='Our Video logo'
						/>
						<div>
							<h4 className='font-semibold'>Features</h4>
							<p className='text-gray-400 dark:text-gray-400 mt-1'>Creative content creation features</p>
						</div>
					</div>
				</DropdownItem>
				<DropdownItem
					key='ads_product'
					className='border dark:border-gray-600 mb-3 p-3'
					description={
						<p className='text-gray-400 dark:text-gray-400 mt-1'>
							High-quality AI-driven ad content creation.
						</p>
					}
					// startContent={<Image src={AdsImage} width={34} />}
					onClick={() => {
						if (!token) {
							handleNavigate('login');
						} else {
							handleNavigate('feature/ads-product');
						}
					}}>
					<div className='flex items-center justify-center'>
						Ads Product Video
						{!token && (
							<p className='text-gray-400 dark:text-gray-400 ml-1' style={{ fontSize: '12px' }}>
								<span className='ml-2 border font-semibold px-2.5 py-0.5 rounded text-gray-700 dark:text-gray-400 dark:border-gray-700'>
									Login First
								</span>
							</p>
						)}
					</div>
				</DropdownItem>
				<DropdownItem
					key='reels_story'
					className='border dark:border-gray-600 mb-3 p-3'
					description={
						<p className='text-gray-400 dark:text-gray-400 mt-1'>Creative visuals for Reels and TikTok.</p>
					}
					// startContent={<Image src={FilmReel} width={34} />}
					onClick={() => {
						if (!token) {
							handleNavigate('login');
						} else {
							handleNavigate('feature/story-teller');
						}
					}}>
					<div className='flex items-center justify-center'>
						Story Teller Video
						{!token && (
							<p className='text-gray-400 dark:text-gray-400 ml-1' style={{ fontSize: '12px' }}>
								<span className='ml-2 border font-semibold px-2.5 py-0.5 rounded text-gray-700 dark:text-gray-400 dark:border-gray-700'>
									Login First
								</span>
							</p>
						)}
					</div>
				</DropdownItem>
				<DropdownItem
					key='generate_image'
					className='border dark:border-gray-600 mb-3 p-3'
					description={
						<p className='text-gray-400 dark:text-gray-400 mt-1'>
							AI-crafted image with high conversion rates.
						</p>
					}
					// startContent={<Image src={MagicWand} width={34} />}
					onClick={() => {
						if (!token) {
							handleNavigate('login');
						} else {
							handleNavigate('feature/image-creation');
						}
					}}>
					<div className='flex items-center justify-center'>
						Image Creation
						{!token && (
							<p className='text-gray-400 dark:text-gray-400 ml-1' style={{ fontSize: '12px' }}>
								<span className='ml-2 border font-semibold px-2.5 py-0.5 rounded text-gray-700 dark:text-gray-400 dark:border-gray-700'>
									Login First
								</span>
							</p>
						)}
					</div>
				</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

const DesktopNavItems = ({ location, handleNavigate, token }) => (
	<>
		<NavbarMenuItem isActive={location.pathname === '/'}></NavbarMenuItem>
		<FeatureDropdown handleNavigate={handleNavigate} token={token} />
		<NavbarItem isActive={location.pathname === '/pricing'}>
			<Link
				color='foreground'
				to='/pricing'
				className={`${
					location.pathname === '/pricing' && 'text-violet-600'
				} hover:text-violet-300 dark:hover:text-violet-300 active:text-violet-300 font-bold`}>
				Pricing
			</Link>
		</NavbarItem>
		{/* <NavbarItem isActive={location.pathname === '/galleries'}>
			<Link
				color='foreground'
				to='/galleries'
				className={`${
					location.pathname === '/galleries' && 'text-violet-300'
				} hover:text-violet-300 dark:hover:text-violet-300 active:text-violet-300`}>
				Gallery
			</Link>
		</NavbarItem> */}
	</>
);

const DesktopNavbarContent = ({ location, handleNavigate, token, username, avatar, role, handleLogout, credits }) => (
	<div className='hidden md:flex w-full items-center justify-between'>
		<NavbarBrandComponent />
		<NavbarContent className='hidden md:flex gap-4 mb-0' justify='center'>
			<DesktopNavItems location={location} handleNavigate={handleNavigate} token={token} />
		</NavbarContent>
		<NavbarContent justify='end'>
			{token ? (
				<UserDropdown
					username={username}
					avatar={avatar}
					role={role}
					handleLogout={handleLogout}
					credits={credits}
				/>
			) : (
				<AuthLinks location={location} />
			)}
			<ThemeSwitcherItem />
		</NavbarContent>
	</div>
);

const NavbarWrapper = ({ children, isMenuOpen, setIsMenuOpen }) => (
	<Navbar
		classNames={{
			item: ['flex', 'relative', 'h-full', 'items-center', 'data-[active=true]:text-primary-500', 'mb-0'],
		}}
		style={{
			padding: '8px 0',
			boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
		}}
		isBordered
		variant='sticky'
		isMenuOpen={isMenuOpen}
		onMenuOpenChange={setIsMenuOpen}>
		{children}
	</Navbar>
);

const NavbarUI = () => {
	const { username, avatar, role, credits, resetUseAuth } = useContext(AuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const token = localStorage.getItem('accessToken');

	const [isMenuOpen, setIsMenuOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

	const handleResize = () => {
		setIsMobile(window.innerWidth <= 768);
	};

	useEffect(() => {
		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	useEffect(() => {
		toast.dismiss();
	}, [location.pathname]);

	const handleLogout = async () => {
		try {
			const options = {
				method: Constants.REQUEST_API.POST,
				url: `/logout`,
			};
			const response = await fetchApi(options);

			if (parseInt(response?.data.code) === 200) {
				const cookies = new Cookies();
				cookies.remove('authData');
				// somehow this cookies wont removed
				cookies.remove('refresh.token', { path: '/' });
				localStorage.removeItem('accessToken');
				resetUseAuth();
				navigate('/login');
			} else {
				toast.error('Error logging out:', response?.message);
			}
		} catch (error) {
			toast.error('Error logging out:', error);
		}
	};

	const handleNavigate = useCallback(
		(route) => {
			navigate(`/${route}`);
		},
		[navigate, token]
	);

	return (
		<NavbarWrapper isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen}>
			<MobileNavbarContent
				isMenuOpen={isMenuOpen}
				setIsMenuOpen={setIsMenuOpen}
				username={username}
				avatar={avatar}
				role={role}
				token={token}
				handleNavigate={handleNavigate}
				handleLogout={handleLogout}
				credits={credits}
			/>
			<DesktopNavbarContent
				location={location}
				handleNavigate={handleNavigate}
				username={username}
				avatar={avatar}
				token={token}
				role={role}
				handleLogout={handleLogout}
				credits={credits}
			/>
			{/* {isMobile ? (
      ) : (
      )} */}
		</NavbarWrapper>
	);
};

export default NavbarUI;
